import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {HttpLink} from "apollo-link-http";
import {apiUrl} from "config";

const cache = new InMemoryCache();
const link = new HttpLink({uri: `${apiUrl}/graphql`});
const client = new ApolloClient({cache, link});

export default client;
