/** 인증 관련 API */
import axios from 'axios';
import qs from 'qs';
import { public_url, apiUrl } from 'config';


/****** 유저 인증 관련 ******/
/* Get jwt 인증 토큰
parmas:
{
    "identifier": "test@obenef.com",
    "password": "Chao0116"
}
 */

export const getAuth = (params) => {
    delete axios.defaults.headers.common['Authorization'];
    //return axios.post(`${apiUrl}/auth/local`, {...params});
    return axios.post(`/custom-api/sso/loginCheck`, { ...params });
}

export const getSession = (params) => {
    delete axios.defaults.headers.common['Authorization'];
    //return axios.post(`${apiUrl}/auth/local`, {...params});
    return axios.post(`/custom-api/sso/getMyTest`, { ...params });
}

/* 나의 유저 정보 */
//export const getMy = () => axios.get(`${apiUrl}/users/me`);

//개발 확인용(kacademy2.kisti.re.kr)
export const getMy = (params) => {
    delete axios.defaults.headers.common['Authorization'];
    //return axios.post(`${apiUrl}/auth/local`, {...params});
    return axios.post(`/custom-api/sso/getMy`, { ...params });
}
// export const getAuth = (params) => {
//     delete axios.defaults.headers.common['Authorization'];
//     return axios.post(`${apiUrl}/auth/local`, { ...params });
// }

// /* 나의 유저 정보 */
// export const getMy = () => axios.get(`${apiUrl}/users/me`);

/* 유저 생성 api */
export const createUser = (params) => axios.post(`${apiUrl}/auth/local/register`, { ...params });

/* 유저 목록 조회 */
export const getUsersData = (params) => axios.get(`${apiUrl}/users`, { params: { ...params } })

/* 검색 된 유저 수 카운트 */
export const getUserCount = (params) => axios.get(`${apiUrl}/users/count`, { params: { ...params } })




/* 비밀번호 찾기 메일 전송 api */
export const findPassword = (params) => axios.post(`${apiUrl}/auth/forgot-password`, { ...params });

/* 비밀번호 변경 api */
export const resetPassword = (params) => axios.post(`${apiUrl}/auth/reset-password`, { ...params });

/* 유저정보 변경 api(?) */
export const updateUser = (id, params) => axios.put(`${apiUrl}/users/${id}`, { ...params });


/* 유저정보 유저 정보 삭제 */
export const deleteUser = (id, params) => axios.delete(`${apiUrl}/users/${id}`, { ...params });