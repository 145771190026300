import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment/dist/moment';
import axios from 'axios';

const ChatList = ({ item, selectChatRoom,idx, setSelectChatRoom,menuClose,widthStyle, deleteChatRoom, editTitle, chatting, ws }) => {
    const [titleEdit,setTitleEdit] = useState(false)

    return (
        <div className={`chat_list_item ${_.get(item,'chat_room_id') == _.get(selectChatRoom,'chat_room_id') && 'active'}`}>
            {
                titleEdit &&
                <>
                    <input id={_.get(item,'_id')} defaultValue={_.get(item,'chat_room_name').replace(/"/g, '')}/>
                    <div className={'edit_button_container'}>
                        <button onClick={()=>{
                            editTitle({item,changeTitle:document.getElementById(_.get(item,'_id')).value,setTitleEdit:setTitleEdit,wirteTitle:"true"})
                        }}>저장</button>
                        <button onClick={()=>{setTitleEdit(false)}}>취소</button>
                    </div>
                </>
                ||
                <>
                    <p onClick={
                        ()=>{
                            if(!chatting){
                                if(!_.isEmpty(selectChatRoom)){
                                    //다른 채팅으로 변경 시 기존 ws는 close 하고 넘어가도록
                                    ws.current.close();
                                }

                                if(widthStyle == 'mini_width'){
                                    // menuClose(false)
                                }
                                
                                setSelectChatRoom(item)
                            }
                        }}
                    >
                        { (widthStyle == 'mini_width') ? (idx + 1) :  _.get(item,'chat_room_name').replace(/"/g, '') }
                    </p>

                    {
                        (widthStyle != 'mini_width') && <>
                              <small>
                                    {
                                        moment(_.get(item,'createdAt')).isSame(moment(), 'day') ? 
                                            moment(_.get(item,'createdAt')).format('HH:mm') : moment(_.get(item,'createdAt')).format('YY/MM/DD')
                                    }
                                </small>
                                <div className={'chatroom_button_container'}>
                                    <button onClick={()=>{setTitleEdit(true)}}>
                                        <img src={'/assets/images/GAI/edit_icon.svg'}/>
                                    </button>
                                    <button onClick={()=>{deleteChatRoom(item)}}>
                                        <img src={'/assets/images/GAI/delete_icon.svg'}/>
                                    </button>
                                </div>
                        
                        </>
                    }
                  
                </>
            }
        </div>
    )
};


export default ChatList;