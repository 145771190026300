import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoading } from "components/Custom";
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';
import { defaultSemesterId } from 'config';
import MD5 from "crypto-js/md5";
import { useForm } from "react-hook-form";
import { Rnd } from 'react-rnd';
import useWindowSize from 'utils/useWindowSize';
import axios from 'axios';

//유저 검색
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import * as authApi from 'lib/auth';
import * as api from 'lib/content'
import { CustomPagination } from 'components/Custom'
import { BoardHeader, BoardList } from 'components/Board_ins';
import * as actions from 'store/actions/content';
import socketIOClient from 'socket.io-client';

import ChatForm from './chatForm'
import GuideForm from './guideForm'
import { size } from 'lodash';

const GaiPopup = ({ set_instructor, trigger, title = '', cw_uuid = '', id = '', setPopupOpen, popupOpen, parent_id = '', mode = '', onSuccess = () => { }, onFailure = () => { }, setModeTitle = '', applis, user = '', data = {}, courseData = {}, setReload, reload, preIns, searchInsClose, SetSearchInsClose }) => {
    const now = new Date();
    const dispatch = useDispatch();
    //const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
    const [open, setOpen] = useState(false);    //open 상태인가?
    const { list = [], loading } = useSelector(({ content }) => ({ ...content }));
    // 유저검색
    const [userCount, setUserCount] = useState(0);
    const [userAllCount, setUserAllCount] = useState(0);
    const [userData, setUserData] = useState(null);
    const { search } = useLocation();
    const [getLoading, setGetLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const { width: widthSize = window.innerWidth, height: heightSize = winodw.innerHeight } = useWindowSize();
    const { register, handleSubmit, setValue, errors, watch, getValues } = useForm();

    const [type, setType] = useState(null)
    const [chatRoomTitle, setChatRoomTitle] = useState('')
    const [chatList, setChatList] = useState([])
    const [minimode, setMinimode] = useState(true)
    const [popupDrag, setPopupDrag] = useState(false)
    const [diffX, setDiffX] = useState(0)
    const [diffY, setDiffY] = useState(0)
    const [popupX, setPopupX] = useState(0)
    const [popupY, setPopupY] = useState(0)
    const [maxWidth, setMaxWidth] = useState(600);
    const [maxHeight, setMaxHeight] = useState(300);

    //리사이즈
    const [width, setWidth] = useState('mini_width');
    const [defaultWidth, setDefaultWidth] = useState(358);
    const [height, setHeight] = useState('normal_heigth');
    const [defaultHeight, setDefaultHeight] = useState(600);

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [socketCheck, setSocketCheck] = useState(false);

    
    const [aiEndpointData, setAiEndpointData] = useState({}); // endpoint 정보 
    const [aiApiList, setAiApiList] = useState([]); // 사용 가능한 API 리스트
    const [usageLimitToken, setUsageLimitToken] = useState({usage : 0 , limit : 0}); // 제한량

    const defaultPosition = (minimode) => {
        let size = {};

        if (minimode) {
            size = {
                x: window.innerWidth < 575 ? window.scrollX : window.scrollX + (!_.isEmpty(localStorage.getItem('gaiMinX')) ? localStorage.getItem('gaiMinX') * 1 : 78),
                y: window.innerWidth < 575 ? window.scrollY : window.scrollY + (!_.isEmpty(localStorage.getItem('gaiMinY')) ? localStorage.getItem('gaiMinY') * 1 : 220),
            }

            return size
        } else {

            size = {
                x: window.innerWidth < 575 ? window.scrollX : window.scrollX + (!_.isEmpty(localStorage.getItem('gaiMaxX')) ? localStorage.getItem('gaiMaxX') * 1 : 78),
                y: window.innerWidth < 575 ? window.scrollY : window.scrollY + (!_.isEmpty(localStorage.getItem('gaiMaxY')) ? localStorage.getItem('gaiMaxY') * 1 : 220),
            }

            return size
        }
    }


    //채팅에서만 사용
    const [chatting, setChatting] = useState(false)

    const onResize = (event, { size }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    let ws = useRef(null);
    const init_chat = () => {
        setChatRoomTitle('')
        setChatList([])
        if (socketCheck) {
            ws.current.close();
        }
    }

    useEffect(() => {
        if (popupOpen) {
            // console.log('채팅 열림')
            setType(localStorage.getItem('aiType'))
            init_chat()
        }
    }, [popupOpen])

    useEffect(() => {
        // console.log(widthSize,"widthSizewidthSize")
        // console.log(heightSize,'heightSizeheightSize')

        if (widthSize && heightSize) {
            setMaxWidth(widthSize - 20);
            setMaxHeight(heightSize - 20)
        }
        if (!_.isEmpty(resizableBox.current)) {
            let gai = document.getElementById("resizable-gai");

            if (!_.isEmpty(gai)) {
                let wd = gai.offsetWidth;
                let he = gai.offsetHeight;

                if (wd < 1500 && wd > 575) {
                    setWidth('normal_width')
                }
                else if (wd <= 575 && wd > 100) {
                    setWidth('mini_width')
                }
                if (he < 800 && he > 600) {
                    setHeight('normal_height')
                }
                else if (he <= 600 && he > 0) {
                    setHeight('mini_height')
                }
            }
        }
    }, [resizableBox, widthSize, heightSize])


    const resizableBox = useRef(null);

    const saveSize = ({ width, height }) => {
        setDefaultWidth(width)
        setDefaultHeight(height)
    }

    const defaultSize = (minimode) => {
        let size = {};

        if (minimode) {
            size = {
                x: window.innerWidth < 575 ? window.scrollX : window.scrollX + (!_.isEmpty(localStorage.getItem('gaiMinX')) ? localStorage.getItem('gaiMinX') * 1 : 228),
                y: window.innerWidth < 575 ? window.scrollY : window.scrollY + (!_.isEmpty(localStorage.getItem('gaiMinY')) ? localStorage.getItem('gaiMinY') * 1 : 70),
                width: (localStorage.getItem('miniSizeWidth') ? localStorage.getItem('miniSizeWidth') : (window.innerWidth < 575 ? window.innerWidth : defaultWidth)),
                height: (localStorage.getItem('miniSizeHeight') ? localStorage.getItem('miniSizeHeight') : (window.innerHeight < 600 ? window.innerHeight : defaultHeight)),
            }

            return size
        } else {

            size = {
                x: window.innerWidth < 575 ? window.scrollX : window.scrollX + (!_.isEmpty(localStorage.getItem('gaiMaxX')) ? localStorage.getItem('gaiMaxX') * 1 : 228),
                y: window.innerWidth < 575 ? window.scrollY : window.scrollY + (!_.isEmpty(localStorage.getItem('gaiMaxY')) ? localStorage.getItem('gaiMaxY') * 1 : 70),
                width: (localStorage.getItem('wideSizeWidth') ? localStorage.getItem('wideSizeWidth') : (window.innerWidth < 575 ? window.innerWidth : defaultWidth)),
                height: (localStorage.getItem('wideSizeHeight') ? localStorage.getItem('wideSizeHeight') : (window.innerHeight < 600 ? window.innerHeight : defaultHeight)),
            }
            return size
        }
    }

    // API 정보 가져오기
    useEffect(()=>{
        if(popupOpen){
            if (_.isEmpty(user))return;
            getAiuuid()
        }
    },[popupOpen, user])

    // 제한 정보 가져오기
    useEffect(()=>{
        if(!_.isEmpty(user) && !_.isEmpty(aiEndpointData)){
            onCheckLimitPrice({user,aiEndpointData})
        }
    },[user, aiEndpointData])

    // 사용 모델과 사이트에 맞춰 endpoint 가져오기
    const getAiuuid = async()=>{
        try{
            const { data : getAiEndpoint1} = await axios.get('/custom-api/ai/ai_chat_room/ai_uuid',{
                params : {
                    model : 'gpt-3.5-turbo-1106',
                    site : 'https://www.gjudec.com',
                    user_id : _.get(user,'_id'),
                    role : _.get(user,'role')
                }
            })


            const { data : getAiEndpoint2} = await axios.get('/custom-api/ai/ai_chat_room/ai_uuid',{
                params : {
                    model : 'gpt-4-1106-preview',
                    site : 'https://www.gjudec.com',
                    user_id : _.get(user,'_id'),
                    role : _.get(user,'role')
                }
            })

            let list = [];
            if(!_.isEmpty(_.get(getAiEndpoint1,'data',{}))){
                list.push( _.get(getAiEndpoint1,'data',{})  )
            }

            if(!_.isEmpty(_.get(getAiEndpoint2,'data',{}))){
                list.push( _.get(getAiEndpoint2,'data',{}) )
            }

            setAiApiList(list)
            setAiEndpointData(list[0])

        }catch(e){
            console.log('err',e)
        }
    }

    // 제한 사용량 체크 
    const onCheckLimitPrice = async({user,aiEndpointData})=>{

        try{

            const address = window.location.protocol+'//'+window.location.host

            const {data : getChkLimitData} = await axios.get("/custom-api/ai/usage_limit/check_up",{
                params : {
                    username : _.get(user,'username'),
                    site : address,
                    ai_api_uuid : _.get(aiEndpointData,'ai_api_uuid'),
                    date : new Date()
                }
            })
            const usage = Number(_.get(getChkLimitData, 'result.userUsage.0.totalPriceSum', 0))
            const limit = Number(_.get(getChkLimitData,'result.userLimit.0.price_limit'))

            setUsageLimitToken({usage : usage.toFixed(3), limit : limit.toFixed(3)})

            return { state : _.get(getChkLimitData,'message'), reason : _.get(getChkLimitData,'data') }

        }catch(e){

        }
    }


    return (
        <Rnd
            id={"resizable-gai"}
            className="resizable-box-gai"
            bounds={'#root'}
            dragHandleClassName={'gai_top'}
            ref={resizableBox}
            default={defaultSize(minimode)}
            enableUserSelectHack={true}
            minHeight={200}
            minWidth={300}
            // position={position}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            onDragStop={(e, direction, position) => {

                //onDrag로 감지하려 했으나 거기는 계속 반응하는 곳이라 stop으로 변경하였습니다
                //이동 시 scroll의 y값을 가져오는 부분은 lastY
                const lastY = _.get(direction, 'lastY')
                //이동 시 scroll의 x값을 가져오는 부분은 lastX
                const lastX = _.get(direction, 'lastX')
                //스크롤 기준으로 가져오는 이유는 전체의 좌표로 할 경우 해당 팝업이 fixed로 되어있어 팝업이 사라질 수 있기 때문.
                //만약에 y가 맨 위로 올라가거나 맨 아래로 들어가게되면 사라질 수 있어 Y가 -면 x로 변경해주기도 해야함

                setPopupX(lastX)

                if (minimode) {
                    localStorage.setItem('gaiMinX', _.get(direction, 'lastX'))
                    localStorage.setItem('gaiMinY', _.get(direction, 'lastY'))
                } else {
                    localStorage.setItem('gaiMaxX', _.get(direction, 'lastX'))
                    localStorage.setItem('gaiMaxY', _.get(direction, 'lastY'))
                }

                if (lastY < 0) {
                    setPopupY(0)
                    document.getElementById('resizable-gai').style.transform = `translateX(${lastX}px) translateY(0px)`

                    if (minimode) {
                        localStorage.setItem('gaiMinY', 0)

                    } else {
                        localStorage.setItem('gaiMaxY', 0)
                    }
                }
            }}
            onResize={(e, direction, ref, delta, position) => {

                setMinimode(true);

                const lastY = _.get(position, 'y')
                const lastX = _.get(position, 'x')


                if (e.clientY < 1) {
                    document.getElementsByClassName('resizable-box-gai')[0].style.transform = `translate(${position.x}px,0)`;
                }

                if (ref.offsetWidth >= maxWidth) {
                    document.getElementsByClassName('resizable-box-gai')[0].style.width = `${maxWidth}px`
                }

                if (ref.offsetHeight >= maxHeight) {
                    document.getElementsByClassName('resizable-box-gai')[0].style.height = `${maxHeight}px`
                }

                setDefaultWidth(ref.offsetWidth)
                setDefaultHeight(ref.offsetHeight)

                if (minimode) {
                    localStorage.setItem('miniSizeWidth', ref.offsetWidth)
                    localStorage.setItem('miniSizeHeight', ref.offsetHeight)
                } else {
                    localStorage.setItem('wideSizeWidth', ref.offsetWidth)
                    localStorage.setItem('wideSizeHeight', ref.offsetHeight)
                }

                if (ref.offsetWidth < 1500 && ref.offsetWidth > 575) {
                    setWidth('normal_width')
                }
                else if (ref.offsetWidth <= 575 && ref.offsetWidth > 100) {
                    setWidth('mini_width')
                }
                if (ref.offsetHeight < 800 && ref.offsetHeight > 600) {
                    setHeight('normal_height')
                }
                else if (ref.offsetHeight <= 600 && ref.offsetHeight > 0) {
                    setHeight('mini_height')
                }

            }}
        >
            <div className={`popup_overlay gai ${type ? type : ''} ${width} ${height} ${minimode ? 'mini' : ''}`} id={'gai_popup'}>
                <div className="popup_content">
                    <div className="backImg">
                        <img src={'/assets/images/GAI/vertical_line.svg'} />
                    </div>
                    <div className="backImg rights">
                        <img src={'/assets/images/GAI/vertical_line.svg'} />
                    </div>
                    <div className={`gai_top`}>
                        <div style={{display :'flex', gap : 10}}>
                            <h3><p

                                onTouchStart={() => {
                                    if (socketCheck) {
                                        ws.current.close();
                                    }
                                    setChatList([])
                                    setType(null)
                                }}
                                onClick={() => {
                                    if (socketCheck) {
                                        ws.current.close();
                                    }
                                    setChatList([])
                                    setType(null)
                                    localStorage.setItem('aiType', null)
                                }} className={'pointer'}>GAI</p>
                                
                                <div className='sub_title_container'>
                                    <div className={'popup_title_point'}></div>
                                    {
                                        !(_.isEmpty(type) || (type == "null")) &&
                                        <p>{_.get(aiEndpointData,'title', '')}</p>
                                    }
                                </div>
                                
                            </h3>
                            <div className={'top_button_container'}>
                            {
                                    !_.isEmpty(type) &&
                                    <button title={'뒤로가기'}
                                        className="backButton"
                                        onTouchStart={() => {
                                            if (!chatting) {
                                                if (socketCheck) {
                                                    ws.current.close();
                                                }
                                                localStorage.setItem('aiType', null)
                                                setChatList([])
                                                setType(null)
                                            }
                                        }}

                                        onClick={() => {
                                            if (!chatting) {
                                                if (socketCheck) {
                                                    ws.current.close();
                                                }
                                                localStorage.setItem('aiType', null)
                                                setChatList([])
                                                setType(null)
                                            }
                                        }}
                                    ><img src={'/assets/images/GAI/return_svg.svg'} /></button>
                                }
                            </div>
                        </div>
                        {
                            chatting &&
                            <div className={'chatting_warning'}>
                                AI가 채팅 답변 중일때는 채팅창의 다른 기능을 사용할 수 없습니다.
                            </div>
                        }

                        <div className={'top_button_container'}>
                            {
                                !(_.isEmpty(type) || (type == "null")) &&
                                <>
                                    <h6 className='usage_container'>
                                        <span className='usage_text'>사용량</span>
                                        <span className='usage_number'> {usageLimitToken.usage}/{usageLimitToken.limit} ($) </span>
                                    </h6>
                                </>
                            }
                            {
                                minimode ?
                                    <button title={'화면확장'}
                                        onClick={() => {
                                            setMinimode(false)
                                            setWidth('normal_width')
                                            setHeight('normal_Height')

                                            document.getElementsByClassName('resizable-box-gai')[0].style.width = `calc(100% - 20px)`
                                            document.getElementsByClassName('resizable-box-gai')[0].style.height = `calc(100% - 20px)`

                                            resizableBox.current.updatePosition({ x: 10, y: 10 })

                                            // let wideSizeHeight = localStorage.getItem("wideSizeHeight");
                                            // let wideSizeWidth = localStorage.getItem("wideSizeWidth");
                                            // let wideSizeX = localStorage.getItem("gaiMaxX");
                                            // let wideSizeY = localStorage.getItem("gaiMaxY");

                                            // if(!_.isEmpty(wideSizeHeight) && !_.isEmpty(wideSizeWidth)){
                                            //     wideSizeHeight = parseInt(wideSizeHeight);
                                            //     wideSizeWidth = parseInt(wideSizeWidth);

                                            //     document.getElementsByClassName('resizable-box-gai')[0].style.width = `${wideSizeWidth}px`
                                            //     document.getElementsByClassName('resizable-box-gai')[0].style.height = `${wideSizeHeight}px`
                                            //     saveSize({width:wideSizeWidth,height:wideSizeHeight})

                                            //     if(wideSizeWidth < 1500 && wideSizeWidth > 575){
                                            //         setWidth('normal_width')
                                            //     }
                                            //     else if(wideSizeWidth <= 575 && wideSizeWidth > 100){
                                            //         setWidth('mini_width')
                                            //     }
                                            //     if(wideSizeHeight<800 && wideSizeHeight>600){
                                            //         setHeight('normal_height')
                                            //     }
                                            //     else if(wideSizeHeight <= 600 && wideSizeHeight>0){
                                            //         setHeight('mini_height')
                                            //     }

                                            //     if(!_.isEmpty(wideSizeX) && !_.isEmpty(wideSizeY)){
                                            //         let pos = {x:parseInt(wideSizeX),y:parseInt(wideSizeY)}
                                            //         resizableBox.current.updatePosition(pos)
                                            //     }

                                            // }else{
                                            //     document.getElementsByClassName('resizable-box-gai')[0].style.width = '1500px'
                                            //     document.getElementsByClassName('resizable-box-gai')[0].style.height = '800px'
                                            //     saveSize({width:1500,height:800})


                                            //     if(!_.isEmpty(wideSizeX) && !_.isEmpty(wideSizeY)){
                                            //         let pos = {x:parseInt(wideSizeX),y:parseInt(wideSizeY)}
                                            //         resizableBox.current.updatePosition(pos)
                                            //     }
                                            // }


                                        }}> <img src={'/assets/images/GAI/narr_button_2.svg'} /> </button>
                                    :
                                    <button title={'이전화면으로 축소'} onClick={() => {
                                        setWidth('mini_width')
                                        setHeight('mini_Height')
                                        setMinimode(true)

                                        let miniSizeHeight = localStorage.getItem("miniSizeHeight");
                                        let miniSizeWidth = localStorage.getItem("miniSizeWidth");
                                        let miniSizeX = localStorage.getItem("gaiMinX");
                                        let miniSizeY = localStorage.getItem("gaiMinY");


                                        if (!_.isEmpty(miniSizeHeight) && !_.isEmpty(miniSizeWidth)) {
                                            miniSizeHeight = parseInt(miniSizeHeight);
                                            miniSizeWidth = parseInt(miniSizeWidth);

                                            if (miniSizeWidth < 1500 && miniSizeWidth > 575) {
                                                setWidth('normal_width')
                                            }
                                            else if (miniSizeWidth <= 575 && miniSizeWidth > 100) {
                                                setWidth('mini_width')
                                            }
                                            if (miniSizeHeight < 800 && miniSizeHeight > 600) {
                                                setHeight('normal_height')
                                            }
                                            else if (miniSizeHeight <= 600 && miniSizeHeight > 0) {
                                                setHeight('mini_height')
                                            }


                                            document.getElementsByClassName('resizable-box-gai')[0].style.width = `${miniSizeWidth}px`
                                            document.getElementsByClassName('resizable-box-gai')[0].style.height = `${miniSizeHeight}px`
                                            saveSize({ width: miniSizeWidth, height: miniSizeHeight })

                                            if (!_.isEmpty(miniSizeX) && !_.isEmpty(miniSizeY)) {
                                                let pos = { x: parseInt(miniSizeX), y: parseInt(miniSizeY) }
                                                resizableBox.current.updatePosition(pos)
                                            }

                                        } else {
                                            document.getElementsByClassName('resizable-box-gai')[0].style.width = '370px'
                                            document.getElementsByClassName('resizable-box-gai')[0].style.height = '700px'
                                            saveSize({ width: 370, height: 700 })


                                            if (!_.isEmpty(miniSizeX) && !_.isEmpty(miniSizeY)) {
                                                let pos = { x: parseInt(miniSizeX), y: parseInt(miniSizeY) }
                                                resizableBox.current.updatePosition(pos)
                                            }
                                        }

                                    }}> <img src={'/assets/images/GAI/wide_button_2.svg'} /> </button>
                            }
                            <button title={'채팅닫기'}
                                onTouchStart={() => {
                                    if (!chatting) {
                                        if (socketCheck) {
                                            ws.current.close();
                                        }
                                        setOpen(false)
                                        setChatList([])
                                        setPopupOpen(false)
                                    }
                                }}
                                onClick={() => {
                                    if (!chatting) {
                                        if (socketCheck) {
                                            ws.current.close();
                                        }
                                        setChatList([])
                                        setPopupOpen(false)
                                    }
                                }
                                    //popupClose
                                }> <img src={'/assets/images/GAI/close_button_2.svg'} /> </button>
                        </div>
                    </div>

                    {/* 초기 화면 */}
                    {
                        (_.isEmpty(type) || (type == "null")) &&
                        <>
                            <div className={'gai_content'}>
                                <div className={'content_title'}>
                                    {/* <div className="imgWrap">
                                             <img src={'/assets/images/GAI/GAI_Icon_2.svg'} className="gai_icons"/>
                                            </div> */}

                                    <span className="title_first">안녕하세요,</span>
                                    <img className={'logo'} src={'/assets/images/GAI/GAI_logo_2.svg'} />
                                    <span className="title_second">입니다</span>
                                </div>
                                <div className={'content_title_sub'}>
                                    GJUDEC에 대해 궁금한 것이 있다면<br className="minerBr" /> <span className="blues">가이(Gai)</span>에게 물어봐주세요
                                </div>

                                <div className={'content_container'}>
                                    <div className={'content_buttons'}>

                                        {
                                            aiApiList.map(api=>{
                                                return (
                                                    <button onClick={() => {
                                                        localStorage.setItem('aiType', 'chat')
                                                        setAiEndpointData(api)
                                                        setType('chat')
                                                    }}>
                                                        <div className="iconBox">
                                                            <img src={'/assets/images/GAI/message_icon_blue.svg'} />
                                                        </div>
                                                        <p className="inflex">
                                                            {_.get(api,'title')}
                                                            <img src={'/assets/images/GAI/in_buttons.svg'} />
                                                        </p>
                                                    </button>
                                                )
                                            })
                                        }
                                        {/* 현재 사용 여부를 확인할 단계는 아니라서 가려둠. 채팅 먼저 우선 개발 진행 */}
                                        {/* <button onClick={()=>{setType('search')}}><img src={'/assets/images/GAI/search_icon.svg'}/><p>지식검색</p></button> */}
                                        {/* <button onClick={() => {
                                            localStorage.setItem('aiType', 'method')
                                            setType('method')
                                        }}>
                                            <div className="iconBox">
                                                <img src={'/assets/images/GAI/manual_icon.svg'} className="manual_icons" />
                                            </div>
                                            <p className="inflex">
                                                GJUDEC 사용 매뉴얼
                                                <img src={'/assets/images/GAI/in_buttons.svg'} />
                                            </p>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            <div className={'gai_bottom'}>
                                <img src={'/assets/images/GAI/GAI_logo.svg'} />
                            </div>
                        </>
                    }
                    {/* 채팅일 경우 */}
                    {
                        type == 'chat' &&
                        <ChatForm setSocketCheck={setSocketCheck} popOpen={popupOpen} chatting={chatting} setChatting={setChatting}
                          widthStyle={width} minimode={minimode} setChatRoomTitle={setChatRoomTitle} user={user} setChatList={setChatList}
                          chatRoomTitle={chatRoomTitle} init_chat={init_chat} chatList={chatList} ws={ws}
                          aiEndpointData={aiEndpointData} setUsageLimitToken={setUsageLimitToken}
                        />
                    }
                    {/* 채팅일 경우 */}
                    {
                        type == 'method' &&
                        <GuideForm setSocketCheck={setSocketCheck} chatting={chatting} setChatting={setChatting} widthStyle={width}
                          minimode={minimode} setChatRoomTitle={setChatRoomTitle} user={user} setChatList={setChatList}
                          chatRoomTitle={chatRoomTitle} init_chat={init_chat} chatList={chatList} ws={ws}
                          aiEndpointData={aiEndpointData} setUsageLimitToken={setUsageLimitToken}
                        />
                    }
                </div>
            </div>
        </Rnd>
    )
};


export default GaiPopup;