import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useCookies } from 'react-cookie'
import { _ } from 'core-js';

const quicklist = [
    // {
    //     icon: '/assets/images/quick_icon_1.png',
    //     title: '회원가입',
    //     url: '/member/register'
    // },
    /*    {
            icon: '/assets/images/quick_register.png',
            title: '회원가입',
            url: '/member/register/information'
            /!*url: 'https://lms.gjudec.com/member/ext_login',*!/
            /!*type:'a'*!/
        },*/
    {
        icon: '/assets/images/main/lms.svg',
        title: '통합 LMS',
        url: 'https://lms.gjudec.com/member/ext_login',
        type: 'a'
        //url: '/program/supportprogram'
    },
    // {
    //   icon: '/assets/images/quick_icon_4.png',
    //   title: '공간/장비예약',
    //   url: ''
    // },
    {
        icon: '/assets/images/main/studio.svg',
        title: '스튜디오예약',
        url: '/university/studio'
        //url: '/about/introduction'
    },
    {
        icon: '/assets/images/main/content.svg',
        title: '콘텐츠품질관리',
        //url: '#'
        url: '/content/auditcontent'
    },
    {
        icon: '/assets/images/main/eBook.svg',
        title: 'eBook',
        url: '/community/reference/ebook'
    },
    {
        icon: '/assets/images/main/meta.svg',
        title: '메타버스',
        url: '/university/metaverse',
    },
    {
        icon: '/assets/images/main/error.svg',
        title: '장애접수',
        url: '/help/disabilityreception',
    },
    {
        icon: '/assets/images/digibarogagi.svg',
        title: '교육캠프',
        url: '/digitalsprout',
    }
];

const Quick = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['isQuickMenu']);
    const location = useLocation()
    const { user, isLoggedIn, loading: userLoading } = useSelector(({ auth }) => ({ ...auth }));
    const [toggle, setToggle] = useState((
        _.isUndefined(cookies.isQuickMenu) || cookies.isQuickMenu == 'true'
    ) && true || false);

    useEffect(() => {
        _.isUndefined(cookies.isQuickMenu) && setCookie('isQuickMenu', false, { path: '/' });
    }, []);

    const handleToggle = (isToggle) => {
        setCookie('isQuickMenu', isToggle, { path: '/' });
        setToggle(isToggle);
    }


    return (
        <div className={`quick ${toggle && 'active'}`}>
            {/*<button className="quick__toggle" onClick={() => handleToggle(!toggle)}>
                <div>{toggle}</div>
                <img src={'/assets/images/quick-close.png'} alt="퀵메뉴 열고 닫기"/>
            </button>*/}

            <div className="quick__content">
                <div className="quick__header">
                    {/*전남콘텐츠코리아랩<br/>*/}
                    {/*오신 것 환영합니다*/}
                    {/*<img src={'/assets/images/main-quick-1.png'} alt="퀵메뉴 로고"/>*/}
                </div>
                <h2 className="quick__menu"></h2>
                <div className="quick__body">
                    {/** 회원가입 링크 */}
                    {/*{(!user || !isLoggedIn) && (*/}
                    {/*    <Link to={`/member/register/information`}>*/}
                    {/*        <span><img src={`/assets/images/quick_icon_1.png`} alt=""/></span> 회원가입*/}
                    {/*    </Link>*/}
                    {/*)}*/}

                    {quicklist.map(item => {

                        if (_.isEmpty(_.get(item, 'type'))) {
                            if (item.title == '콘텐츠품질관리') {
                                if (_.isEmpty(user)) {
                                    return (
                                        <Link className='quick_hover' onClick={() => {
                                            swal({
                                                title: `권한이 없습니다.`, text: '로그인 후 이용할 수 있습니다.',
                                                icon: "warning", timer: 3000, buttons: false,
                                            })
                                        }}
                                            key={item.title} >
                                            <div className='quick_img'><img src={item.icon} alt="" />
                                                <span className='quick_txt'>{item.title}</span>
                                            </div>
                                        </Link>
                                    )
                                }
                                else {
                                    return (
                                        <Link className='quick_hover' to={item.url} key={item.title} >
                                            <div className='quick_img'><img src={item.icon} alt="" />
                                                <span className='quick_txt'>{item.title}</span>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            else {
                                return (
                                    <Link className='quick_hover' to={item.url} key={item.title} >
                                        <div className='quick_img'><img src={item.icon} alt="" />
                                            <span className='quick_txt'>{item.title}</span>
                                        </div>
                                    </Link>
                                )
                            }


                        }
                        else {

                            if (!_.isEmpty(user) && isLoggedIn) {
                                return (
                                    <a className='quick_hover' href="https://lms.gjudec.com/home/dashboard" key={item.title} >
                                        <div className='quick_img'><img src={item.icon} alt="" />
                                            <span className='quick_txt'>{item.title}</span>
                                        </div>
                                    </a>
                                )
                            } else {
                                return (
                                    <Link to="/member/login" key={item.title}>
                                        <div className='quick_img'><img src={item.icon} alt="" />
                                            <span className='quick_txt'>{item.title}</span>
                                        </div>
                                    </Link>
                                )
                            }

                        }
                    })}
                </div>
                <div className="quick__foot">
                    <a href='#'>
                        <img src='/assets/images/quick_allow.png' alt="" />
                        <span>TOP</span>
                    </a>
                    {/*<h2>
                        <img src={'/assets/images/quick_icon_7.png'} alt="문의 연락처"/> 문의
                    </h2>
                    <h3>
                        062-530-5075 ,2595
                    </h3>
                    <br/>
                    <h4>문의시간</h4>
                    <p>
                        월~금 09시~18시<br/>
                        주말 및 공휴일 휴무
                    </p>*/}
                </div>
            </div>
        </div>
    );
};

export default Quick;
