/*
 takeEvery: 여러번 실행했을때, 여러번 계속해서 실행되도 되는것.
 takeLatest: 여러번 실행했을때, 한번만 실행되야 되는것. ex)로그인 버튼
*/
// import {put, spawn, takeEvery, takeLatest, call, getContext} from 'redux-saga/effects'
import {put, spawn, takeEvery, takeLatest, call, delay, select} from 'redux-saga/effects'
import * as actions from 'store/actions/users';
import axios from 'axios';
import qs from 'qs';
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import config from 'config'
import * as api from 'lib/users'

const {code: apiCode} = config;

/*
사용자 목록 불러오기
payload => {
    userRole: 유저권한, "authorized user" | "unauthorized user"
    pager: 주석쓰세요,
    searchdata: 주석쓰세요
}
 */
function* getUsersSaga({payload}) {
    try {
        const {data, status} = yield call(api.getUserData, {...payload});
        if (status === 204) throw new Error('사용자 목록을 불러오지 못하였습니다.');
        yield put(actions.loadUsersSuccess(data));
    } catch (e) {
        console.log(e.message, 'getUsersSaga error')
        yield put(actions.loadUsersFailure());
    } finally {
        yield put(hideLoading())
    }
}

function* watchUsers() {
    yield takeLatest(actions.LOAD_USERS_REQUEST, getUsersSaga)
}

export default function* root() {
    yield spawn(watchUsers);
}
