/** 장비목록 컴포넌트 */
import React, {useState, useEffect, useCallback} from 'react';

import RentalHeader from 'components/RentalHeader';
import EquipmentSlider from './EquipmentSlider';

//graphql 관련
import {useLazyQuery} from "@apollo/react-hooks";
import * as query from "queries/resource";

const Index = ({size = 8, variables = {}, hiddenHeader = true}) => {
    const [getCategory, {data: category}] = useLazyQuery(query.EQUIPMENT_RESOURCES_CATEGORIES_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({equipmentResourceCategories = []}) => setCateList([
            {id: '', title: '전체'},
            ...equipmentResourceCategories
        ])
    });
    const [getData, {loading}] = useLazyQuery(query.EQUIPMENT_RESOURCES_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({equipmentResources = []}) => setList(_.chunk(equipmentResources, size))
    });
    const [list, setList] = useState([]);
    const [cateList, setCateList] = useState([]);
    const [tab, setTab] = useState('');
    const handleChangeTab = useCallback((id) => {
        setTab(id);
        const _variables = {...variables}
        // if (id) _variables["where"] = {"equipment_resource_category.id": id}
        if (id) _variables["where"] = {equipment_resource_category: id}
        getData({variables: {..._variables}})
    }, [tab])

    useEffect(() => {
        getCategory({variables: {sort: "weight:ASC"}})
        getData({variables: {...variables}})
    }, []);

    return (
        <div className="equipmentInfo">
            <RentalHeader
                title={'장비 안내'}
                tabList={cateList}
                tab={tab}
                onChangeTag={handleChangeTab}
                hiddenHeader={hiddenHeader}
            />
            <div className="equipmentInfo__content">
                {!loading && (<EquipmentSlider list={list}/>) || (<div className={`text-center`}>...로딩 중</div>)}
            </div>
        </div>
    );
};

export default Index;
