import React from 'react';
import { Link } from 'react-router-dom';

const ReservationSystemLink = () => {
  return (
    <Link to={''} className="rsvlink">예약관리시스템</Link>
  );
};

export default ReservationSystemLink;
