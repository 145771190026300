import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import {loadingBarMiddleware} from 'react-redux-loading-bar'
import rootSaga from './sagas';
import history from 'utils/history';
import {composeWithDevTools} from "redux-devtools-extension";

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware({context: {history}});
    const composeEnhancers = composeWithDevTools({trace: true});
    const enhancer = composeEnhancers(
        applyMiddleware(
            sagaMiddleware,
            loadingBarMiddleware({
                promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
            }),
        )
    );

    const store = createStore(
        reducer,
        initialState,
        enhancer
    );
    sagaMiddleware.run(rootSaga);
    return store;
};
