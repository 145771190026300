import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ReservationSystemLink from 'components/ReservationSystemLink';

// 이벤트 클릭했을때, 이동할 경우 아래 돔 수정
function renderEventContent(eventInfo) {
    return (
        <>
            <p>{eventInfo.event.title}</p>
        </>
    );
}

const Index = ({events = [], hiddenHeader = false}) => {
    return (
        <div className="calendar">
            <h2 className="calendar__title">공간/장비 예약캘린더</h2>
            {!hiddenHeader && (
                <div className="linkbox">
                    <ReservationSystemLink/>
                </div>
            )}
            <FullCalendar
                schedulerLicenseKey={''}
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                eventContent={renderEventContent}
                locale={'ko'}
                headerToolbar={{
                    left: 'prev title next',
                    center: null,
                    right: null
                }}
                events={events}
            />
        </div>
    );
};

export default Index;
