import React from 'react';
import { Link } from 'react-router-dom';

const RightBoard = ({ user = {}, isLoggedIn }) => {

    const winurl = window.location.href;
    const url = winurl.indexOf('sublms');
    const newurl = winurl.substring(0, url);
    const url1 = newurl + 'sublms';

    // console.log(user,isLoggedIn,"isLoggedInisLoggedInisLoggedIn")

    return (
        <div className="col col--right">
            <div className="top">
                <h1 className="">
                    바로가기
                </h1>
            </div>
            <div className="contbox">

                <a href={'https://www.youtube.com/@gjudec'} target="_blank">
                    <div>
                        <img src="/assets/images/youtube.svg" alt="" />
                    </div>
                    <p>GJUDEC YOUTUBE</p>
                </a>

                <Link to={'/resource/innovation_brief'}>
                    <div>
                        <img src="/assets/images/brief.svg" alt="" />
                    </div>
                    <p>혁신 브리프</p>
                </Link>
                {/*<Link to={'/content/repository'}>
                    <div>
                        <img src="/assets/images/main-quick-2.png" alt=""/>
                    </div>
                    <p>공개강좌</p>
                </Link>*/}
                {/* <Link to={'/about/introduction'}>
                    <div>
                        <img src="/assets/images/main-quick-1.png" alt="" />
                    </div>
                    <p>GJUDEC소개</p>
                </Link> */}

                {/* <Link to={'/content/metaverse'}>
                    <div>
                        <img src="/assets/images/VSTORY-2.svg" alt="" />
                    </div>
                    <p>메타버스</p>
                </Link> */}

                <Link to={'/digitalsprout'}>
                    <div>
                        <img src="/assets/images/VSTORY-digi.svg" alt="" />
                    </div>
                    <p>교육캠프</p>
                </Link>

                <Link to={'/community/letter_news'}>
                    <div>
                        <img src="/assets/images/letter.svg" alt="" />
                    </div>
                    <p>뉴스레터</p>
                </Link>

            </div>
        </div>
        // {window.location.href === url1 && (
        //     <div className="col col--right">
        //         <div className="top">
        //             <button className="">
        //                 메롱
        //             </button>
        //         </div>
        //         <div className="contbox">
        //             <Link to={'/about/introduction'}>
        //                 <div>
        //                     <img src="/assets/images/main-quick-1.png" alt=""/>
        //                 </div>
        //                 <p>GJUDEC 소개</p>
        //             </Link>
        //             <Link to={'/content/repository'}>
        //                 <div>
        //                     <img src="/assets/images/main-quick-2.png" alt=""/>
        //                 </div>
        //                 <p>공개강좌</p>
        //             </Link>
        //             <Link to={'/content/repository'}>
        //                 <div>
        //                     <img src="/assets/images/main-quick-3.png" alt=""/>
        //                 </div>
        //                 <p>통합 콘텐츠 저장소</p>
        //             </Link>
        //             <Link to={'/'}>
        //                 <div>
        //                     <img src="/assets/images/main-quick-4.png" alt=""/>
        //                 </div>
        //                 <p>헬프 센터</p>
        //             </Link>
        //             <Link to={'/'}>
        //                 <div>
        //                     <img src="/assets/images/main-quick-5.png" alt=""/>
        //                 </div>
        //                 <p>홈페이지 들어가기</p>
        //             </Link>
        //         </div>
        //     </div>
        // )}
    );
};

export default RightBoard;
