import {combineReducers} from 'redux';
import auth from './auth';
import content from './content';
import users from './users';
import {loadingBarReducer} from 'react-redux-loading-bar'

export default combineReducers({
    auth,
    content,
    users,
    // reducers Loading
    loadingBar: loadingBarReducer,
})
