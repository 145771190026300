import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";

const CustomLoading = ({
                           loading,
                           containerStyle = {position: 'relative', minHeight: '300px', flex: '0 0 100%'},
                           contentStyle = {background: 'none'}
                       }) => {
    if (!loading) return null;
    return (
        <div style={containerStyle}>
            <div className="loading-container" style={contentStyle}>
                <BeatLoader size={15} margin={2} color="#123abc" loading={loading}/>
            </div>
        </div>
    );
}

export default CustomLoading;
