import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoading } from "components/Custom";
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';
import { defaultSemesterId } from 'config';
import MD5 from "crypto-js/md5";
import { useForm } from "react-hook-form";

//유저 검색
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import * as authApi from 'lib/auth';
import * as api from 'lib/content'
import { CustomPagination } from 'components/Custom'
import { BoardHeader, BoardList } from 'components/Board_ins';
import * as actions from 'store/actions/content';
import ChatList from './chatList';

import axios from 'axios';


const ChatForm = ({ chatRoomTitle,popOpen,setSocketCheck,init_chat, ws, widthStyle, chatList=[], setChatList={},minimode, closeMenu, user,
    setChatting, chatting, aiEndpointData, setUsageLimitToken }) => {


    const address = `${window.location.protocol}//${window.location.host}`

    const { register, handleSubmit, setValue, errors, watch, getValues, setError, control } = useForm({});
    const [chatRoom, setChatRoom] = useState([])
   

    //채팅 room 지정(처음에는 null로 지정됨)
    const [selectChatRoom, setSelectChatRoom] = useState(null);
    const [menuOpen, setMenuOpen] = useState(true)
    const [prevChatResult, setPrevChatResult] = useState('')
    const [prevChatValue, setPrevChatValue] = useState('')
    const [loading, setLoading] = useState(true)
    const [roomId,setRoomId] = useState('')
    const [chatValue,setChatValue] = useState(null)
    const [chatResult,setChatResult] = useState('')
    const chatEndRef = useRef(null);

    const [isUnlimit, setIsUnlimit] = useState({ state  : true , reason : ''}); // 채팅 제한에 걸렸는지 확인

    const socketConnect = async ({aiEndpointData}) => {

        try{
            ws.current = new WebSocket(`wss://lc.gjudec.com/${roomId}?site=${address}&model=${_.get(aiEndpointData,'model')}`);
            ws.current.onopen = () => {
                setSocketCheck(true)
            };
            ws.current.onmessage = (evt) => {
                // console.log(_.get(evt,'data','{}'))
                if(!_.isEmpty(_.get(evt,'data','{}'))){
                    const event_data = JSON.parse(_.get(evt,'data','{}'))
                    if(_.get(event_data,'status', 200) < 300 || _.get(event_data,'status')=='start' || _.get(event_data,'status')=='end' ){
                        const status = JSON
                        if(_.get(event_data,'status') == 'end'){
                            setChatting(false)
                        }
                        else if(_.get(event_data,'status') == 'start'){
                            setChatting(true)
                        }
                        else{
                            setChatResult(prev=>prev+_.get(event_data,'message'))
                        }
                    }else{
                        console.log('사이트를 등록하세요');
                        ws.current.close()
                    }
                }
            };
            ws.current.onclose = (error) => {
                if (error.code === 1006) {
                    // 연결이 강제로 끊겼을 때(예: 서버 다운) 재연결 시도
                    setTimeout(() => {
                        socketConnect();
                    }, 100); // 0.1초 후 재연결 시도
                } else {
                  // 다른 이유로 연결이 끊겼을 때 처리
                    setSocketCheck(false)
                    console.log('WebSocket connection closed:', event);
                }
            };            
        }catch(e){
            console.log('연결 오류')
        }
    }

    const createChatRoom = async () => {
        let params = {
            chat_room_id:MD5(`${_.get(user,'username')}_${new Date()}_새 채팅 추가`).toString(),
            chat_room_name:'새 채팅 추가',
            chat_user:user,
            chat_model:_.get(aiEndpointData,'model'),
            type:'chat',
            createdAt:moment().format('YYYY-MM-DD HH:mm:ss'),
            updatedAt:moment().format('YYYY-MM-DD HH:mm:ss'),
        }
        
        let new_chatRoom = _.cloneDeep(chatRoom)

        const {data : createdData} = await axios.post('/custom-api/ai/ai_chat_room/create',{params})
        
        new_chatRoom.push(createdData)
        setSelectChatRoom(createdData)
        setChatRoom(new_chatRoom)
        localStorage.setItem('selectChatRoom',JSON.stringify(createdData))
        localStorage.setItem('selectChatRoom_id',_.get(createdData,'chat_room_id'))
        setRoomId(_.get(createdData,'chat_room_id'))

        setLoading(prev=>!prev)
    }

    const deleteChatRoom = async (item) => {
        swal({
            title: '해당 채팅을 삭제하시겠습니까', text: '확인버튼을 누르면 삭제됩니다.',
            icon: "warning",
            buttons: {
                cancle: {
                    text: '취소',
                    value: false,
                    className: ''
                },
                confirm: {
                    text: '확인',
                    value: true,
                    className: 'btn_error'
                }
            }
        }).then(async (btn_result) => {
            if (!btn_result) {
                return;
            } else {
                await axios.post('/custom-api/ai/ai_chat_room/delete',{...item})
                setChatRoom(prev=>{
                    let deleteIdx = 0;
                    let newChatRoom = prev.filter((old,oidx)=>{
                        if (_.get(old,'_id') ==_.get(item,'_id') ){
                            deleteIdx = oidx;
                        }
                        return _.get(old,'_id')!==_.get(item,'_id')
                    })

                    if(!_.isEmpty(newChatRoom)){
                        if(deleteIdx-1 < 0){    
                            setSelectChatRoom(newChatRoom[0])
                            setRoomId(_.get(newChatRoom[0],'chat_room_id'))
                            localStorage.setItem('selectChatRoom',JSON.stringify(newChatRoom[0]))
                            localStorage.setItem('selectChatRoom_id',_.get(newChatRoom[0],'chat_room_id'))
                        }else{
                            setSelectChatRoom(newChatRoom[deleteIdx-1])
                            setRoomId(_.get(newChatRoom[deleteIdx-1],'chat_room_id'))
                            localStorage.setItem('selectChatRoom',JSON.stringify(newChatRoom[deleteIdx-1]))
                            localStorage.setItem('selectChatRoom_id',_.get(newChatRoom[deleteIdx-1],'chat_room_id'))
                        }
                    }
                    
                    
                    return newChatRoom;
                    
                })
                setLoading(prev=>!prev)
            }
        })
    }
    

    const editTitle = async ({item,changeTitle,setTitleEdit,wirteTitle}) => {

        let changeParams = {item,changeTitle}
        if(!_.isEmpty(wirteTitle)){
            changeParams={item,changeTitle,writeTitle:true}
        }
        await axios.post('/custom-api/ai/ai_chat_room/edit',changeParams)
        setLoading(prev=>!prev)
        if(!_.isEmpty(wirteTitle)){
            setTitleEdit(false)
        }
    }



    useEffect(()=>{
        //이전 답변을 저장하기 위해
        if(!_.isEmpty(chatResult)){
            setPrevChatResult(chatResult)
        }
    },[chatResult])

    useEffect(()=>{
        //채팅이 진행 중인지 텍스트에 출력
        if(chatting){
            setValue('chatValue','AI가 답변을 생성중입니다')
        }
        else{
            setValue('chatValue','')
        }
    },[chatting])

    useEffect(()=>{
        let chatValuelength = 0;
        //이전 입력 값을 저장하기 위해
        if(!_.isEmpty(chatValue)){
            setPrevChatValue(chatValue)
            if(chatValue.length > 1000){
                swal({
                    title: '채팅 내용은 1000자를 넘을 수 없습니다.', text: '',
                    icon: "warning",
                    buttons: {
                        confirm: {
                            text: '확인',
                            value: true,
                            className: 'btn_error'
                        }
                    }
                }).then(async (btn_result) => {
                    setChatValue(chatValue.slice(0,1000))
                    setValue('chatValue',chatValue.slice(0,1000))
                })
            }
        }
    },[chatValue])

    useEffect(()=>{
        //선택된 채팅 룸과 이전 채팅 결과 / 이전 채팅 값이 있을 경우에 DB에 저장한다. 저장 후에는 이전 채팅 값들을 전부 null 상태로 돌려야한다.
        if(!_.isEmpty(prevChatValue) && !_.isEmpty(prevChatResult) && !_.isEmpty(selectChatRoom) && !chatting){
            //saveChatData()
        }
    },[prevChatValue,prevChatResult,selectChatRoom,chatting])

    useEffect(()=>{
        if(!_.isEmpty(chatRoomTitle) && _.isEmpty(selectChatRoom)){
            createChatRoom()
        }
       
    },[chatRoomTitle,selectChatRoom])

    useEffect(async ()=>{
        if(!_.isEmpty(selectChatRoom)){
            setRoomId(_.get(selectChatRoom,'chat_room_id'))
            const {data} = await axios.get(`/custom-api/ai/ai_chatting/get?chat_room_id=${_.get(selectChatRoom,'chat_room_id')}`)
            setChatList(data)
            let ai_answer = data.filter(item=>{return(item.chatting == true)})
            if(!_.isEmpty(ai_answer[ai_answer.length-1])){
                setChatting(true)
                //채팅 답변이 정상적으로 등록되지 않음
                if(ai_answer[ai_answer.length-1].chatting_status == 'typing'){
                    let intervalId = setInterval(async () => {
                        const {data} = await axios.get(`/custom-api/ai/ai_chatting/get?chat_room_id=${_.get(selectChatRoom,'chat_room_id')}`)
                        setChatList(data)
                        let ai_answer = data.filter(item=>{return(item.chatting == true)})
                        let ai_answer_data = data[data.length-1]
                        // console.log(ai_answer_data)
                        if(_.isEmpty(ai_answer) && !_.isEmpty(_.get(ai_answer_data,'answer'))){
                            clearInterval(intervalId);
                            setChatting(false)
                        }
                    }, 1000); // 1초마다 실행, 여기서 1000은 밀리초 단위입니다.
                }
            }
            localStorage.setItem('selectChatRoom_id',_.get(selectChatRoom,'chat_room_id'))
            localStorage.setItem('selectChatRoom',JSON.stringify(selectChatRoom))
        }
    },[selectChatRoom])

    useEffect(async ()=>{
        if(!_.isEmpty(user) && !_.isEmpty(aiEndpointData)){
            const {data = []} = await axios.get(`/custom-api/ai/ai_chat_room/get?user_id=${_.get(user,'username')}&type=chat&model=${_.get(aiEndpointData,'model')}`)
            //setChatRoom(data)
            if(_.isEmpty(data)){
                createChatRoom()
            }
            else{
                setSelectChatRoom(data[data.length-1])
                setChatRoom(data)
                localStorage.setItem('selectChatRoom',JSON.stringify(data[data.length-1]))
                localStorage.setItem('selectChatRoom_id',_.get(data[data.length-1],'chat_room_id'))
                setRoomId(_.get(data[data.length-1],'chat_room_id'))
            }
        }
    },[user, loading, aiEndpointData])


    useEffect(()=>{
        if(!_.isEmpty(chatEndRef.current)){
            chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
        }
    },[chatList,chatEndRef,chatValue, chatting, chatResult])


    useEffect(()=>{
        if(!_.isEmpty(chatResult) && !chatting){
            let chatListClone = _.cloneDeep(chatList)
            chatListClone.push({
                question:chatValue,
                question_time:new Date(),
                answer:chatResult,
                answer_time:new Date()
            })
            setChatList(chatListClone)
            setChatResult('')
            setChatValue('')
        }
    },[chatting,chatResult,chatList])

    useEffect(async ()=>{
        if(!_.isEmpty(roomId) && !_.isEmpty(aiEndpointData)){
            socketConnect({aiEndpointData})
        }

        // return ()=>{
        //     ws.current?.close()
        // }
    },[roomId, aiEndpointData])

    useEffect(()=>{
        if(popOpen){
            if(!_.isEmpty(chatRoom)){
                // console.log(chatRoom,'chatRoomchatRoom')
                //이전에 열었던 채팅방 세팅(여기가 처음 열렸을 때 세팅이 필요함)
                if(!_.isEmpty(localStorage.getItem('selectChatRoom')) && !_.isEmpty(localStorage.getItem('selectChatRoom_id'))){
                    setSelectChatRoom(JSON.parse(localStorage.getItem('selectChatRoom')))
                    setRoomId(localStorage.getItem('selectChatRoom_id'))
                }
                else{
                    setSelectChatRoom(chatRoom[chatRoom.length-1])
                }
            }
        }
        
    },[chatRoom,popOpen])


    const handleKeyDownChatting = async (event) => {
        
        //엔터 누르면 실행되도록
        if (event.shiftKey && event.key === 'Enter') {
            //쉬프트 엔터의 입력이 우선적으로 체크되어야하므로 추가가 필요함
        }
        else if (event.key === 'Enter') {
            event.preventDefault();
            const { state, data : limitReason } = await onCheckLimitPrice({user,aiEndpointData})
            if(!state)return null;
            

            if (_.isEmpty(selectChatRoom)) {
                createChatRoom()
            }
            else {
                if (!_.isEmpty(watch('chatValue')) && !chatting) {
                    if (_.isEmpty(chatList)) {
                        // console.log('기존에 있던 채팅 리스트가 없어서 여기로 옵니다')
                        //채팅 리스트가 없을 경우에 제목을 수정해줘야함
                        editTitle({ item: selectChatRoom, changeTitle: chatValue.slice(0, 10) })
                    }
                    //채팅 데이터 저장
                    //채팅 데이터 저장 시 서로가 같은 질문 - 답변이라는 것을 알기 위해 chat_id는 같은 값으로 설정한다
                    let chat_id = MD5(`${_.get(user, 'username')}_${new Date()}_${chatValue}`).toString();
                    //유저 params
                    let user_params = {
                        chat_room_id: _.get(selectChatRoom, 'chat_room_id'),
                        chat_id: chat_id,
                        user: _.get(user, 'username'),
                        chat: watch('chatValue'),
                        chat_model: _.get(aiEndpointData,'model'),
                        chat_type: 'human',
                        site: address,
                        uuid: _.get(aiEndpointData,'ai_api_uuid'),
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                    }
                    //ai 답변 params
                    let ai_params = {
                        chat_room_id: _.get(selectChatRoom, 'chat_room_id'),
                        chat_id: chat_id,
                        user: _.get(user, 'username'),
                        chat: '',
                        chat_model: _.get(aiEndpointData,'model'),
                        chat_type: 'robot',
                        site: address,
                        uuid: _.get(aiEndpointData,'ai_api_uuid'),
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                    }
                    await ws.current.send(JSON.stringify({ message: chatValue, room_id: roomId, type: 'chat', user_params, ai_params }))
                    setValue('chatValue', '')
                }
            }
        }
    }


    // 제한 정보 가져오기
    useEffect(()=>{
        if(!_.isEmpty(user) && !_.isEmpty(aiEndpointData)){
            onCheckLimitPrice({user,aiEndpointData})
        }
    },[user, aiEndpointData])

    // 제한 사용량 체크 
    const onCheckLimitPrice = async({user,aiEndpointData})=>{
        try{
            // console.log('onCheckLimitPrice', aiEndpointData)
            const {data : getChkLimitData} = await axios.get("/custom-api/ai/usage_limit/check_up",{
                params : {
                    username : _.get(user,'username'),
                    site : address,
                    ai_api_uuid : _.get(aiEndpointData,'ai_api_uuid'),
                    date : new Date()
                }
            })
            
            setIsUnlimit({ state : _.get(getChkLimitData,'message'), reason : _.get(getChkLimitData,'data') })

            const usage = Number(_.get(getChkLimitData, 'result.userUsage.0.totalPriceSum', 0))

            setUsageLimitToken(prev=>({...prev, usage : usage.toFixed(3)}))
            
            if(!_.get(getChkLimitData,'message')){
                swal({
                    title : '사용량 초과', text : `[${ _.get(getChkLimitData,'data') }] 초과로 인해 답변을 받을 수 없습니다.`, timer : 3000, buttons : false, icon : 'error'
                })
            };

            return { state : _.get(getChkLimitData,'message'), reason : _.get(getChkLimitData,'data') }
        }catch(e){

        }
    }


    return (
        <div className={'chat_form'}>
            <div className={`left_container ${menuOpen ? 'active' : 'deactive'}`}>
                {
                    !menuOpen ? 
                    <div className={'open_container'}>
                        <button className={'open_button'} onClick={()=>{setMenuOpen(true)}}>
                            <img src={'/assets/images/GAI/next_icons.svg'}/>
                        </button>
                    </div> : <></>
                    
                }
                <div className={'chat_button_container'}>
                    <button className={'add_button'} 
                        type={'button'}
                        onClick={()=>{
                            if(!chatting){
                                if(!_.isEmpty(selectChatRoom)){
                                    //다른 채팅으로 변경 시 기존 ws는 close 하고 넘어가도록
                                    ws.current.close();
                                }
                                setChatValue('')
                                setValue('chatValue','')
                                setSelectChatRoom(null)
                                init_chat()
                                createChatRoom()
                            }
                    }}>
                        <img src={'/assets/images/GAI/plus_icons_2.svg'} className="pluses"/> <span className="inText">새 채팅 추가</span>
                    </button>
                    <button className={'close_button'} onClick={()=>{setMenuOpen(false)}}>
                        <img src={'/assets/images/GAI/prev_icons.svg'}/>
                    </button>
                </div>
                <div className={'chat_list_container'}>
                    {
                        widthStyle != "mini_width" && 
                        <h3> 채팅목록</h3>
                    }
                 
                    <div className={'chat_list'}>
                    {
                        !_.isEmpty(chatRoom) && 
                        chatRoom.map((item,idx) => {
                            return(
                                <ChatList widthStyle={widthStyle}  idx={idx} menuClose={setMenuOpen} ws={ws} chatting={chatting} editTitle={editTitle} deleteChatRoom={deleteChatRoom} setSelectChatRoom={setSelectChatRoom} item={item} selectChatRoom={selectChatRoom}/>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            <div className={`right_container ${menuOpen ? '' : 'wide'}`}>


                {/* {
                    (minimode && menuOpen) &&
                    <div className={'cover'}>
    
                    </div>
                } */}
                <div className={'chatting_container'} ref={chatEndRef}>
                  
                    {
                        !_.isEmpty(chatList) && chatList.map((item,chatIndex)=>{
                            return(
                                <div>
                                    <div className={'user_chat'}>
                                        <div className={'chat_data'}>  
                                        <div className={'chat'}>{_.get(item,'question')}</div>
                                            <small>{moment(_.get(item,'question_time')).format('YYYY-MM-DD HH:mm:ss')}</small>
                                        </div>  
                                    </div>
                                    {
                                        !_.isEmpty(_.get(item,'answer'))&&
                                            <div className={'ai_chat'}>
                                                <img src={'/assets/images/GAI/GAI_Icon_2.svg'}/>
                                                <div className={'chat_data'}>
                                                    <div className={'chat'}>{_.get(item,'answer')}</div>
                                                    <small>{moment(_.get(item,'answer_time')).format('YYYY-MM-DD HH:mm:ss')}</small>
                                                </div>                      
                                            </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        (_.isEmpty(chatList) && _.isEmpty(chatValue)) &&
                        <div className={'empty_chat'}>채팅을 입력하여 새로운 채팅을 시작해보세요.</div>
                    }
                    {!_.isEmpty(chatValue) && 
                        <div className={'user_chat'}>
                            <div className={'chat_data'}>  
                                <div className={'chat'}>{chatValue}</div>
                            </div>  
                        </div>
                    }
                    {!_.isEmpty(chatResult) ? 
                        <div className={'ai_chat'}>
                            <img src={'/assets/images/GAI/GAI_Icon_2.svg'}/>
                            <div className={'chat_data'}>
                                <div className={'chat'}>{chatResult}</div>
                            </div>                      
                        </div>
                        : 
                        chatting ? 
                        <div className={'ai_chat'}>
                            <img src={'/assets/images/GAI/GAI_Icon_2.svg'}/>
                            <div className={'chat_data'}>
                                <div className={'chat'}><div className={'dot-flashing'}></div></div>
                            </div>                      
                        </div>
                        : <></>
                    }
                </div>
                <div className={'chatting_input_container'}>
                    {
                        isUnlimit.state ?
                        <>
                            <textarea 
                                id={'chatValue'}
                                onChange={(e)=>{setChatValue(e.target.value)}}
                                ref={register()}
                                name={'chatValue'}
                                disabled={chatting}
                                readOnly={chatting}
                                style={{ height: `${!_.isEmpty(watch('chatValue')) && watch('chatValue').split('\n').length * 35 || 35}px`, color: chatting ? '#999' : '' }}
                                onKeyDown={ (event) => handleKeyDownChatting(event)}
                            />
                            <div className={'chat_text_length'}>{(!chatting && !_.isEmpty(chatValue)) ? chatValue.length : 0}/1000</div>
                            <button onClick={async ()=>{
                                const { state, data : limitReason } = await onCheckLimitPrice({user,aiEndpointData})
                                if(!state)return null;
                                if(_.isEmpty(selectChatRoom)){
                                    createChatRoom()
                                }
                                else{
                                    if(!_.isEmpty(watch('chatValue')) && !chatting){
                                        if(_.isEmpty(chatList)){
                                            //채팅 리스트가 없을 경우에 제목을 수정해줘야함
                                            editTitle({selectChatRoom,changeTitle:chatValue.slice(0,10)})
                                        }
                                        let chat_id = MD5(`${_.get(user,'username')}_${new Date()}_${chatValue}`).toString();
                                        //유저 params
                                        let user_params = {
                                            chat_room_id:_.get(selectChatRoom,'chat_room_id'),
                                            chat_id:chat_id,
                                            user : _.get(user,'username'),
                                            chat:watch('chatValue'),
                                            chat_model:_.get(aiEndpointData,'model'),
                                            chat_type:'human',
                                            site : address,
                                            uuid : _.get(aiEndpointData,'ai_api_uuid'),
                                            createdAt:moment().format('YYYY-MM-DD HH:mm:ss'),
                                            updatedAt:moment().format('YYYY-MM-DD HH:mm:ss'),
                                        }
                                        //ai 답변 params
                                        let ai_params = {
                                            chat_room_id:_.get(selectChatRoom,'chat_room_id'),
                                            chat_id:chat_id,
                                            user : _.get(user,'username'),
                                            chat:'',
                                            chat_model:_.get(aiEndpointData,'model'),
                                            chat_type:'robot',
                                            site : address,
                                            uuid : _.get(aiEndpointData,'ai_api_uuid'),
                                            createdAt:moment().format('YYYY-MM-DD HH:mm:ss'),
                                            updatedAt:moment().format('YYYY-MM-DD HH:mm:ss'),
                                        }
                                        await ws.current.send(JSON.stringify({message:chatValue,room_id:roomId,type:'chat',user_params,ai_params}))
                                        setValue('chatValue','')
                                    }
                                }
                            }}
                            disabled={(_.isEmpty(watch('chatValue')) || chatting)}
                            >{(chatting) && 
                                <img src={`/assets/images/GAI/enter_sendMessage.svg`}/>
                            ||
                                <img src={`/assets/images/GAI/enter.svg`}/>
                            }</button>
                        </>
                        :
                        <> 
                        <textarea 
                            id={'chatValue'}
                            ref={register()}
                            name={'chatValue'}
                            style={{ height: `${!_.isEmpty(watch('chatValue')) && watch('chatValue').split('\n').length * 35 || 35}px`, color: chatting ? '#999' : '' }}
                        />
                        <div className={'chat_text_length'}>{(!chatting && !_.isEmpty(chatValue)) ? chatValue.length : 0}/1000</div>
                        <button onClick={()=>{
                            swal({
                                title : '사용량 초과', text : `[${isUnlimit.reason}] 초과로 인해 답변을 받을 수 없습니다.`, timer : 3000, buttons : false, icon : 'error'
                            })
                        }}
                        >
                            <img src={`/assets/images/GAI/enter.svg`}/>
                        </button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
};


export default ChatForm;