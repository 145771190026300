import {all} from 'redux-saga/effects';

import auth from './auth';
import content from './content';
import users from './users';

/**
 * rootSaga
 */
export default function* root() {
    yield all([
        auth(),
        content(),
        users(),
    ]);
}
