require('babel-polyfill');

const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

module.exports = Object.assign(
  {
    AuthToken: 'a305980743325990024eb18f139661e7', //API 접근 인증 토큰, obenef!234 -> md5
    host: process.env.HOST || 'gj.udec.kr',
    port: process.env.PORT || 5000,
    publicUrl: 'https://www.gjudec.com',
    apiUrl: 'https://portal.gjudec.com/api', //strapi URL
    lmsUrl: 'https://lms.gjudec.com',
    strpassword: 'gjudec-obenef-!@#',
    logApiUrl: 'https://lms.gjudec.com',
    cookieDomain: '.gjudec.com',
    //apiUrl: 'http://34.84.15.163:1347',  //strapi URL
    app: {
      title: 'GJUDEC - 광주전남권역 대학원격교육지원센터',
      description: '광주전남권역 대학원격교육지원센터 입니다.',
      head: {
        defaultTitle: 'GJUDEC - 광주전남권역 대학원격교육지원센터',
        // titleTemplate: '전남콘텐츠코리아랩: %s',
        titleTemplate: '%s',
        meta: [
          {
            name: 'description',
            content: 'GJUDEC - 광주전남권역 대학원격교육지원센터',
          },
          { charset: 'utf-8' },
          {
            property: 'og:site_name',
            content: 'GJUDEC - 광주전남권역 대학원격교육지원센터',
          },
          {
            property: 'og:image',
            content: 'https://gj.udec.kr/assets/images/logo.png',
          },
          { property: 'og:locale', content: 'ko_KR' },
          {
            property: 'og:title',
            content: 'GJUDEC - 광주전남권역 대학원격교육지원센터',
          },
          {
            property: 'og:description',
            content: 'GJUDEC - 광주전남권역 대학원격교육지원센터',
          },
          { property: 'og:card', content: 'summary' },
          { property: 'og:site', content: '@erikras' },
          { property: 'og:creator', content: '@erikras' },
          { property: 'og:image:width', content: '200' },
          { property: 'og:image:height', content: '200' },
        ],
      },
    },
    daum_api_key: '13a17c0d59f85d1660e9cf7236720ea4',
    //첫번째 path 와 마지막 path로 구별
    routing: {},
  },
  environment
);
