/** 컨텐츠 관련 Action */
import {createAction} from 'redux-actions'
import {createReduxActions} from 'utils/reduxUtils'

//컨텐츠 목록
export const {
    LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE,
    listRequest, listSuccess, listFailure
} = createReduxActions('content', 'LIST');

//컨텐츠 상세
export const {
    VIEW_REQUEST, VIEW_SUCCESS, VIEW_FAILURE,
    viewRequest, viewSuccess, viewFailure
} = createReduxActions('content', 'VIEW');

//컨텐츠 생성/수정
export const {
    WRITE_REQUEST, WRITE_SUCCESS, WRITE_FAILURE,
    writeRequest, writeSuccess, writeFailure
} = createReduxActions('content', 'WRITE');

//contents, view 초기화
export const INIT = 'content/INIT';
export const init = createAction(INIT);
