/*
 takeEvery: 여러번 실행했을때, 여러번 계속해서 실행되도 되는것.
 takeLatest: 여러번 실행했을때, 한번만 실행되야 되는것. ex)로그인 버튼
*/
import { put, spawn, takeEvery, takeLatest, call } from 'redux-saga/effects';
import * as actions from 'store/actions/auth';
import axios from 'axios';
import qs from 'qs';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import config from 'config';
import * as api from 'lib/auth';
import { browserName, CustomView, mobileModel, mobileVender, deviceType, osName } from 'react-device-detect'

import Cookie from 'universal-cookie';
/*
인증 요청
payload = {
    "identifier": "test@obenef.com",
    "password": "Chao0116"
}
 */

function* getIpAddress() {
  try {
    let localIp = null;
    if (location.protocol === 'http:') {
      
      const { data: localIp } = yield call(axios.get, '/custom-api/ip')
      //const { data: localIp = null } = yield call(axios.get, 'http://ip-api.com/json')
      //const { query = '' } = localIp;
      return localIp;
    } else {
      //const { data = {} } = yield call(axios.get, 'https://api64.ipify.org/?format=json')
      // const { data = {} } = yield call(axios.get, 'http://ip-api.com')
      // const { ip = '' } = data;
      // return ip;
      const { data: localIp } = yield call(axios.get, '/custom-api/ip')
      return localIp
      // const { data = {} } = yield call(axios.get, 'https://ipinfo.io')
      // const { ip = '' } = data;
      // return ip;
    }
    return localIp;
  } catch (e) {
    return null
  }
}

function* authorizationSaga({ payload }) {
  // console.log('authorizationSaga=================')
  // console.log('payload=======================', payload)

  const myIp = yield call(getIpAddress)
  const cookie = new Cookie();
  const cookie_authToken = cookie.get('jwt');
  yield put(showLoading())
  const authToken = cookie.get('jwt');
  // console.log(authToken, 'authToken');
  const log_data = {
    event_type: 'auth',
    click_refer_url: '',
    current_url: '',
    current_component_name: '',
    timestamp: moment().valueOf(),
    ip: _.get(payload, 'ip'),
    os: _.get(payload, 'os'),
    device_type: _.get(payload, 'device'),
    browser: _.get(payload, 'browser'),
    action: 'login_'
  }

  try {
    if (authToken) {
      //토큰이 있는 경우
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      const { data, status } = yield api.getMy({ token: authToken });
      yield put(actions.loadSuccess({ ...data }));
    } else {

      let userData = {};

      //없는경우 (로그인 페이지에서 인증요청)
      const { data: session_Data } = yield axios.post('/custom-api/sso/getMyTest', { ...payload, ip: myIp, browserName, deviceType, osName })
      // console.log(session_Data)
      if (!_.isEmpty(session_Data)) {
        cookie.set('jwt', _.get(session_Data, 'jwt'), { path: '/', domain: _.get(config, 'cookieDomain') })
        if (!_.isEmpty(_.get(session_Data, 'user'))) {
          userData = _.get(session_Data, 'user')
        }
      }
      else {
        const { data, status } = yield api.getAuth({ ...payload, ip: myIp, browserName, deviceType, osName });

        const { jwt = '', user = {} } = data;

        // console.log('login user data=======================', data)
        // console.log(user)
        if (_.isEmpty(user)) {
          throw Error()
        }
        // if(!_.isEmpty(user)) {
        //   if(_.get(user, 'role.type') == 'admin') {
        //     throw Error (`inAdmin`)
        //   }
        // }
        // else{
        //   throw Error()
        // }
        userData = user
      }
      const Logdata = yield call(axios.post, `/custom-api/user_logs_contextApi/userPathLog/set`, {
        user: userData,
        log_data,
        event_type: 'auth',
        action: 'login_success',
        ip: myIp,
        os: osName,
        device: deviceType,
        browser: browserName
      })
      yield put(actions.loadSuccess({ ...userData }));
    }
  } catch (e) {
    if (payload) {
      //파라미터로 받은경우 메세지 alert(로그인페이지)
      swal({
        title: `인증 실패`,
        text: '유저 인증에 실패하였습니다.',
        icon: 'warning',
        timer: 2000,
        buttons: false,
      });
    }
    //console.log(e)
    //console.log('에러뜸')
    //localStorage.removeItem('jwt');
    _.set(log_data, 'message', '로그인 시도가 확인됐습니다. [로그인 실패]')
    _.set(log_data, 'user_name', _.get(payload, 'identifier'))
    _.set(log_data, 'event_type', 'login_fail')
    _.set(log_data, 'site_name', 'PORTAL')

    const Logdata = yield call(axios.post, `/custom-api/user_logs_contextApi/userPathLog/set`, {
      log_data,
      user_name: _.get(payload, 'identifier'),
      event_type: 'auth',
      action: 'login_fail'
    })
    yield put(actions.loadFailure(e));
  } finally {
    yield put(hideLoading());
  }
}

/** 로그아웃 */
function* logoutSaga({ payload }) {
  const user = _.get(payload, 'user');
  const log_data = {
    event_type: 'auth',
    click_refer_url: '',
    current_url: '',
    current_component_name: '',
    timestamp: moment().valueOf(),
    ip: _.get(payload, 'ip'),
    os: _.get(payload, 'os'),
    device: _.get(payload, 'device'),
    browser: _.get(payload, 'browser'),
    action: 'logout_'
  }

  try {
    yield put(showLoading());
    const myIp = yield call(getIpAddress)

    const cookie = new Cookie();
    cookie.remove('session_id', { path: '/', domain: _.get(config, 'cookieDomain') })
    cookie.remove('session_id', { path: '/' })
    cookie.remove('jwt', { path: '/', domain: _.get(config, 'cookieDomain') })
    cookie.remove('jwt', { path: '/' })
    cookie.remove('jwt.sig', { path: '/', domain: _.get(config, 'cookieDomain') })
    cookie.remove('jwt.sig', { path: '/' })
    cookie.remove('user_name', { path: '/', domain: _.get(config, 'cookieDomain') })
    cookie.remove('user_name', { path: '/' })
    cookie.remove('expiredTime.sig', { path: '/', domain: _.get(config, 'cookieDomain') })
    cookie.remove('expiredTime.sig', { path: '/' })
    cookie.remove('expiredTime', { path: '/', domain: _.get(config, 'cookieDomain') })
    cookie.remove('expiredTime', { path: '/' })
    //localStorage.removeItem('jwt');

    if (_.isEmpty(localStorage.jwt)) {
      let nowIp = '';

      _.set(log_data, 'message', '로그아웃 시도가 확인됐습니다. [로그아웃 성공]')
      _.set(log_data, 'user_name', _.get(payload, 'user.username'))
      _.set(log_data, 'event_type', 'auth')
      _.set(log_data, 'action', 'logout_success')
      _.set(log_data, 'site_name', 'PORTAL')

      _.set(log_data, 'os', osName)
      _.set(log_data, 'device', deviceType)
      _.set(log_data, 'browser', browserName)

      if (myIp == null) {
        _.set(log_data, 'ip', _.get(payload, 'ip'))
        nowIp = _.get(payload, 'ip')
      } else {
        _.set(log_data, 'ip', myIp)
        nowIp = myIp
      }


      const Logdata = yield call(axios.post, `/custom-api/user_logs_contextApi/userPathLog/set`, {
        user,
        log_data,
        user_name: _.get(payload, 'user.username'),
        event_type: 'auth',
        action: 'logout_success',
        ip: nowIp,
        os: osName,
        device: deviceType,
        browser: browserName
      })
    }

  } catch (e) {
    _.set(log_data, 'message', '로그아웃 시도가 확인됐습니다. [로그아웃 실패]')
    _.set(log_data, 'user_name', _.get(payload, 'user.username'))
    _.set(log_data, 'event_type', 'auth')
    _.set(log_data, 'action', 'logout_fail')
    _.set(log_data, 'site_name', 'LMS')
    const Logdata = yield call(axios.post, `/custom-api/user_logs_contextApi/userPathLog/set`, {
      user,
      log_data,
      user_name: _.get(payload, 'user.username'),
      event_type: 'auth',
      action: 'logout_fail'
    })
    console.log(e, `logoutSaga Err`);
  } finally {
    yield put(hideLoading());
  }
}

function* watchAuth() {
  yield takeLatest(actions.AUTHORIZATION, authorizationSaga); //토큰 및 유저정보 요청
  yield takeLatest(actions.LOGOUT, logoutSaga); //로그아웃
}

export default function* root() {
  yield spawn(watchAuth);
}
