import React from 'react';
import Quill from 'components/Editor/Quill';

const SlideItem = ({ data, num }) => {
    console.log(data)
  return (
      
      {/* 
      <div className={`mainSlider__item`}>
          <div className="wrap">
            <div className="left banner">
                <Quill initialValue={_.get(data,'content')} isView={true}/>
                //<p>{data.content}</p>
              <h2>{data.title}</h2>
            </div>
            <div className="right">
              <img src={`https://portal.gjudec.com/api${data.banner_image.url}`} alt=""/>
            </div>
          </div>
      </div>
      */}
  );
};

export default SlideItem;
