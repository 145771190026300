import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch, generatePath, Link } from 'react-router-dom';
import { apiUrl } from 'config';
import moment from 'moment/dist/moment';
import axios from 'axios';

const ProgramItem = ({ data, path }) => {
    const [parmas, setParmas] = useState({});
    const [defaultImage, setDefaultImage] = useState({});
    // console.log(data)
    // console.log(_.get(data, 'thumbnailimages.0.url'))

    const {
        id = '',
        title = '',
        allowednumber = null,
        apply_startdate = new Date(),
        apply_allowednumber = 0,
        apply_maxnumber = 0,
        apply_enddate = new Date(),
        learning_enddate = new Date(),
        learning_startdate = new Date(),
        maxnumber = null,
        thumbnailimages = {},
        study_program_applies = [],
        program_address = "",
        props = "",
        dev_org = "",
        organizationName = "",
        instructor = []
    } = data;

    const today = moment().format(`YYYY-MM-DD`);
    const d_day = moment(apply_enddate).diff(today, 'days');
    const applied = applied_num + applied_over;
    const apply_started = moment(apply_startdate).format(`YYYY-MM-DD`);
    const apply_ended = moment(apply_enddate).format(`YYYY-MM-DD`);
    const learning_ended = moment(learning_enddate).format(`YYYY-MM-DD`);
    const learning_started = moment(learning_startdate).format(`YYYY-MM-DD`);
    const applied_num = study_program_applies.filter(item => item.apply_status == "5ff6b769bf55b944295d63e8").length  // 수강 신청 완료 상태 개수
    const applied_over = study_program_applies.filter(item => item.apply_status == "5ff6b774bf55b944295d63e9").length // 수강 신청 완료(정원 초과) 개수
    const [devOrg, setDevOrg] = useState({});
    const [courseType, setCourseType] = useState({});

    // console.log(data);
    useEffect(async () => {
        if (!_.isEmpty(data)) {
            const { data: course_type_data } = await axios.post('/custom-api/studyprogram/study/taxonomy_data', { tid: _.get(data, 'course_type') })
            const { data: dev_org_data } = await axios.post('/custom-api/studyprogram/study/taxonomy_data', { tid: _.get(data, 'dev_org') })
            setCourseType(course_type_data)
            setDevOrg(dev_org_data)
            if (_.get(dev_org_data, 'name') == 'K-MOOC') {
                setDefaultImage('kmooc')
            }
            else if (_.get(dev_org_data, 'name') == 'KOCW') {
                setDefaultImage('kocw')
            }
            else if (_.get(dev_org_data, 'name') == '늘배움') {
                setDefaultImage('nelbaum')
            }
            //console.log(taxonomy_data)
        }
    }, [data])



    // console.log(data, "====================data")

    const InnerData = ({ data = {} }) => {
        return (
            <>
                {/*{(d_day > 0 && d_day < 7) && <span className="d_day">D-{d_day}</span>}*/}
                <div className="thumb">
                    {/*<img
                        src={!_.isEmpty(_.get(data, 'thumbnail')) ? _.get(data, 'thumbnail') : `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}`}
                        alt=""/>*/}
                    {
                        _.get(courseType, 'name') != '외부강좌' ?
                            <img
                                src={!_.isEmpty(_.get(data, 'thumbnailimages.0.url')) ? `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}` : '/assets/images/thumb_none.png'}
                                alt="" />
                            :
                            <img
                                onError={(e) => {
                                    e.target.src = `/assets/images/ext_course/${defaultImage}/default.png`;
                                }}
                                src={!_.isEmpty(_.get(data, 'thumbnail_imgae_url')) ? `${_.get(data, 'thumbnail_imgae_url')}` : `/assets/images/ext_course/${defaultImage}/default.png`}
                                alt="" />

                    }

                    {
                        _.get(courseType, 'name') == '외부강좌' ?
                            <span className='div_one_courseType'>
                                외부
                            </span>
                            :
                            <></>
                    }
                    {/* <div className='div_one_p'>
                        {
                            (today < apply_ended && '신청') ||
                            (today > apply_started && '마감') ||
                            '신청'
                        }
                    </div> */}
                </div>
                <div className="info">
                    <div className='info_div_one'>
                        <h2 className="card_title">{title}</h2>
                    </div>
                    <div className='date_wrap'>
                        {
                            (!_.isEmpty(dev_org) && ((
                                !_.isEmpty(props)) ?
                                <>
                                    <div className="blocks">
                                        <div className="left_info">
                                            교육기관
                                        </div>
                                        <div className="right_info">
                                            {_.get(devOrg, 'name') ? _.get(devOrg, 'name') : dev_org}
                                        </div>
                                    </div>
                                    <div className="blocks">
                                        <p className="left_info">
                                            교수자
                                        </p>
                                        <p className="right_info">
                                            {props}
                                        </p>
                                    </div>
                                </>

                                : (!_.isEmpty(dev_org)) ?
                                    <div className="blocks">
                                        <p className="date">
                                            {_.get(devOrg, 'name') ? _.get(devOrg, 'name') : dev_org}
                                        </p>
                                        <p className="right_info">
                                            <br />
                                        </p>
                                    </div>
                                    : (!_.isEmpty(props)) &&
                                    <div className="blocks">
                                        <p className="date">
                                            {props}
                                        </p>
                                        <p className="right_info">
                                        </p>
                                    </div>
                            )
                            )
                        }


                    </div>
                </div>



            </>
        )
    }
    return (
        <div className="programList__item">
            {/*<Link to={`/program/studyprogram/${id}`}>*/}
            {/*<a href={data.url}>*/}
            <Link to={`/content/${_.get(data, '_id')}`}>
                <InnerData data={data} />
            </Link>
            {/*{!_.isEmpty(_.get(data, 'url')) ? <a href={_.get(data, 'url')} target={'_blank'}>
                <InnerData data={data}/>
            </a> : <Link to={`/program/studyprogram/${id}`}>
                <InnerData data={data}/>
            </Link>}*/}
            {/*<a href={data.url.replace('http://34.84.15.163:6061','https://gjudec-lms.obenef.com')}>*/}

        </div>
    );
};

export default ProgramItem;