import { put, spawn, takeEvery, takeLatest, call, fork } from 'redux-saga/effects'
import * as actions from 'store/actions/content';
import * as api from 'lib/content';
import axios from 'axios';
import qs from 'qs';
import { showLoading, hideLoading } from 'react-redux-loading-bar'

/* 컨텐츠 목록 */
function* contentListSaga({ payload }) {
    try {
        const { type, ...params } = payload;
        if (!type) throw '불러올 컨텐츠 타입을 입력해주세요.'
        const { data: list = [] } = yield call(api.gets, { type, ...params });
        const { data: totalCount = 0 } = yield call(api.count, { type, ...params });
        const _list = _.compact(list);

        yield put(actions.listSuccess({ list: _list, totalCount }));
    } catch (e) {
        console.log(e, 'contentListSaga error')
        const errorMsg = _.isString(e) ? e.trim() : '컨텐츠를 가져오는데 실패하였습니다.';
        yield put(actions.listFailure(errorMsg));
        swal({ title: errorMsg, text: ' ', icon: "warning", timer: 2000, buttons: false });
    } finally {
        yield put(hideLoading())
    }
}



/* 컨텐츠 상세 */
function* contentViewSaga({ payload }) {
    try {
        const { type, id } = payload;
        if (!type) throw '불러올 컨텐츠 타입을 입력해주세요.'
        if (!id) return yield put(actions.viewSuccess({}));
        const { data } = yield api.get({ type, id });
        //console.log(_.get(data,'view_count','값 잘못찾아왔음'))
        //console.log(data.view_count)
        _.set(data, 'view_count', ((_.get(data, 'view_count', 0) * 1) + 1))
        yield api.update({ type: type, id: id, ...data });
        //yield put(actions.writeSuccess(data));
        //console.log(data)
        yield put(actions.viewSuccess(data));
    } catch (e) {
        console.log(e, 'contentViewSaga error')
        const errorMsg = _.isString(e) ? e.trim() : '컨텐츠를 불러오는데 실패하였습니다.';
        yield put(actions.viewFailure(errorMsg));
        swal({ title: errorMsg, text: ' ', icon: "warning", timer: 2000, buttons: false });
    } finally {
        yield put(hideLoading())
    }
}

/* 컨텐츠 수정/생성 */
function* conentWriteSaga({ payload }) {
    try {
        // const {board, wr_id, mode, formData} = payload;
        const { type, id, params = {}, formData } = payload;
        const { files, ...data } = formData;
        if (!type) throw '불러올 컨텐츠 정보를 입력해주세요.'

        // const {data: writeData, status: writeStatus} = yield axios.post(`/api/board/${board}${getParams ? `?${getParams}` : ''}`, data)
        // if (writeStatus === 204) throw '게시물 생성에 실패하였습니다.';
        //
        // if (!_.isEmpty(files)) {
        //     const fd = new FormData();
        //     const headers = {'Content-Type': 'multipart/form-data'};
        //     for (const file of files) {
        //         fd.append('file', file);
        //     }
        //     const {data: fileData, status: fileStatus} = yield axios.post(`/api/board/${board}/upload${getParams ? `?${getParams}` : ''}`, fd, {headers})
        //     if (fileStatus === 204) throw '파일 등록에 실패하였습니다.';
        //     if (_.get(fileData, 'errorMsg', null)) throw fileData.errorMsg;
        // }

        yield put(actions.writeSuccess(data));
        yield put(actions.listRequest({ type, ...params }));
    } catch (e) {
        console.log(e, 'conentWriteSaga error')
        const errorMsg = _.isString(e) ? e.trim() : '컨텐츠 등록 또는 수정에 실패하였습니다.';
        yield put(actions.writeFailure(errorMsg));
        swal({ title: errorMsg, text: ' ', icon: "warning", timer: 2000, buttons: false });
    } finally {
        yield put(hideLoading())
    }
}


function* watchContent() {
    yield takeLatest(actions.LIST_REQUEST, contentListSaga)    //컨텐츠 목록
    yield takeLatest(actions.VIEW_REQUEST, contentViewSaga)    //컨텐츠 상세
    yield takeLatest(actions.WRITE_REQUEST, conentWriteSaga)    //게시물 생성/수정/답변
}

export default function* root() {
    yield spawn(watchContent);
}
