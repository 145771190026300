import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BsArrowRight, RiArrowDropRightLine } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions/auth';
import route from 'route';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import MobileUserMenuItem from '../MobileUserMenu/MobileUserMenuItem';


const { mypage, depth6 } = route;
const MobileUserMenu = ({ openUserMenu, onChangeOpenUserMenu }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()

  const { user, isLoggedIn } = useSelector(
    ({ auth: { user, isLoggedIn } }) => ({
      user,
      isLoggedIn,
    })
  );

  const [cookie, setCookie, removeCookie] = useCookies([]);
  const handleLogout = () => {
    removeCookie('user');
    removeCookie('jwt');
    dispatch(actions.logout({ user }));
    onChangeOpenUserMenu(false);
    history.push('/');
  };

  useEffect(() => {
    return () => {
      onChangeOpenUserMenu(false);
    };
  }, [location.pathname]);

  return (
    <>
      <div
        className={`dim ${openUserMenu ? 'active' : ''}`}
        onClick={() => onChangeOpenUserMenu(false)}
      />
      <nav className={`mobileUserMenu ${openUserMenu ? 'active' : ''}`}>
        <header className="mobileUserMenu__header">
          <div className="wrap">
            사용자 메뉴
            <button onClick={() => onChangeOpenUserMenu(false)}>
              <BsArrowRight />
            </button>
          </div>
        </header>
        <div className="mobileUserMenu__links">
          {(!user || !isLoggedIn) && (
            <>
              <Link
                to="/member/login"
                onClick={() => onChangeOpenUserMenu(false)}
              >
                로그인
                <RiArrowDropRightLine />
              </Link>
              <Link
                to="/member/register/information"
                onClick={() => onChangeOpenUserMenu(false)}
              >
                회원가입
                <RiArrowDropRightLine />
              </Link>
            </>
          )}

          {user && isLoggedIn && (
            <>
              <div className={`mobileUserMenu__usercontainer`}>
                <span className={`username`}>
                  {`${user.name} 님` || `email: ${user.email}` || '-'}
                </span>
                <button className={`logout`} onClick={handleLogout}>
                  로그아웃
                </button>
              </div>
              <div className={`mobileNav__link-item mobileNav__links`}>
                <MobileUserMenuItem data={depth6} />
              </div>
              {/*<div className="mobileNav__links">
                    <MobileNavItem data={mypage}/>
                    </div>*/}
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default MobileUserMenu;
