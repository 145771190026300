import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch, generatePath, Link } from 'react-router-dom';
import { apiUrl } from 'config';
import moment from 'moment/dist/moment';
import axios from 'axios';

const ProgramItem = ({ data, path }) => {
    const [parmas, setParmas] = useState({});
    const [defaultImage, setDefaultImage] = useState({});
    // console.log(data)
    // console.log(_.get(data, 'thumbnailimages.0.url'))

    const {
        props = "",
        dev_org = "",
        organizationName = "",
        instructor = []
    } = data;

    const today = moment().format(`YYYY-MM-DD`);
    const [devOrg, setDevOrg] = useState({});
    const [courseType, setCourseType] = useState({});

    // console.log(data);
    useEffect(async () => {
        if (!_.isEmpty(data)) {

            const { data: course_type_data } = await axios.post('/custom-api/studyprogram/study/taxonomy_data', { tid: _.get(data, 'course.course_type') })
            const { data: dev_org_data } = await axios.post('/custom-api/studyprogram/study/taxonomy_data', { tid: _.get(data, 'organizationCode') })
            setCourseType(course_type_data)
            setDevOrg(dev_org_data)

            if (_.get(dev_org_data, 'name') == 'K-MOOC') {
                setDefaultImage('kmooc')
            }
            else if (_.get(dev_org_data, 'name') == 'KOCW') {
                setDefaultImage('kocw')
            }
            else if (_.get(dev_org_data, 'name') == '늘배움') {
                setDefaultImage('nelbaum')
            }
        }
    }, [data])


    const InnerData = ({ data = {} }) => {
        return (
            <>
                <div className="thumb">
                    {
                        _.get(data, "externalCourse") != true ?
                            <img
                                src={!_.isEmpty(_.get(data, 'thumbnailimages.0.url')) ? `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}` : '/assets/images/thumb_none.png'}
                                alt="" />
                            :
                            <img
                                onError={(e) => {
                                    e.target.src = `/assets/images/ext_course/${defaultImage}/default.png`;
                                }}
                                src={!_.isEmpty(_.get(data, 'thumbnail_imgae_url')) ? `${_.get(data, 'thumbnail_imgae_url')}` : ((_.get(devOrg,"name") == "광주전남권역 대학원격교육지원센터" ) ? '/assets/images/thumb_none_gj.jpg' : `/assets/images/ext_course/${defaultImage}/default.png`)}
                                alt="" />
                    }
                    {
                        _.get(data, "externalCourse") == true ?
                            <span className='div_one_courseType'>
                                외부
                            </span>
                            :
                            <></>
                    }
                </div>
                <div className="info">
                    <div className='info_div_one'>
                        <h2 className="card_title">{_.get(data, "title")}</h2>
                    </div>
                    <div className='date_wrap'>
                        {
                            (!_.isEmpty(organizationName) && ((
                                !_.isEmpty(instructor)) ?
                                <>
                                    <div className="blocks">
                                        <div className="left_info">
                                            교육기관
                                        </div>
                                        <div className="right_info">
                                            {organizationName}
                                        </div>
                                    </div>
                                    <div className="blocks">
                                        <p className="left_info">
                                            교수자
                                        </p>
                                        <p className="right_info">
                                            {
                                                (instructor.length > 1) ? (
                                                    instructor.map((item, idx) => {
                                                        if (idx == 0) {
                                                            return (<>
                                                                {item} 외 {instructor.length - 1}명
                                                            </>)
                                                        }

                                                    })
                                                ) : (
                                                    instructor.map((item, idx) => {
                                                        if (idx == 0) {
                                                            return (<>
                                                                {item}
                                                            </>)
                                                        }

                                                    })
                                                )
                                            }
                                        </p>
                                    </div>
                                </>
                                : (!_.isEmpty(organizationName)) ?
                                    // <div className="blocks">
                                    //     <p className="date">
                                    //         {organizationName}
                                    //     </p>
                                    //     <p className="right_info">
                                    //         <br />
                                    //     </p>
                                    // </div>
                                    <>
                                         <div className="blocks">
                                            <div className="left_info">
                                                교육기관
                                            </div>
                                            <div className="right_info">
                                                {organizationName}
                                            </div>
                                        </div>
                                        <div className="blocks">
                                            <div className="left_info">
                                                개발기관
                                            </div>
                                            <div className="right_info">
                                                {_.get(data, "developmentName")}
                                            </div>
                                        </div>
                                    </>
                                   
                                    : (!_.isEmpty(instructor)) &&
                                    <div className="blocks">
                                        <p className="date">
                                            {
                                                (instructor.length > 1) ? (
                                                    instructor.map((item, idx) => {
                                                        if (idx == 0) {
                                                            return (<>
                                                                {item}외 {instructor.length - 1}명
                                                            </>)
                                                        }

                                                    })
                                                ) : (
                                                    instructor.map((item, idx) => {
                                                        if (idx == 0) {
                                                            return (<>
                                                                {item}
                                                            </>)
                                                        }

                                                    })
                                                )
                                            }
                                        </p>
                                        <p className="right_info"></p>
                                    </div>
                            )
                            )
                        }


                    </div>
                </div>



            </>
        )
    }
    return (
        <div className="programList__item">
            <Link to={`/content/${_.get(data, '_id')}`}>
                <InnerData data={data} />
            </Link>
        </div>
    );
};

export default ProgramItem;