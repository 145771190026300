import {createAction} from 'redux-actions'

/* Redux Actino Creator */
export const createReduxActions = (module, actionType) => {
    const actionTypes = {
        [`${actionType}_REQUEST`]: `${module}/${actionType}_REQUEST`,
        [`${actionType}_SUCCESS`]: `${module}/${actionType}_SUCCESS`,
        [`${actionType}_FAILURE`]: `${module}/${actionType}_FAILURE`
    };
    const actionName = _.camelCase(actionType);
    const actions = {
        [`${actionName}Request`]: createAction(actionTypes[`${actionType}_REQUEST`], payload => payload),
        [`${actionName}Success`]: createAction(actionTypes[`${actionType}_SUCCESS`], payload => payload),
        [`${actionName}Failure`]: createAction(actionTypes[`${actionType}_FAILURE`], payload => payload),
    }
    return {...actionTypes, ...actions};
}
