import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Item from './Item';

const LeftBoard = ({ type, onChangeType, list = [] }) => {

    return (
        <div className="col">
            <div className="top">
                <div className="top_board_link_button">
                    <Link to={type === 'notice' ? '/community/notice' : '/community/news'}>
                        <img src="/assets/images/plus_button_white.png" alt="메인아이콘" />
                    </Link>
                </div>
                <div className="top_button_line">
                </div>
                <button className={type === 'notice' ? 'active' : ''} onClick={() => onChangeType('notice')}>공지사항</button>
                <button className={type === 'news' ? 'active' : ''} onClick={() => onChangeType('news')}>센터 소식</button>
                <button className={type === 'reference' ? 'active' : ''} onClick={() => onChangeType('reference')}>자료실</button>
            </div>
            <div className="contbox boardbox">
                <div className="list">
                    {
                        !_.isEmpty(list)
                            ? list.map(item => <Item key={item.id} type={type} data={item} />
                            )
                            : <p>등록된 글이 없습니다.</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default LeftBoard;
