import React from 'react';
import Slider from 'react-slick';

import EquipmentItem from './EquipmentItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const EquipmentSlider = ({list = []}) => {
    return (
        <div className="equipmentInfo__slide">
            <Slider {...settings}>
                {
                    list.length &&
                    list.map((items, idx) => (<EquipmentItem key={idx} items={items}/>)) ||
                    (<div className="pb-5 pt-5 text- text-center">장비가 존재하지 않습니다.</div>)
                }
            </Slider>
        </div>
    );
};

export default EquipmentSlider;
