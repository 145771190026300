import { handleActions } from 'redux-actions';
import {
    //컨텐츠 목록
    LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE,

    //컨텐츠 상세
    VIEW_REQUEST, VIEW_SUCCESS, VIEW_FAILURE,

    //컨텐츠 생성/수정
    WRITE_REQUEST, WRITE_SUCCESS, WRITE_FAILURE,

    INIT,   //컨텐츠 redux 초기화
} from 'store/actions/content'
import update from "react-addons-update";

const initialState = {
    list: [],    //컨텐츠 목록
    totalCount: 0,  //컨텐츠 목록 갯수
    view: {},    //컨텐츠 상세
    loading: false,
    errorMsg: null,
}

export default handleActions({
    /** 컨텐츠 목록 */
    [LIST_REQUEST]: (state, action) => ({ ...state, loading: true }),
    [LIST_SUCCESS]: (state, action) => {
        const { list = [], totalCount = 0 } = action.payload;
        return update(state, {
            list: { $set: list },
            totalCount: { $set: totalCount },
            errorMsg: { $set: null },
            loading: { $set: false }
        })
    },
    [LIST_FAILURE]: (state, action) => update(state, {
        list: { $set: [] },
        totalCount: { $set: 0 },
        errorMsg: { $set: action.payload },
        loading: { $set: false }
    }),

    /** 컨텐츠 상세 */
    [VIEW_REQUEST]: (state, action) => ({ ...state, loading: true }),
    [VIEW_SUCCESS]: (state, action) => update(state, {
        view: { $set: { ...action.payload } },
        errorMsg: { $set: null },
        loading: { $set: false }
    }),
    [VIEW_FAILURE]: (state, action) => update(state, {
        view: { $set: {} },
        errorMsg: { $set: action.payload },
        loading: { $set: false }
    }),

    /** 컨텐츠 생성/수정 */
    [WRITE_REQUEST]: (state, action) => ({ ...state, loading: true }),
    [WRITE_SUCCESS]: (state, action) => ({ ...state, loading: false }),
    [WRITE_FAILURE]: (state, action) => ({ ...state, loading: false }),


    /** 컨텐츠 redux 초기화 */
    [INIT]: (state, action) => ({ ...state, ...initialState }),

}, initialState)
