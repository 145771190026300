import React, { useEffect, useState, useCallback, useRef } from 'react';
import RentalHeader from 'components/RentalHeader';
import { apiUrl } from 'config';
import * as api from 'lib/content';

//graphql 관련
import { useLazyQuery } from "@apollo/react-hooks";
import * as query from "queries/resource";
import { useSelector } from 'react-redux';
import axios from 'axios';

const Index = ({ hiddenHeader = false, onSubmit, setFloorId, floorId }) => {
    const { user, isLoggedIn } = useSelector(({ auth }) => ({ ...auth }));
    const [space, setSpace] = useState({})  //선택 된 공간
    const [cateList, setCateList] = useState([]);   //카테고리 목록
    const [floors, setFloors] = useState([]);   //층수
    const [tab, setTab] = useState(''); //선택 된 탭



    //1. 카테고리 목록
    // const [getCategory] = useLazyQuery(query.SPACE_RESOURCE_CATEGORY_QUERY, {
    //     fetchPolicy: 'no-cache', variables: {sort: "weight:ASC"},
    //     onCompleted: ({spaceResourceCategories = []}) => {
    //         setCateList([/*{id: '', title: '전체'},*/ ...spaceResourceCategories]);
    //         setTab(_.get(spaceResourceCategories, '0._id'));
    //     }
    // });

    //2. 층 목록
    // const [getFloors] = useLazyQuery(query.SPACE_RESOURCE_FLOOR_QUERY, {
    //     fetchPolicy: 'no-cache', variables: {sort: "weight:ASC"},
    //     onCompleted: ({spaceResourceFloors = []}) => {
    //         setFloors([...spaceResourceFloors])
    //     }
    // });

    //Get 공간 데이터
    // const [getData] = useLazyQuery(query.SPACE_RESOURCE_QUERY, {
    //     fetchPolicy: 'no-cache',
    //     onCompleted: ({spaceResource = {}}) => {
    //         setSpace({...spaceResource})
    //     }
    // });

    const getCategory = async () => {
        const { data } = await axios.post('/custom-api/resource/space/category')
        //console.log(data)
        setCateList(data)
        setTab(_.get(data, '0._id'));
    }


    const getFloors = async () => {
        const { data } = await axios.post('/custom-api/resource/space/floors')
        setFloors(data)
    }

    useEffect(async () => {
        const { data } = await axios.post('/custom-api/resource/space/space')
        //console.log(data.filter((item)=>{return (item._id == '607ffa659ad8cb29300f4445')})[0])
        setSpace(data.filter((item) => { return (item._id == '607ffa659ad8cb29300f4445') })[0])
    }, [])

    const getData = async ({ variables = '' }) => {
        const { data } = await axios.post('/custom-api/resource/space/space')
        setSpace(data.filter((item) => { return (item._id == _.get(variables, 'id')) })[0])
    }

    //카테고리 및 층
    useEffect(() => {
        getCategory()
        getFloors()
    }, []);

    //데이터 initialize
    useEffect(() => {
        if (!_.isEmpty(cateList) && !_.isEmpty(floors) && _.isEmpty(space)) {
            const lastData = _.chain(floors)
                .map('space_resources').flatten().last().value() || null;
            lastData && getData({ variables: { id: '607ffa659ad8cb29300f4445' } });
        }
    }, [floors, cateList, space])

    useEffect(() => {
        onSubmit && space && onSubmit(space)
    }, [space])

    return (
        <div className="section info">

            <div className="part_select">
                <div className={'part columnFlexStudio'}>
                    <p className={'part_title'}>
                        스튜디오
                    </p>
                    <div>
                        <RentalHeader
                            title={'공간 안내'}
                            tabList={cateList}
                            tab={tab}
                            onChangeTag={setTab}
                            hiddenHeader={hiddenHeader} />

                        <div className="col">
                            <ul className="spaceInfo__floor">

                                {!_.isEmpty(cateList) && cateList.map((cate, idx) => {
                                    //console.log(cate)
                                    const { _id = '', space_resources = [] } = cate;
                                    if (_.isEmpty(space_resources)) return;
                                    if (_.get(cate, '_id') != tab) return;
                                    //console.log(space_resources)
                                    return (
                                        <li className="item" key={_id}>
                                            {!_.isEmpty(space_resources) && (
                                                <div className="roomlist">
                                                    {space_resources.map((v, index) => {
                                                        const {
                                                            title = '', _id = '',
                                                            space_resource_floors,
                                                            space_resource_category: { _id: cateId = '' } = {}
                                                        } = v;
                                                        //console.log(tab,'tab')
                                                        //console.log(cateId,'cateId')
                                                        if (tab !== '' && tab !== cateId) return;
                                                        return (<button
                                                            className={`room-btn ${_id === space._id && 'active' || ''}`}
                                                            onClick={() => {
                                                                getData({ variables: { id: _id } })
                                                                setFloorId(_.get(space_resource_floors, '0._id'))
                                                            }}
                                                            key={_id}>{title}</button>)
                                                    })}
                                                </div>
                                            ) ||
                                                <li className="item">
                                                    <div className="roomlist">
                                                        <p>가능한 공간이 없습니다.</p>
                                                    </div>
                                                </li>}
                                        </li>
                                    )
                                }) || (
                                        <li className="item">
                                            <div className="roomlist">
                                                <p>가능한 공간이 없습니다.</p>
                                            </div>
                                        </li>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/*<div className={'part_footer'}>
                    <p className={'footer_title'}>대관시간</p>
                    <p className={'footer_info'}>월~금(토요일 및 공휴일 제외)</p>
                    {
                        !_.isEmpty(space) &&
                        (noti_time && (<p className={'footer_info'}>{noti_time}</p>))
                    }
                </div>*/}
            </div>

            <div className="part_info">
                <div className="spaceInfo__content part columnFlexStudio">
                    <p className={'part_title'}>
                        시설
                    </p>
                    <div>
                        {
                            !_.isEmpty(_.get(space, 'space_resource_floors')) && _.get(space, 'space_resource_floors').map((floors, index) => {
                                //console.log(space)
                                const {
                                    title: spaceTitle = '',
                                    documentfiles = [], thumbnailimages = {},
                                    noti_time = null, noti_date = null, noti_run = null,
                                    id = '607fff679ad8cb29300f4454', space_resource_floors = []
                                } = space;
                                return (
                                    <button onClick={() => setFloorId(_.get(floors, '_id'))} className={floorId == _.get(floors, '_id') ? 'floorButton active' : 'floorButton'} key={_.get(floors, '_id')}>
                                        <div className="col col--download">

                                            <div className="spaceInfo__operation">
                                                {
                                                    _.isEmpty(thumbnailimages) ?
                                                        <img src={'/assets/images/noimage.png'} />
                                                        :
                                                        <img src={`${apiUrl}${_.get(thumbnailimages, 'url')}`} />
                                                }
                                                {
                                                    !_.isEmpty(space) &&
                                                    (<>
                                                        {spaceTitle && (
                                                            <div className="space_info"><p className={'space_info_title'}>시설명칭<br></br></p>
                                                                <p>{spaceTitle}</p></div>)}
                                                        {_.get(space, 'phone', '') && (
                                                            <div className="space_info"><p className={'space_info_title'}>전화번호<br></br></p>
                                                                <p>{_.get(space, 'phone', '')}</p></div>)}
                                                        {noti_run && (
                                                            <div className="space_info"><p className={'space_info_title'}>시설위치<br></br></p>
                                                                <p>{noti_run}</p></div>)}
                                                    </>) ||
                                                    (<p className="empty">선택 된 공간이 없습니다.</p>)
                                                }
                                            </div>
                                        </div>
                                    </button>
                                )
                            })
                        }
                    </div>

                </div>
                {/*<div className={'part_footer'}>
                    <p className={'footer_title'}>이용수칙</p>
                    <p className={'footer_info'}>시간준수</p>
                    <p className={'footer_info'}>목적 외 사용 금지</p>
                    <p className={'footer_info'}>외부인 출입금지</p>
                </div>*/}
            </div>
            <div className={'part_footer'}>
                <ul className={'part_footer_list'}>
                    <li className={'footer_title'}><strong>대관시간</strong> : 월~금(토요일 및 공휴일 제외) {_.get(space, 'noti_time')}</li>
                    <li><strong>이용수칙</strong> : 시간준수, 목적 외 사용 금지, 외부인 출입금지</li>
                </ul>
            </div>
        </div>
    )
        ;
};

export default Index;
