import React, { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import katex from "katex";
import "katex/dist/katex.min.css";
import ImageUploader from "quill-image-uploader";
const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];
import HtmlEditButton from "quill-html-edit-button";

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/htmlEditButton", HtmlEditButton);

window.katex = katex;

const modules = {
    toolbar: [
        ["bold", "italic", "underline", "strike", "blockquote"],
        // [{ color: [] }, "code-block"],
        [{ color: [] }],

        [{ list: "ordered" }, { list: "bullet" }, { 'align': [] }],
        [{ script: "sub" }, { script: "super" }],
        //[{ header: [1, 2, 3, false] }],
        [{ 'size': fontSizeArr }],
        ["link", "image", "formula", "video"],
    ],
    // htmlEditButton: {},
    clipboard: {
        matchVisual: true,
    },
    imageUploader: {
        upload: (file) => {
            return new Promise((resolve, reject) => {
                if (file.size > 5120000) {
                    reject("Upload failed");
                    alert("5MB 이하만 가능합니다.");
                }
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener("load", () => {
                    resolve(reader.result);
                })
            });
        },
    },
};

const QuillEditor = ({
    isView = false,
    initialValue = "",
    name = "description",
    onChange = () => { },
}) => {

    if (isView) {
        return (
            <ReactQuill
                value={initialValue}
                readOnly={isView}
                //defaultValue={initialValue}
                name={name}
                modules={{ toolbar: [] }}
                preserveWhitespace
            />
        );
    }

    return (
        <ReactQuill
            defaultValue={initialValue}
            name={name}
            modules={modules}
            preserveWhitespace

            onChange={(...props) => {
                !isView && onChange(...props);
            }}
        />
    );
};

export default React.memo(QuillEditor);
