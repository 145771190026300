/** Previous State */
import React, {useState, useEffect, useRef} from 'react';
import Cookie from "universal-cookie";
import axios from "axios";
import moment from 'moment/dist/moment';
import config from 'config';
import * as authActions from '../store/actions/auth';

const loginCookie = async ({intervalId,swalOption,dispatch}) => {
        const cookies = new Cookie();

              let expiredTime = ''
              if(_.isEmpty(cookies.get('jwt'))){
                console.log('stop')
                return clearInterval(intervalId)
              }
              if(!_.isEmpty(cookies.get('session_id'))){
                const {data} = await axios.post(`/custom-api/sso/getSession`, {session_id:cookies.get('session_id')});
                const {expire='',token} = data
                expiredTime = expire.toString();
                //setExpiredTime(expire)
              }

              //console.log(moment(new Date()) > moment(expiredTime))
            //   if(!_.isEmpty(cookies.get('jwt'))){
            //       const jwt = cookies.get('jwt');
            //       const {data} = await axios.post('/custom-api/sso/browserCheck',{jwt})
            //        if(_.get(data,'value') == 'logout'){
            //            swal({title:'로그인이 감지되었습니다.', text:'자동으로 로그아웃 됩니다', icon:'warning',...swalOption}).then(
            //                (val)=>{
            //                    if(val){
            //                       cookies.remove('jwt',{path:'/',domain:_.get(config,'cookieDomain')})
            //                       cookies.remove('jwt.sig', { path: '/',domain:_.get(config,'cookieDomain') })
            //                       cookies.remove('expiredTime', { path: '/',domain:_.get(config,'cookieDomain') })
            //                       return dispatch(authActions.logout())
            //                    }
            //                }
            //            )
            //        }
            //   }
              if(moment(new Date()) > moment(expiredTime) || (_.isEmpty(cookies.get('jwt')) && !_.isEmpty(user))){
                  // cookies.remove('jwt', { path: '/' })
                  // cookies.remove('jwt.sig', { path: '/' })
                  // cookies.remove('expiredTime', { path: '/' })
                  swal({title:'로그인 세션시간이 만료 되었습니다.', text:'자동으로 로그아웃 됩니다', icon:'warning',...swalOption}).then(
                      async (val)=>{
                          const cookies = new Cookie();
                          if(val){
                              await axios.post('/custom-api/sso/logout',{jwt:cookies.get('jwt'),message:'logout_error'})
                              cookies.remove('jwt', { path: '/',domain:_.get(config,'cookieDomain') })
                              cookies.remove('jwt.sig', { path: '/',domain:_.get(config,'cookieDomain') })
                              cookies.remove('expiredTime', { path: '/',domain:_.get(config,'cookieDomain') })
                              return dispatch(authActions.logout())
                          }
                      }
                  )
              }
    
};

export {loginCookie};