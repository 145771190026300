import i18n from "i18next"
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './translation.en.json'
import translationKo from './translation.ko.json'

const resources = {
    en: {translation: translationEn},
    ko: {translation: translationKo}
};

i18n
    .use(initReactI18next)  // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ko",
        fallbackLng: 'ko',
        // ns: ['translation'],
        // defaultNS: "translation",
        debug: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
