import React, { useEffect, useState } from 'react';
import { Link, Switch } from 'react-router-dom';
import axios from 'axios';

import Route from 'route';

const IntroFooter = () => {
    const [todayVisit, setTodayVisit] = useState('')
    const [totalVisit, setTotalVisit] = useState('')

    useEffect(async () => {
        const { data: today } = await axios.get('/custom-api/gapi/todayvisitcount')
        const { data: total } = await axios.get('/custom-api/gapi/totalvisitcount')
        const todaycount = _.get(today, 'rows[0].metricValues[0].value')
        //console.log(today,'today')
        //console.log(total,'total')
        //const todaycount = 2000000000000
        const totalcount = _.get(total, 'rows[0].metricValues[0].value')
        setTodayVisit(todaycount ? todaycount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0)
        setTotalVisit(totalcount ? totalcount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0)
    }, [])
    useEffect(() => {
        //todayVisit && console.log(todayVisit,'todayVisit')
        //totalVisit && console.log(totalVisit,'totalVisit')
    }, [todayVisit, totalVisit])
    return (
        <footer className={'intro__footer'}>

            <div className="wrap">
                <div className='top_btn'>
                    <a href={'#'}>
                        <img src='/assets/images/top_btn.png' />
                    </a>
                </div>
                <Link to={'/'} className="logo_footer">
                    <img src="/assets/images/footer_logo_0920.svg" alt="" />
                </Link>
                <div className="footer_center">
                    <ul>
                        <li>
                            <Link to={"/privacypolicy"}>개인정보처리방침</Link>
                            {/* <a href='#'>개인정보처리방침</a> */}
                        </li>
                        <span>|</span>
                        <li>
                            <Link to={"/copyright"}>저작권보호</Link>
                        </li>
                        <span>|</span>
                        <li>
                            <Link to={"/emailwarning"}>이메일무단수집거부</Link>
                        </li>
                    </ul>
                    <p>
                        <span>
                            {/*61186*/}
                            {/* <em className='bottom_em'>광주캠퍼스(61186) 광주광역시 북구 용봉로 77(용봉동)/ 전화: 062-530-5075, 2595</em> */}
                            Copyright 2021 CHONNAM NATIONAL UNIVERSITY. <i>All rights reserved.</i>
                        </span>
                        <span className='bottom_span'>
                            {/* Copyright 2021 CHONNAM NATIONAL UNIVERSITY. All rights reserved. */}
                            광주캠퍼스(61186) 광주광역시 북구 용봉로 77(용봉동) <u>/</u> <i>전화: 062-530-5075</i>
                        </span>
                    </p>
                </div>
                <div className="visit_container">
                    <span className="visit_title">숫자로 보는 광주전남권역 대학원격교육지원센터</span>
                    <div className="visit_left">
                        <div className="visit_img">
                            <img src="/assets/images/visit_today.svg" alt="오늘 방문자 수" />
                        </div>
                        <div>
                            <span className="visit_num">{todayVisit}명</span>
                            <span>오늘 방문자 수</span>
                        </div>
                    </div>
                    <div className="visit_right">
                        <div className="visit_img">
                            <img src="/assets/images/visit_total.svg" alt="누적 방문자 수" />
                        </div>
                        <div>
                            <span className="visit_num">{totalVisit}명</span>
                            <span>누적 방문자 수</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default IntroFooter;
