import React from 'react';

const IntroPagination = ({
  paging,
  handleClickPaging,
    totalCountPaging
}) => {
  return (
    <div className={'intro__pagination'}>
      <button className={'arrow arrow--prev'} onClick={() => handleClickPaging('prev')}>이전</button>
      <div className={'numbers'}>
          {[...Array(totalCountPaging)].map((n, index) => {
              return (
                  <button key={index} className={`number ${paging === index ? 'active' : ''}`} onClick={() => handleClickPaging(index)}><span>{index+1}</span></button>
              )
          })}
      </div>
      <button className={'arrow arrow--next'} onClick={() => handleClickPaging('next')}>다음</button>
    </div>
  );
};

export default IntroPagination;
