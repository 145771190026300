import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/dist/moment';

//게시글 안내용 가져오기
import Quill from "../Editor/Quill";
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from 'utils/useWindowSize';




const Item = ({ data, type = '' }) => {
    // console.log('data==========================', data)
    //게시글 안내용 가져오기
    const { title = '', view_count = 0, body = null, attached_file = [], createdAt = new Date, author = {} } = data;
    const body2 = useSelector((state) => state.content.view.body)
    const list_data = moment(data.createdAt).format(`YYYY-MM-DD`);
    const type_name = type;
    const size = useWindowSize()
    // console.log(_.get(size, "width"))
    let new_name;
    let board_type;

    //카테고리 명 추출
    function type_name_sub() {
        if (type_name == "notice") {
            new_name = "공지사항";
        }
        else if (type_name == "news") {
            new_name = "센터 소식"
        }
        else if (type_name == "reference") {
            new_name = "자료실"
            if (_.get(data, 'board.board_types') == 'photo') {
                board_type = '포토 갤러리'
            }
            else if (_.get(data, 'board.board_types') == 'videos') {
                board_type = '동영상'
            }
            else if (_.get(data, 'board.board_types') == 'ebook') {
                board_type = 'eBook'
            }
        }
    }
    type_name_sub();

    //글자수자르기
    const new_title = data.title;
    const new_title_substr = new_title.substr(0, 40) + "...";

    if (type != 'reference') {
        return (
            <Link to={`community/${type}/${data.id}`} className="link" style={{}}>
                {_.get(size, "width") > 768 ? <p className="main_list_cate">{new_name}</p> : null}
                <h1 className="main_list_title">{data.title}</h1>
                <Quill isView={true} initialValue={body} />
                {/* <p className="main_list_date" >{list_data}</p> */}
            </Link>
        );
    }
    else {
        if (_.get(data, 'board.board_types') != 'ebook') {
            return (
                <Link to={`community/${type}/${_.get(data, 'board.board_types')}/${data.id}`} className="link" style={{}}>
                    {_.get(size, "width") > 768 ? <p className="main_list_cate">{new_name} - {board_type}</p> : null}
                    <h1 className="main_list_title">{data.title}</h1>
                    {_.get(data, 'board.board_types') === 'photo' ?
                        <div className='quill' style={{ height: "100%", maxHeight: "59px", overflow: "hidden" }}>
                            <img src={_.get(data, 'thumbnail_img_data.0.url') ? `https://portal.gjudec.com/api/${_.get(data, 'thumbnail_img_data.0.url')}` : `/assets/images/thumb_none.png`} alt="" style={{ width: "80%", display: "flex", margin: "0 auto" }} />
                        </div>
                        :
                        <Quill isView={true} initialValue={`https://portal.gjudec.com/api/${_.get(data, 'thumbnail_img_data.0.url')}`} />}
                    {/* <p className="main_list_date" >{list_data}</p> */}
                </Link>
            );
        }
        else {
            return (
                <a href={`${_.get(data, 'link_url')}`} className="link" style={{}}>
                    {_.get(size, "width") > 768 ? <p className="main_list_cate">{new_name} - {board_type}</p> : null}
                    <h1 className="main_list_title">{data.title}</h1>
                    {_.get(data, 'board.board_types') === 'photo' ? <img src={_.get(data, 'thumbnail_img_data.0.url') ? `https://portal.gjudec.com/api/${_.get(data, 'thumbnail_img_data.0.url')}` : `/assets/images/thumb_none.png`} alt="" /> : <Quill isView={true} initialValue={body} />}
                    {/* <p className="main_list_date" >{list_data}</p> */}
                </a>
            );
        }
    }
};

export default Item;
