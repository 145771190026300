import React, {useEffect, useState, useCallback} from 'react';
import {useStateIfMounted} from "use-state-if-mounted";
import moment from 'moment/dist/moment'
import {useSelector} from 'react-redux';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import TimePicker from "rc-time-picker";
import ReactDOM from 'react-dom';

import axios from 'axios';

//jQWidgets style
import "jqwidgets-scripts/jqwidgets/styles/jqx.base.css";
// import "jqwidgets-scripts/jqwidgets/styles/jqx.material.css";
import "styles/components/jqx.material.scss";

//Scheduler 관련
import JqxScheduler, {jqx} from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxscheduler';
import * as opt from 'components/ScheduleCalendar/option'   //기본 옵션

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as query from "queries/resource";
import { split } from 'lodash';


window.moment = moment
const ScheduleCalendar = ({space = {}, data = [], getData,floorId='', setFromDate, setToDate}) => {
    const [scheduler, setScheduler] = useStateIfMounted(null);  //스케쥴러 인스턴스
    const [editDialog, setEditDialog] = useStateIfMounted(null);    //일정 생성 다이얼로그
    const [option, setOption] = useState({});   //스케쥴러 옵션
    const [dataAdapter, setDataAdapter] = useState({});
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const [resource, setResource] = useState({equipmentResources: [], spaceResources: []}); //사용 가능한 리소스
    const {equipmentResources, spaceResources} = resource;
    const [selected, setSelected] = useState({});   //open 된 editorDialog 스케쥴 데이터
    const [view, setView] = useState('monthView');   //View 모드

    const [dispatchTime, setDispatchTime] = React.useState(moment());

    const handleValueChange = value => {
        setDispatchTime(value);
    };


    //사용 가능 리소스 목록
    const [getAbailables] = useLazyQuery(query.AVAILABLE_DATES, {
        fetchPolicy: 'no-cache',
        onCompleted: ({availableDates = []}) => {
            const spaceResources = _.chain(availableDates).map('space_resources').flatten().uniqBy('id').value()
            const equipmentResources = _.chain(availableDates).map('equipment_resources').flatten().uniqBy('id').value()
            setResource({...resource, spaceResources, equipmentResources})
        }
    })

    //일정 데이터 포메팅
    const dataFormater = (data = []) => {
        //console.log(data)
        if (!data.length) return [];
        return _.chain(data).map(v => {
            const {
                space_resource = {}, schedule_author = {}, schedule_equipments = [],
                schedule_from = null, schedule_to = null, documentfiles = [], confirmed,
                ...rest
            } = v;
            //if (_.isEmpty(schedule_author)) return null;
            if (_.isEmpty(space_resource)) return null;
            let backColor = '';
            // 승인인 경우
            if(!_.isEmpty(schedule_author)){
                if (confirmed){
                    backColor= '#25A0DA'
                }
            }
            else{
                // 현재 시간 이전일 때
                if(moment(schedule_from) < moment()){
                    backColor = '#dddddd'
                }
                else{
                    backColor= '#489659'
                }
            }

            // 현재 시간 이후이며, 예약된 일정이 있고, 미승인인 경우 (
            if (schedule_author && !confirmed) {
                backColor = '#FF7515'
            }

            return ({
                ...rest,
                schedule_from: new Date(schedule_from),
                schedule_to: new Date(schedule_to),
                schedule_equipments: _.map(schedule_equipments, 'equipment_resource'),
                space_resource_category: _.get(space_resource, 'space_resource_category.title', ''),
                resource: _.get(space_resource, 'title', ''),
                space_resource,
                documentfiles, confirmed,
                draggable:false,
                resizable:false,
                readOnly: !_.isEmpty(user) ? user.role.name === 'Admin' ? false : moment(schedule_from) < moment() ? true : false : false,
                style: backColor,
                author: schedule_author,
            })
        }).compact().value();
    }

    //페이지 이동시, dilog 닫음.
    useEffect(() => {
        return () => {
            editDialog && editDialog.owner.closeDialog()
        }
    }, [editDialog])


    /**
     * 스케쥴러 에러 function Override
     * Scheulde Api rangeIntersection Override
     * @type {function(*=, *=, *=, *=): boolean}
     */
    jqx.scheduler.utilities.rangeIntersection = useCallback((i, h, f, e) => {
        if (!i || !h || !f || !e) return false;
        const g = i && i.dateData;
        const d = f && f.dateData;
        const c = h && h.dateData;
        const b = e && e.dateData;
        if (d >= g && d < c) return true;
        if (d < g && b > g) return true;
        if (g == d || c == b) return true;
        if (g < d) {
            if (c > d && c < b) return true;
            if (c > b) return true;
        } else {
            if (b > g && b < c) return true;
            if (b > c) return true;
        }
        return false
    }, []);

    //source 설정
    useEffect(() => {
        const source = {...opt.source};
        const _data = dataFormater(data);
        _.set(source, 'localData', _data)
        const _dataAdapter = new jqx.dataAdapter(source);
        setDataAdapter(_dataAdapter)
    }, [data]);

    //option 설정
    useEffect(() => {
        const _option = {...opt.schedule};
        _.set(_option, 'resources.source', dataAdapter);
        _.set(_option, 'source', dataAdapter);
        setOption({..._option})
    }, [dataAdapter])

    useEffect(() => {
        window.debugScheulder = scheduler;
    }, [scheduler]);

    //일정 등록 및 수정 윈도우 커스텀
    const editDialogCreate = useCallback((dialog, fields, editAppointment) => {
        
        // let timing = '';
        // if(moment(data.appointment.from.toDate()).format('a') == '오전'){
        //     timing = 'AM'
        // }
        // else if (moment(data.appointment.from.toDate()).format('a') == '오후'){
        //     timing = 'PM'
        // }

        fields.repeatContainer.hide();  //반복 관련 숨김
        fields.timeZoneContainer.hide();  //타임존 설정 숨김
        fields.statusContainer.hide();  //상태 설정 숨김
        fields.resourceContainer.hide();  //리소스 컨테이너 숨김
        fields.colorContainer.hide();  //색상 컨테이너 숨김
        fields.fromContainer.hide(); // 시작 숨김
        fields.toContainer.hide(); // 종료 숨김
        fields.subject.jqxInput({disabled: true});  //유저명
        //fields.location.jqxInput({disabled: true}); //공간 카테고리
        fields.locationContainer.hide(); //공간 카테고리

        //공간 선택 관련
        const sContaier = $(`<div></div>`);
        // const sLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">공간 선택</div>`).appendTo(sContaier);
        const sContent = $(`<div class="jqx-scheduler-edit-dialog-field jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(sContaier);
        const sInput = $(`<input type="hidden" name="jqx-space_resource_id">`).appendTo(sContaier);
        fields.spaceResourceId = sInput;
        fields.spaceResource = sContent;
        // fields.spaceResourceLabel = sLabel;
        fields.spaceResourceContainer = sContaier;
        fields.subjectContainer.after(fields.spaceResourceContainer);

        //사용자
        const tContaier = $(`<div></div>`);
        const tLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">사용자</div>`).appendTo(tContaier);
        const tContent = $(`<div id="jqx-userName-content" class="jqx-input-material jqx-scheduler-edit-dialog-field jqx-input-group-material jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(tContaier);
        const tInput = $(`<input type="text" placeholder="사용자명 또는 기관명 작성" name="jqx-userName">`).appendTo(tContent);
        fields.userNameId = tInput;
        fields.userName = tContent;
        fields.userNameResourceLabel = tLabel;
        fields.userNameContainer = tContaier;
        fields.spaceResourceContainer.after(fields.userNameContainer);

        // //사용시간
        // const timeContainer = $(`<div></div>`);
        // const timeLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">사용시간</div>`).appendTo(timeContainer);
        // const timeContent = $(`<div id="jqx-userName-content" class="jqx-input-material jqx-scheduler-edit-dialog-field jqx-input-group-material jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(timeContainer);
        // const timeInput = $(`<select name="jqx-startTime"><option>1</option></select>시 <select name="jqx-startMiniute"><option>2</option></select>분 <label>~</label> <select name="jqx-startTime"><option>1</option></select>시 <select name="jqx-startMiniute"><option>2</option></select>분`).appendTo(timeContent);
        // fields.timeId = timeInput;
        // fields.time = timeContent;
        // fields.timeResourceLabel = timeLabel;
        // fields.timeContainer = timeContainer;
        // fields.userNameContainer.after(fields.timeContainer);

        //사용시간
        // const timeContainer = $(`<div></div>`);
        // const timeLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">사용시간</div>`).appendTo(timeContainer);
        // const timeContent = $(`<div id="jqx-userName-content" class="jqx-input-material jqx-scheduler-edit-dialog-field jqx-input-group-material jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(timeContainer);
        // const timeInput = $(`<input type="time" name="jqx-startTime" id="jqx-startTime" class="studio_timer"/> <label>~</label> <input id="jqx-startTime" type="time" name="jqx-endTime" class="studio_timer"/>`).appendTo(timeContent);
        // fields.timeId = timeInput;
        // fields.time = timeContent;
        // fields.timeResourceLabel = timeLabel;
        // fields.timeContainer = timeContainer;
        // fields.userNameContainer.after(fields.timeContainer);

        //TimePicker 시험적용
        //const timePicker_test = <div><TimePicker value={'10:00'} onChange={handleValueChange}/></div>
        const timePicker_test = 'hello'
        const timeContainer = $(`<div></div>`);
        const timeLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">사용시간</div>`).appendTo(timeContainer);
        const timeContent = $(`<div id="jqx-userName-content" class="jqx-input-material jqx-scheduler-edit-dialog-field jqx-input-group-material jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(timeContainer);
        let timeInput = $(`<div><select name="jqx-startTime"></select>시 <select name="jqx-startMiniute"><option value=00>00</option><option value=30>30</option></select>분 <label>~</label> <select name="jqx-endTime"></select>시 <select name="jqx-endMiniute"><option value=00>00</option><option value=30>30</option></select>분<div name="jqx-timer"> </div></div>`).appendTo(timeContent);

        fields.timeId = timeInput;
        fields.time = timeContent;
        fields.timeResourceLabel = timeLabel;
        fields.timeContainer = timeContainer;
        fields.userNameContainer.after(fields.timeContainer);



        //사용인원
        const nContaier = $(`<div></div>`);
        const nLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">사용인원</div>`).appendTo(nContaier);
        const nContent = $(`<div id="jqx-useNum-content" class="jqx-input-material jqx-scheduler-edit-dialog-field jqx-input-group-material jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(nContaier);
        const nInput = $(`<input type="number" name="jqx-useNum"/>명`).appendTo(nContent);
        fields.useNumId = nInput;
        fields.useNum = nContent;
        fields.useNumResourceLabel = nLabel;
        fields.useNumContainer = nContaier;
        fields.descriptionContainer.after(fields.useNumContainer);


        //장비 선택 관련
        // const eContaier = $(`<div></div>`);
        // const eLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">장비 예약</div>`).appendTo(eContaier);
        // const eContent = $(`<div class="jqx-scheduler-edit-dialog-field jqx-scheduler-edit-dialog-field-material overflow-auto" style="max-height: 130px"></div>`).appendTo(eContaier);
        // fields.eqOptions = eContent;
        // fields.eqOptionsLabel = eLabel;
        // fields.eqOptionsContainer = eContaier;
        // fields.colorContainer.after(fields.eqOptionsContainer);

        //파일 첨부 컨테이너
        const documentfilesContaier = $(`<div></div>`);
        const documentfilesLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material">파일 첨부</div>`).appendTo(documentfilesContaier);
        const documentfilesContent = $(`<div id="jqx-documentfiles-content" class="1 jqx-scheduler-edit-dialog-field jqx-scheduler-edit-dialog-field-material"><div class="file-list"></div></div>`).appendTo(documentfilesContaier);
        const documentfiles = $(`<input type="file" name="jqx-documentfiles" accept=".gif, .jpg, .png, .pdf, .PDF, .GIF, .JPG, .PNG, .hwp, .HWP, .doc, .DOC, .ppt, .PPT">`).appendTo(documentfilesContent);
        fields.documentfiles = documentfiles;
        fields.documentfilesLabel = documentfilesLabel;
        fields.documentfilesContaier = documentfilesContaier;
        fields.useNumContainer.after(fields.documentfilesContaier)

        //사용인원
        const checkContainer = $(`<div></div>`);
        // const checkLabel = $(`<div class="jqx-scheduler-edit-dialog-label jqx-scheduler-edit-dialog-label-material"></div>`).appendTo(nContaier);
        const checkContent = $(`<div id="jqx-check-content" class="jqx-input-material jqx-scheduler-edit-dialog-field jqx-input-group-material jqx-scheduler-edit-dialog-field-material"></div>`).appendTo(checkContainer);
        const checkInput = $(`<div><label><input type="checkbox" name="jqx-check" class="stuCheck"/> 스튜디오 <button type='button' style="border-bottom:1px solid #aaaaaa; height: 30px;" name="studio_button">이용약관</button>에 동의합니다.</label></div>`).appendTo(checkContent);
        const checkPopup = $(`<div name="studio_popup" style="position: fixed;inset: 0px;display: none;z-index: 999;pointer-events: auto; background:white; color:black; padding: 15px; margin: auto; max-width: 800px; max-height:730px; border: 1px solid black;">
        <div>
        <img style="vertical-align: middle; margin-right: 15px;" src="/assets/images/스튜디오 예약.svg"/>스튜디오 예약
        <button class="studio_popup_close" style="margin-right: 30px; float:right;" type='button' name="studio_close_button"></button>
        </div>
        <div style="text-align:center; margin-bottom: 95px; margin-top: 60px;">
        <img style="display:block; margin:auto; margin-bottom:35px;" src="/assets/images/경고등2.svg"/>
        <span style="display:block;font-size:22px; font-weight:500; line-height: 26px; margin-bottom: 25px;">스튜디오 이용약관</span>
        <div style="width:43px; border:1px solid black; margin: auto; margin-bottom: 25px;"></div>
        </div>
        <div style="white-space:pre-wrap; background:#f4f4f4;border-radius:20px; padding: 5px;">
        1. 사용신청서는 사용일시 7일 전까지 제출하여야 하며, 여러 번의 사용을 일괄 신청할 수 없습니다.
            &nbsp사용신청서 미제출로 인하여 발생할 수 있는 모든 불이익에 대한 책임은 신청자에게 있습니다.
        2. 사용신청서의 사용목적과 다르게 사용할 경우 사용허가를 취소할 수 있습니다.
        3. 시설물의 파손, 훼손, 분실 등 피해가 발생할 경우 사용자가 원상복구 또는 피해 상당액을 변상하여야 합니다.
        4. 사용자의 과실로 인한 모든 안전사고의 책임은 사용자에게 있습니다.
        </div>
        </div>`).appendTo(checkInput);
        fields.checkId = checkInput;
        fields.check = checkContent;
        //fields.checkResourceLabel = checkLabel;
        fields.checkContainer = checkContainer;
        fields.documentfilesContaier.after(fields.checkContainer);

        //날짜에 따른 사용 가능 공간 및 장비 설정
        fields.from.on('change', function () {
            fields.eqOptions.empty();
            fields.spaceResource.empty()
            getResourceByDate(fields.from, fields.to)
        })

        //날짜에 따른 사용 가능 공간 및 장비 설정
        fields.to.on('change', function () {
            fields.eqOptions.empty();
            fields.spaceResource.empty()
            getResourceByDate(fields.from, fields.to)
        })

        fields.from.jqxDateTimeInput({disabled: true});
        fields.to.jqxDateTimeInput({disabled: true});
        //fields.to.jqxInput({readOnly: true})
        //fields.from.jqxInput({readOnly: true})
    }, [scheduler, equipmentResources, spaceResources, user]);


    //사용가능 공간 및 장비 설정
    useEffect(() => {
        // 우클릭했을 때 표출되는 dialog
        if (editDialog) {
            const {fields, appointment} = editDialog.args

            //비회원 팝업 제한
            if (!user) {
                scheduler.closeDialog()
                return swal({
                    title: `로그인한 사용자만 이용이 가능합니다.`, text: ' ',
                    icon: "warning", timer: 2000, buttons: false
                });
            }

            //장비 예약 목록 설정
           /* if (equipmentResources.length) {
                fields.eqOptions.empty()
                equipmentResources.length && equipmentResources.map((v) => {
                    $(`<label for="${v.id}" class="d-block">
                <input value="${v.id}" id="${v.id}" type="checkbox" name="jqx-eq-schedule"> ${v.title} : ${v.summary}
                </label>`).appendTo(fields.eqOptions)
                });
            } else {
                fields.eqOptions.html(`대여 가능한 장비가 존재하지 않습니다.`)
            }*/

            //공간 목록 설정
            // if (spaceResources.length) {
            //     fields.spaceResourceId.val('')
            //     fields.spaceResource.empty()
            //     const sSelect = $(`<select class="jqx-scheduler-selected-appointment w-100" name="jqx-space_resource"></select>`).appendTo(fields.spaceResource);
            //     $(`<option> -- 선택 -- </option>`).appendTo(sSelect)
            //     spaceResources.map((v) => {
            //         $(`<option value="${v.id}">${v.title}</option>`).appendTo(sSelect)
            //     });

            //     if (!_.isEmpty(space)) {
            //         sSelect.val(space.id)
            //         fields.spaceResourceId.val(space.id)
            //         const locationValue = _.get(space, 'space_resource_category.title', '')
            //         fields.location.val(locationValue);

            //         const spaceResourceDocfiles = _.get(space, 'documentfiles', [])
            //         if (!spaceResourceDocfiles.length) {
            //             fields.documentfilesContaier.hide()
            //         } else {
            //             fields.documentfilesContaier.show()
            //         }
            //     }
            //     sSelect.attr("disabled", 'disabled');

            //     //공간 변경에 따른 카테고리
            //     // $(`[name="jqx-space_resource"]`).off().on('change', function () {
            //     //     const id = $(this).val();
            //     //     const spaceResource = _.find(spaceResources, {id}) || null
            //     //     if (spaceResource) {
            //     //         //카테고리 설정
            //     //         const locationValue = _.get(spaceResource, 'space_resource_category.title', '')
            //     //         fields.location.val(locationValue);
            //     //     }
            //     // })

            // } else {
            //     fields.spaceResourceId.val('')
            //     fields.spaceResource.html(`대여 가능한 공간이 존재하지 않습니다.`)
            // }

            //파일 삭제
            fields.documentfilesContaier.find(`.file-list .documentfile-delete`).off().on('click', function () {
                var _this = $(this);
                if (this.id && this.id !== undefined) {
                    api.del({type: `upload/files`, id: this.id}).then((res) => {
                        if (res.status) {
                            _this.parent().remove();
                            getData()
                        }
                    }).catch((err) => console.log(err, 'documentfile delete error'))
                }
            })

            //editDialog 데이터 설정
            if (appointment) {
                const {schedule_equipments = []} = selected;
                // editDailog 공간 데이터 설정
                if (appointment.space_resource) {
                    const spaceResourceId = appointment.space_resource.id;
                    $(`[name="jqx-space_resource"]`).val(spaceResourceId)
                    fields.spaceResourceId.val(spaceResourceId)
                    const spaceResource = _.find(spaceResources, {id: spaceResourceId}) || null;
                    if (spaceResource) {
                        //공간
                        appointmentPropertyChange(appointment, 'space_resource', {...spaceResource})

                        //카테고리
                        const spaceResourceCategory = _.get(spaceResource, 'space_resource_category.title', '')
                        appointmentPropertyChange(appointment, 'location', spaceResourceCategory)

                        const spaceResourceDocfiles = _.get(spaceResource, 'documentfiles', [])
                        if (!spaceResourceDocfiles.length) {
                            fields.documentfilesContaier.hide()
                        } else {
                            fields.documentfilesContaier.show()
                        }
                    }
                }

                // editDailog 장비 사용 설정
                if (schedule_equipments.length) {
                    const equipmentIds = _.map(schedule_equipments, 'equipment_resource')
                    equipmentIds.map(id => {
                        $(`#${id}[name="jqx-eq-schedule"]`).attr('checked', true)
                    });
                    appointmentPropertyChange(appointment, 'schedule_equipments', equipmentIds)
                }


                if(!_.isEmpty(appointment.author)){
                    //나의 예약만 수정 가능
                    if (
                        appointment.author && user &&
                        appointment.author.id !== user.id
                    ) {
                        if (user.role.name === 'Admin') {
                            editDialogFieldStatus(fields, false)    //수정 가능
                        } else {
                            if (user.role.name === 'staff') { // 로그인된 유저가 각 학교의 직원일 때
                                if (_.get(appointment, 'space_resource.title') == user.organization) { // 유저의 소속과 선택된 스튜디오의 이름이 동일하면
                                    editDialogFieldStatus(fields, false) //수정 가능
                                } else {
                                    editDialogFieldStatus(fields, true) //수정 불가
                                }
                            } else {
                                editDialogFieldStatus(fields, true) //수정 불가
                            }
                        }
                    } else {
                        editDialogFieldStatus(fields, false)    //수정 가능
                    }
                }else{
                    editDialogFieldStatus(fields, false)    //수정 가능
                }
            } else {
                scheduler.closeDialog();
                editDialogFieldStatus(fields, false)    //수정 가능
            }

            if (view === 'dayView' || view === 'weekView') {
                scheduler.scrollTop(500)
            }
        }
    }, [scheduler, spaceResources, equipmentResources, editDialog, selected, user, space]);

    //editDialog 수정 가능 여부
    const editDialogFieldStatus = (fields, disabled = false) => {
        if (!fields) return false;
        if (disabled) { //다이얼로그 필드 수정 불가
            fields.description.jqxInput({disabled: true});
            fields.to.jqxDateTimeInput({disabled: true});
            fields.from.jqxDateTimeInput({disabled: true});
            //console.log(fields,"fieldsfields")

            //1129 kai - 에러떄문에 하위 2항목 삭제
            // fields.spaceResource.find('select').attr("disabled", 'disabled');
            // fields.eqOptions.find('input[type="checkbox"]').attr("disabled", 'disabled');

            fields.documentfiles.hide()
            fields.buttons.hide()
            fields.buttons[0].children[1].style.display = 'none'
        } else {    //다이얼로그 필드 수정 가능
            fields.description.jqxInput({disabled: false});
            fields.documentfiles.show()
            fields.buttons.show()
        }
    }

    //다이얼로그 오픈
    const editDialogOpen = useCallback(async (dialog, fields, editAppointment) => {
        fields.repeatContainer.hide();  //반복 관련 숨김
        fields.allDayContainer.hide();  //종일 설정 숨김 - false
        
        setTimeout(() => {
            fields.allDay.val(false)
            fields.from.jqxDateTimeInput({formatString: "yyyy-MM-dd hh:mm tt"})
            fields.to.jqxDateTimeInput({formatString: "yyyy-MM-dd hh:mm tt"})
            getResourceByDate(fields.from, fields.to)
        })

        $(`[name="jqx-documentfiles"]`).val('')
        fields.documentfilesContaier.find(`.file-list`).empty()
        
        /** 수정시 */
        if (editAppointment) {
            const schedule = await getSchedule(editAppointment.id);
            setSelected({...schedule})

            if (!_.isEmpty(schedule)) {
                const {schedule_author = {}, schedule_equipments = [], use_num = 1, user_name='', able_check = false, start_time, end_time, schedule_from, schedule_to} = schedule;
                
                // 수정 시 기존 예약 데이터 출력
                let getStartTime;
                let getEndTime;
                
                $(`[name="studio_button"]`).click(
                    function(){
                        $(`[name="studio_popup"]`).css('display','block')
                    }
                )
                $(`[name="studio_close_button"]`).click(
                    function(){
                        $(`[name="studio_popup"]`).css('display','none')
                    }
                )
                
                if(moment(schedule_from).format('a') == '오전'){
                    $(`[name="jqx-startTime"]`).html('<option value=9>9</option><option value=10>10</option><option value=11>11</option>')
                    $(`[name="jqx-endTime"]`).html('<option value=10>10</option><option value=11>11</option><option value=12>12</option>')
                }
                else if (moment(schedule_from).format('a') == '오후'){
                    $(`[name="jqx-startTime"]`).html('<option value=13>13</option><option value=14>14</option><option value=15>15</option><option value=16>16</option><option value=17>17</option>')
                    $(`[name="jqx-endTime"]`).html('<option value=14>14</option><option value=15>15</option><option value=16>16</option><option value=17>17</option><option value=18>18</option>')
                }

                if (!_.isEmpty(start_time) && !_.isEmpty(end_time)){
                    getStartTime = start_time.split(':')[0]+':'+start_time.split(':')[1]
                    getEndTime = end_time.split(':')[0]+':'+end_time.split(':')[1]

                    $(`[name="jqx-startTime"]`).val(start_time.split(':')[0]);
                    $(`[name="jqx-endTime"]`).val(end_time.split(':')[0]);
                    $(`[name="jqx-startMiniute"]`).val(start_time.split(':')[1]);
                    $(`[name="jqx-endMiniute"]`).val(end_time.split(':')[1]);
                }
                
                $(`[name="jqx-endTime"]`).change(()=>{
                    const reStartTime = parseInt($(`[name="jqx-startTime"]`).val()) * 60 + parseInt($(`[name="jqx-startMiniute"]`).val()); // 시간을 분으로 치환
                    const reEndTime = parseInt($(`[name="jqx-endTime"]`).val()) * 60 + parseInt($(`[name="jqx-endMiniute"]`).val()); // 시간을 분으로 치환
                    if($(`[name="jqx-endTime"]`).val() == 12 || $(`[name="jqx-endTime"]`).val() == 18 ){
                        $(`[name="jqx-endMiniute"]`).val(0);
                        $(`select[name="jqx-endMiniute"] option[value*=30]`).prop('disabled',true);
                    }
                    else{
                        $(`select[name="jqx-endMiniute"] option[value*=30]`).prop('disabled',false)
                    }

                    if(reStartTime > reEndTime || reStartTime == reEndTime){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>시작 시간이 끝나는 시간보다 클 수 없습니다.</span>`);
                    }
                    else if((reEndTime-reStartTime) < 60){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>최소 예약시간은 한 시간입니다.</span>`);
                    }
                    else {
                        $(`[name="jqx-timer"]`).html(null);
                    }
                })
                $(`[name="jqx-endMiniute"]`).change(()=>{
                    const reStartTime = parseInt($(`[name="jqx-startTime"]`).val()) * 60 + parseInt($(`[name="jqx-startMiniute"]`).val()); // 시간을 분으로 치환
                    const reEndTime = parseInt($(`[name="jqx-endTime"]`).val()) * 60 + parseInt($(`[name="jqx-endMiniute"]`).val()); // 시간을 분으로 치환
                    if($(`[name="jqx-endTime"]`).val() == 12 || $(`[name="jqx-endTime"]`).val() == 18 ){
                        $(`[name="jqx-endMiniute"]`).val(0);
                        $(`select[name="jqx-endMiniute"] option[value*=30]`).prop('disabled',true);
                    }
                    else{
                        $(`select[name="jqx-endMiniute"] option[value*=30]`).prop('disabled',false)
                    }

                    if(reStartTime > reEndTime || reStartTime == reEndTime){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>시작 시간이 끝나는 시간보다 클 수 없습니다.</span>`);
                    }
                    else if((reEndTime-reStartTime) < 60){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>최소 예약시간은 한 시간입니다.</span>`);
                    }
                    else {
                        $(`[name="jqx-timer"]`).html(null);
                    }
                })
                $(`[name="jqx-startMiniute"]`).change(()=>{
                    const reStartTime = parseInt($(`[name="jqx-startTime"]`).val()) * 60 + parseInt($(`[name="jqx-startMiniute"]`).val()); // 시간을 분으로 치환
                    const reEndTime = parseInt($(`[name="jqx-endTime"]`).val()) * 60 + parseInt($(`[name="jqx-endMiniute"]`).val()); // 시간을 분으로 치환
                    if($(`[name="jqx-endTime"]`).val() == 12 || $(`[name="jqx-endTime"]`).val() == 18 ){
                        $(`[name="jqx-endMiniute"]`).val(0);
                        $(`select[name="jqx-endMiniute"] option[value*=30]`).prop('disabled',true);
                    }
                    else{
                        $(`select[name="jqx-endMiniute"] option[value*=30]`).prop('disabled',false)
                    }

                    if(reStartTime > reEndTime || reStartTime == reEndTime){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>시작 시간이 끝나는 시간보다 클 수 없습니다.</span>`);
                    }
                    else if((reEndTime-reStartTime) < 60){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>최소 예약시간은 한 시간입니다.</span>`);
                    }
                    else {
                        $(`[name="jqx-timer"]`).html(null);
                    }
                })
                
                $(`[name="jqx-startTime"]`).change(()=>{
                    const reStartTime = parseInt($(`[name="jqx-startTime"]`).val()) * 60 + parseInt($(`[name="jqx-startMiniute"]`).val()); // 시간을 분으로 치환
                    const reEndTime = parseInt($(`[name="jqx-endTime"]`).val()) * 60 + parseInt($(`[name="jqx-endMiniute"]`).val()); // 시간을 분으로 치환

                    

                    if(reStartTime > reEndTime || reStartTime == reEndTime){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>시작 시간이 끝나는 시간보다 클 수 없습니다.</span>`);
                    }
                    else if((reEndTime-reStartTime) < 60){
                        $(`[name="jqx-timer"]`).html(`<span style='color:red'>최소 예약시간은 한 시간입니다.</span>`);
                    }
                    else {
                        $(`[name="jqx-timer"]`).html(null);
                    }
                })

                //$(`[name="jqx-startTime"]`).val(getStartTime)
                //$(`[name="jqx-endTime"]`).val(getEndTime)

                //if (!_.isEmpty(user_name)){
                $(`[name="jqx-userName"]`).val(user_name)
                // }else {
                //     $(`[name="jqx-userName"]`).attr('placeholder','사용자명 또는 기관명')
                // }
                $(`textarea`).attr('placeholder','사용목적 작성')
                appointmentPropertyChange(editAppointment, 'userName', user_name)
                setTimeout(() => {
                    fields.userName.val(user_name)
                })
                
                $(`[name="jqx-useNum"]`).val(use_num)
                appointmentPropertyChange(editAppointment, 'useNum', use_num)
                setTimeout(() => {
                    fields.useNum.val(use_num)
                })
                
                $(`[name="jqx-check"]`).attr('checked',able_check)
                // if (!_.isEmpty(user_name)){
                //     $(`[name="jqx-userName"]`).val(user_name)
                //     appointmentPropertyChange(editAppointment, 'userName', user_name)
                //     setTimeout(() => {
                //         fields.userName.val(user_name)
                //     })
                // }
                // else{
                //     $(`[name="jqx-userName"]`).val(null)
                //     appointmentPropertyChange(editAppointment, 'userName', null)
                //     setTimeout(() => {
                //         fields.userName.val(null)
                //     })
                // }

                // if(use_num){
                //     $(`[name="jqx-useNum"]`).val(use_num)
                //     appointmentPropertyChange(editAppointment, 'useNum', use_num)
                //     setTimeout(() => {
                //         fields.useNum.val(use_num)
                //     })
                // }
                // else{
                //     $(`[name="jqx-userName"]`).val(use_num)
                //     appointmentPropertyChange(editAppointment, 'useNum', use_num)
                //     setTimeout(() => {
                //         fields.useNum.val(use_num)
                //     })
                // }
                
                // if (able_check){
                //     $(`[name="jqx-check"]`).attr('checked',true)
                // }
                // else{

                // }
                

                //등록자
                if (!_.isEmpty(schedule_author)) {
                    setTimeout(() => {
                        // const _username = schedule_author.username.slice(0, 3).padEnd(6, "***")
                        const _username = schedule_author.name
                        const hp = schedule_author.hp
                        fields.subject.val(`${_username} / ${hp}`)
                    })
                    appointmentPropertyChange(editAppointment, 'author', {...schedule_author})
                    if (user && schedule_author.id != user.id) {
                        // admin일 때
                        if (user.role.name === 'Admin') {
                            fields.buttons[0].children[1].style.display = 'block'
                            fields.buttons.show()
                        } else {
                            // staff이고, 로그인된 user의 소속과 예약 공간의 title이 동일할 때
                            if (user.role.name === 'staff') {
                                if (_.get(editAppointment, 'space_resource.title') === user.organization) {
                                    fields.buttons[0].children[1].style.display = 'block'
                                    fields.buttons.show()
                                } else {
                                    fields.buttons.hide()
                                }
                            } else {
                                fields.buttons.hide()
                            }
                        }
                    } else {
                        fields.buttons[0].children[1].style.display = 'block'
                        fields.buttons.show()
                    }
                }
                else{
                    fields.buttons[0].children[1].style.display = 'none'
                    setTimeout(() => {
                        // const _username = user.name.slice(0, 1).padEnd(6, "***")
                        const _username = user.name
                        const hp = user.hp
                        fields.subject.val(`${_username} / ${hp}`)
                        //fields.useNum.val(use_num)
                        //fields.userName.val(user_name)
                    })
                    fields.buttons.show()
                }

                //첨부파일 관련
                if (editAppointment.documentfiles) {
                    fields.documentfilesContaier.find(`.file-list`).html(
                        _.map(editAppointment.documentfiles, (v) => {
                            const {author = {}} = editAppointment;
                            if (
                                (author && user) &&
                                author.id === user.id ||
                                user.role.name === 'Admin'
                            ) {
                                return (`<div><a href='https://portal.gjudec.com/api/${v.url}'>${v.name} </a> <button id="${v.id}" class="btn btn-danger btn-sm documentfile-delete">x</button></div>`)
                            } else if (
                                (author && user) &&
                                author.id === user.id ||
                                user.role.name === 'staff'
                            ) {
                                if (_.get(editAppointment, 'space_resource.title') == user.organization) {
                                    return (`<div><a href='https://portal.gjudec.com/api/${v.url}'>${v.name} </a><button id="${v.id}" class="btn btn-danger btn-sm documentfile-delete">x</button></div>`)
                                }
                            } else {
                                return (`<div>${v.name}</div>`)
                            }
                        })
                    );
                    appointmentPropertyChange(editAppointment, 'documentfiles', {...editAppointment.documentfiles})
                }
            }
        }
        /** 등록시 */
        else {
            if (user) {
                setTimeout(() => {
                    // const _username = user.username.slice(0, 3).padEnd(6, "***")
                    const _username = user.username
                    const hp = user.hp
                    fields.subject.val(`${_username} / ${hp}`)
                })
                appointmentPropertyChange(editAppointment, 'author', {...user})
                
            }
            scheduler.closeDialog();
        }
 
        //Read only
        fields.location.prop("readonly", true); //공간 카테고리
        fields.subject.prop("readonly", true);  //유저아이디

        if (!user) {
            editDialogFieldStatus(fields, true) //수정 불가
        }
    }, [scheduler, getAbailables, equipmentResources, spaceResources, user]);

    //일정 데이터 Setting
    const appointmentPropertyChange = useCallback((appointment, key, data) => {
        if (!appointment) return null;
        appointment[key] = data;
        appointment.originalData[key] = data;
        scheduler.setAppointmentProperty(appointment.id, key, data)
    }, [scheduler])


    //다이얼로그 취소
    const editDialogClose = useCallback((dialog, fields, editAppointment) => {
        //공간 목록
        $(`[name="jqx-space_resource"]`).val('')

        //장비 목록
        $('[name="jqx-eq-schedule"]').attr('checked', false)

        //공간 및 장비 관련
        //fields.eqOptions.empty();
        //fields.spaceResource.empty()
        fields.spaceResourceId.val('')

        //파일 삭제 관련
        fields.documentfilesContaier.find(`.file-list .documentfile-delete`).off()

        setSelected({})
    }, [scheduler]);

    //Get 예약 데이터
    const getSchedule = useCallback(async (id) => {
        try {
            const {data = {}} = await api.get({type: 'schedule-spaces', id})
            return data;
        } catch (e) {
            console.log(e, 'getSchedule Error')
        }
        return {};
    }, [])

    //날짜에 따른 동적 공간/장소
    const getResourceByDate = useCallback((fromField, toField) => {
        if (!fromField || !toField) return false;

        const from = moment(fromField.val())
        const to = moment(toField.val())
        const variables = {
            where: {
                "available_day": from.get('day'),
                "available_start_time_lte": from.format("HH:mm:ss"),
                "available_end_time_gte": to.format("HH:mm:ss")
            }
        };
        getAbailables({variables})
    }, [getAbailables]);

    /** 일정 클릭 이벤트 */
    const handleAppointmentClick = (e) => {
        const args = e.args;
        const {appointment = {}} = args;
        const {originalData = {}} = appointment;
        appointmentPropertyChange(appointment, 'author', {...appointment.author})
        appointmentPropertyChange(appointment, 'space_resource', {...appointment.space_resource})
    }

    /** 일정 관련 생성/수정 이벤트 시 */
    const handleAppointmentSet = (e) => {
        const args = e.args;
        const {appointment = {}} = args;
        const {originalData = {}} = appointment;
        if (e.type === 'appointmentAdd') {
            //등록자
            appointmentPropertyChange(appointment, 'author', {...user})
        }

        //공간 목록
        // const spaceResourceId = $(`[name="jqx-space_resource"]`).val();
        const spaceResourceId = $(`[name="jqx-space_resource_id"]`).val();
        const spaceResource = _.find(spaceResources, {id: spaceResourceId}) || null
        if (spaceResource) {
            //공간
            appointmentPropertyChange(appointment, 'space_resource', spaceResource)

            //카테고리
            const spaceResourceCategory = _.get(spaceResource, 'space_resource_category.title', '')
            appointmentPropertyChange(appointment, 'location', spaceResourceCategory)
        }

        //장비 목록
        let checkedEqs = [];
        $('[name="jqx-eq-schedule"]:checked').each((idx, chk) => {
            const eq = _.find(equipmentResources, {id: $(chk).val()});
            eq && checkedEqs.push(eq)
        })
        appointmentPropertyChange(appointment, 'schedule_equipments', checkedEqs)

        //첩부파일
        const files = $("input[name='jqx-documentfiles']").prop("files");
        appointmentPropertyChange(appointment, 'files', files)

        
        //사용자 이름
        const userName = $("input[name='jqx-userName']").prop("value");
        appointmentPropertyChange(appointment, 'userName', userName)

        
        //사용자 수
        const useNum = $("input[name='jqx-useNum']").prop("value");
        appointmentPropertyChange(appointment, 'useNum', useNum)

        //약관 동의
        const ableCheck = $("input[name='jqx-check']").prop("checked");
        appointmentPropertyChange(appointment, 'ableCheck', ableCheck)

        
        //시간데이터 등록
        const startTime = $("[name='jqx-startTime']").prop("value");
        const startMiniute = $("[name='jqx-startMiniute']").prop("value");
        const endTime = $("[name='jqx-endTime']").prop("value");
        const endMiniute = $("[name='jqx-endMiniute']").prop("value");
        appointmentPropertyChange(appointment, 'startTime', `${startTime}:${startMiniute}`)
        appointmentPropertyChange(appointment, 'endTime', `${endTime}:${endMiniute}`)


        //수정 및 등록
        onAction(appointment, e.type, editDialog && true || false)
    }

    /*
     공간 스케쥴 validation
     */
    const spaceValidate = useCallback(async ({id = '', spaceResourceId = '', schedule_from, schedule_to,
    ableCheck,
    useNum,
    userName,
    startTime,
    endTime}) => {
        const result = {success: false, message: null}

        try {
            if (!spaceResourceId || !schedule_from || !schedule_to || !useNum || !userName) {
                result.message = '필수 데이터를 받아오지 못하였습니다.'
                return result;
            }

            if (!ableCheck) {
                result.message = '이용약관에 동의하셔야합니다.'
                return result;
            }

            if(moment(schedule_from).format('HH:mm') > startTime || moment(schedule_to).format('HH:mm') < endTime){
                result.message = '시간 범위에 맞지 않습니다.'
                return result;
            }

            const {data: spaceResource = {}} = await api.get({type: 'space-resources', id: spaceResourceId})
            if (_.isEmpty(spaceResource)) {
                result.message = '공간이 존재하지 않습니다.'
                return result;
            }

            const {available_dates: availableDates = [], title, schedule_spaces = []} = spaceResource;
            const from = moment(schedule_from);
            const to = moment(schedule_to);
            const fromTime = moment(schedule_from, "HH:mm");
            const toTime = moment(schedule_to, "HH:mm");
            const rangeDays = [];
            if (to.get('day') === from.get('day')) {    //시작일과 종료일이 같음. (하루이내)
                rangeDays.push(from.get('day'))
            } else {    //시작일과 종료일이 다름. (여러날짜)
                const _rangeDay = _.range(to.get('day'), from.get('day'))
                rangeDays.push([..._rangeDay])
                result.message = '여러일을 지정할 수 없습니다.'
                return result;
            }

            //공간 중복 여부 확인
            const formStart = moment(schedule_from).set({hour: 0, minute: 0, second: 0});
            const formEnd = moment(schedule_from).set({hour: 23, minute: 59, second: 59});
            const apiParams = {
                type: 'schedule-spaces',
                "space_resource": spaceResourceId,
                "schedule_from_gte": formStart.toISOString(),   //from 의 첫시작 시간
                "schedule_to_lte": formEnd.toISOString(),   //from 의 마지막 시간
                "schedule_from_lt": to.toISOString(),
                "schedule_to_gt": from.toISOString(),
                "space_resource_floor": floorId,
                "use_num": useNum,
                "able_check": ableCheck,
                "user_name": userName
            };
            if (id) {  //수정 시
                apiParams['id_ne'] = id;
            }
            const {data: scheduleDuplicateCnt = 0} = await api.count({...apiParams})
            if (scheduleDuplicateCnt > 0) {
                result.message = `"${title}"의 예약신청이 존재합니다. 다른 시간대를 예약해주세요.`
                return result;
            }

            let isValid = true;
            for (const available_day of rangeDays) {
                const availableDate = _.find(availableDates, {available_day})
                //운영 요일 validation - 예약 가능 여부
                if (!availableDate) {
                    result.message = '공간 운영 요일이 아닙니다.'
                    isValid = false;
                    break;
                }

                const {available_end_time = null, available_start_time = null} = availableDate;
                if (!available_start_time || !available_end_time) {
                    result.message = '공간 운영 시간이 아닙니다.'
                    isValid = false;
                    break;
                }

                //운영 시간 validation - 예약 가능 여부
                const availableStart = moment(available_start_time, "HH:mm");
                availableStart.set({year: from.get('year'), month: from.get('month'), date: from.get('date')})
                const availableEnd = moment(available_end_time, "HH:mm");
                availableEnd.set({year: from.get('year'), month: from.get('month'), date: from.get('date')})

                const diffFrom = moment(from).diff(availableStart)
                const diffTo = moment(availableEnd).diff(to)
                if (diffFrom < 0 || diffTo < 0) {
                    result.message = '공간 운영 시간이 아닙니다.'
                    isValid = false;
                    break;
                }
            }
            result.success = isValid;
            return result;
        } catch (e) {
            console.log(e, 'spaceScheduleValidate error')
        }
        return result;
    }, [scheduler]);


    //캘린더 이벤트 실행
    const onAction = useCallback(async (appointment, type, isOpen = false) => {
        try {

            if (!user) {
                return swal({
                    title: `로그인이 필요한 서비스 입니다.`, text: ' ',
                    icon: "warning", timer: 2000, buttons: false
                });
            }

            /** 일정 등록 */
            if (type === 'appointmentAdd') {
                const {from, to} = appointment;
                const {
                    schedule_title = null,
                    schedule_from, schedule_to,
                    author: {id: author_id = null} = {},
                    space_resource = {},
                    schedule_description = null,
                    schedule_equipments = [],
                    files = [],
                } = appointment.originalData;

                //validate
                if (
                    !schedule_title || !author_id ||
                    !schedule_from || !schedule_to || _.isEmpty(space_resource)
                ) {
                    return swal({
                        title: `일정 생성에 실패하였습니다.`, text: ' ',
                        icon: "warning", timer: 2000, buttons: false
                    });
                }


                //공간 Validation
                const {success = false, message = ''} = await spaceValidate({
                    ...space_resource,
                    spaceResourceId: space_resource.id,
                    schedule_from,
                    schedule_to,
                    id: null,
                });
                if (!success) {
                    return swal({
                        title: `일정 생성에 실패하였습니다.`, text: message && message || ' ',
                        icon: "warning", timer: 3000, buttons: false
                    });
                }

                //1. 공간대여 스케쥴 생성
                const {data: scheduleSpaceResult = {}} = await api.create({
                    type: 'schedule-spaces',
                    schedule_title,
                    schedule_author: {_id: author_id},
                    space_resource: {_id: space_resource.id},
                    schedule_from, schedule_to,
                    schedule_description,
                    // confirmed: true
                }, [{key: 'documentfiles', files}])

                const formatFrom = moment(schedule_from).format(`YYYY-MM-DD hh:mm`);
                const formatTo = moment(schedule_from).format(`YYYY-MM-DD hh:mm`);

                //2. 장비대여 스케쥴 생성
                for (const [idx, eq] of Object.entries(schedule_equipments)) {
                    await api.create({
                        type: 'schedule-equipments',
                        schedule_quantity: 1,
                        schedule_from, schedule_to,
                        schedule_space: {_id: scheduleSpaceResult.id},
                        schedule_author: {_id: author_id},
                        equipment_resource: {_id: eq.id},
                        schedule_title: `${schedule_title}, ${eq.title} ${formatFrom} - ${formatTo}`,
                    })
                }

                swal({
                    title: `일정 생성 완료`, text: `관리자 승인 후 공간이 예약이 완료됩니다.`,
                    icon: "success", timer: 2000, buttons: false
                });
            }
            /** 일정 수정 */
            else if (type === 'appointmentChange') {
                const {id = null, author = {}, originalData = {}} = appointment;
                const {
                    schedule_title = null,
                    schedule_from, schedule_to,
                    author: {id: author_id = null} = {},
                    space_resource = {},
                    schedule_description = null,
                    schedule_equipments = [],
                    files = [],
                    useNum,
                    userName,
                    ableCheck,
                    startTime,
                    endTime
                } = originalData;
                const formatFrom = moment(schedule_from).format(`YYYY-MM-DD hh:mm`);
                const formatTo = moment(schedule_from).format(`YYYY-MM-DD hh:mm`);


                if(!_.isEmpty(author_id)){
                    //유저 validate
                    if (author_id !== user.id && user.role.name !== 'Admin' && user.role.name !== 'staff') {
                        return swal({
                            title: `수정 실패`, text: `자신의 일정만 수정 가능합니다.`,
                            icon: "warning", timer: 2000, buttons: false
                        });
                    } else {
                        if (user.role.name === 'staff') {
                            if(_.get(appointment, 'space_resource.title') == '조선대학교') {
                                if (_.get(appointment, 'space_resource.title') !== user.organization) {
                                    scheduler.closeMenu()
                                    return swal({
                                        title: `자신의 일정만 수정 가능합니다.`, text: ` `,
                                        icon: "warning", timer: 2000, buttons: false
                                    });
                                }
                            }
                        }
                    }
                    //validate
                    if (
                        !schedule_title || !author_id ||
                        !schedule_from || !schedule_to || _.isEmpty(space_resource)
                    ) {
                        return swal({
                            title: `일정 수정에 실패하였습니다.`, text: ' ',
                            icon: "warning", timer: 2000, buttons: false
                        });
                    }

                }

                // 공간 대여 validation
                const {success = false, message = ''} = await spaceValidate({
                    ...space_resource,
                    spaceResourceId: space_resource.id,
                    schedule_from,
                    schedule_to,
                    id,
                    ableCheck,
                    useNum,
                    userName,
                    startTime,
                    endTime
                });
                if (!success) {
                    return swal({
                        title: `일정 수정에 실패하였습니다.`, text: message && message || ' ',
                        icon: "warning", timer: 3000, buttons: false
                    });
                }

                //다이얼로그 상세 수정
                if (isOpen) {
                    //1. 공간대여 스케쥴 수정
                    const {data: scheduleSpaceResult = {}} = await api.update({
                        type: 'schedule-spaces', id,
                        //schedule_title:user.name.slice(0, 3).padEnd(6, "***"),
                        schedule_title:user.name,
                        space_resource: space_resource.id,
                        schedule_author: user.id,
                        schedule_from, schedule_to, schedule_description,
                        use_num:useNum,
                        user_name:userName,
                        able_check:ableCheck,
                        start_time:`${startTime}`,
                        end_time:`${endTime}`
                    }, [{key: 'documentfiles', files}])


                    const from = userName + ',' + user.email;
                    const to = 'operator@gj.udec.kr,operator@gj.udec.kr';
                    const recv_contact = user.stu_hp_phone_number
                    const {data: message_result = {}} = await api.create({
                        type: `lms-messages`,
                        from: from,
                        to: to,
                        subject: '스튜디오 신청 알림',
                        body: `
                        스튜디오 명 : ${_.get(space_resource,'title')} (${_.get(space_resource,'space_resource_category.title')})<br/>
                        일시 : ${moment(schedule_from).format('YYYY-MM-DD (dd)')} ${startTime}~${endTime}<br/>
                        사용자 명 : ${userName}<br/>
                        신청자 명 : ${user.name}<br/>
                        신청자 전화번호 : ${user.hp}<br/>
                        `,
                        content_type: 'studio_reservation',
                        send_method: 'email',
                        recv_contact: recv_contact,
                        link: null,
                        refer_cw: '스튜디오 예약,예약 신청',
                        message_type: 'email,web_push,app_push',
                        sent_status: false
                    })

                    //console.log(message_result)

                    //변경해야 할 장비 스케쥴
                    const currentEquipments = scheduleSpaceResult.schedule_equipments || [];
                    const modifyEquipments = schedule_equipments;

                    const delEqs = _.difference(currentEquipments, modifyEquipments);
                    const addEqs = _.difference(modifyEquipments, currentEquipments);

                    //2. 장비대여 스케쥴 생성
                    for (const [idx, eq] of Object.entries(addEqs)) {
                        await api.create({
                            type: 'schedule-equipments',
                            schedule_quantity: 1,
                            schedule_from, schedule_to,
                            schedule_space: {_id: scheduleSpaceResult.id},
                            schedule_author: {_id: author_id},
                            equipment_resource: {_id: eq.id},
                            schedule_title: `${schedule_title}, ${eq.title} ${formatFrom} - ${formatTo}`,
                        })
                    }

                    //3. 장비대여 스케쥴 삭제
                    for (const [idx, eq] of Object.entries(delEqs)) {
                        await api.del({type: 'schedule-equipments', id: eq.id});
                    }
                }
                //간이 수정
                else {
                    await api.update({
                        type: 'schedule-spaces', id,
                        schedule_from, schedule_to,
                    });
                }

                swal({
                    title: `일정 수정 완료`, text: `일정 수정에 성공하였습니다.`,
                    icon: "success", timer: 2000, buttons: false
                });
            }
            /** 일정 삭제 */
            else if (type === 'appointmentDelete') {
                const {
                    id = null,
                    author: {id: author_id = null} = {},
                } = appointment;

                //유저 validate
                if (author_id !== user.id && user.role.name !== 'Admin' && user.role.name !== 'staff') {
                    return swal({
                        title: `수정 실패`, text: `자신의 일정만 수정 가능합니다.`,
                        icon: "warning", timer: 2000, buttons: false
                    });
                } else {
                    if (user.role.name === 'staff') {
                        if(_.get(appointment, 'space_resource.title') == '조선대학교') {
                            if (_.get(appointment, 'space_resource.title') !== user.organization) {
                                scheduler.closeMenu()
                                return swal({
                                    title: `자신의 일정만 수정 가능합니다.`, text: ` `,
                                    icon: "warning", timer: 2000, buttons: false
                                });
                            }
                        }
                    }
                }

                const schedule = await getSchedule(id)
                if (!schedule) return false;
                const {schedule_equipments = []} = schedule;

                //1. 공간대여 스케쥴 삭제
                const {data: scheduleSpaceResult = {}} = await api.update({
                    type: 'schedule-spaces',
                    id ,
                    schedule_author:null,
                    schedule_title:null,
                    schedule_description:null,
                    confirmed:false
                });

                //2. 장비대여 스케쥴 삭제
                for (const [idx, se] of Object.entries(schedule_equipments)) {
                   //await api.update({type: 'schedule-equipments', id: se.id});
                }

                swal({
                    title: `일정 삭제 완료`, text: `일정 삭제에 성공하였습니다.`,
                    icon: "success", timer: 2000, buttons: false
                });
            }
        } catch (e) {
            console.log(e, 'handleEvent error')
        } finally {
            getData()
        }
    }, [user])


    //일정 데이터 렌더링
    const renderAppointment = useCallback((data) => {
        data.appointment.draggable = false;
        data.appointment.resizable = false;

        if(_.isEmpty(_.get(data.appointment,'author'))){

            if(moment(data.appointment.from.toDate()) < moment()){
                data.appointment.readOnly = true;
                data.borderColor = '#dddddd'
                data.html = `
                    <div class="small">예약 불가</div>
                `;
            }
            else{
                let timing = '';
                if(moment(data.appointment.from.toDate()).format('a') == '오전'){
                    timing = 'AM'
                }
                else if (moment(data.appointment.from.toDate()).format('a') == '오후'){
                    timing = 'PM'
                }
                data.borderColor = '#dddddd'
                data.html = `
                    <div class="small schedules">예약 가능 ${timing}</div>
                `;
            }
            return data;
        }
        const {author: {author_id = '',username=''} = {}, from, to, location = '', confirmed = false, space_resource = {}} = data.appointment;
        const {id = '', title = ''} = space_resource;

        const fromDate = new jqx.date(from, `Korea Standard Time`);
        const toDate = new jqx.date(to, `Korea Standard Time`);
        const fromString = moment(fromDate.toDate()).format("YYYY-MM-DD HH:mm");
        const toString = moment(toDate.toDate()).format("YYYY-MM-DD HH:mm");

        //나의 일정
        if (!_.isEmpty(user) && user.id === author_id) {
            data.borderColor = '#E40000'
        }
        data.html = `
        <div class="small">${confirmed && '승인' || '미승인'}</div>
        <div class="small text-dark">${fromString} ~ ${toString}</div>
        `;
        return data;
    }, [user]);


    //Cell Rendering Hook
    const handleRendered = useCallback(() => {
        if (!_.isEmpty(space)) {
            const {available_dates = []} = space;
            $(`td[data-date]`).each((idx, v) => {
                const dateData = $(v).data('date');
                const cellDate = moment(dateData);
                const availFilterDate = _.find(available_dates, {available_day: cellDate.get('day')}) || null;
                if (!availFilterDate) return;

                const {available_end_time = '', available_start_time = ''} = availFilterDate;
                const availableStart = moment(available_start_time, "HH:mm");
                availableStart.set({
                    year: cellDate.get('year'),
                    month: cellDate.get('month'),
                    date: cellDate.get('date')
                })
                const availableEnd = moment(available_end_time, "HH:mm");
                availableEnd.set({
                    year: cellDate.get('year'),
                    month: cellDate.get('month'),
                    date: cellDate.get('date')
                })

                if (cellDate < availableEnd && cellDate >= availableStart) {
                    $(v).css({backgroundColor: '#dcf0fb', border: 'solid #E0E0E0', borderWidth: '0px 0px 1px 1px'});
                    $(v)[0].className += ' space_able'
                }
            })
        }
    }, [space, scheduler]);


    if (_.isEmpty(option)) return null;
    return (
        <>
            <JqxScheduler
                ref={ref => setScheduler(ref)}
                theme={'material'}
                width={'100%'}
                // view={"monthView"}
                view={view}
                ready={getData}
                // height={option.height}
                height={500}
                source={option.source}
                showLegend={false}
                renderAppointment={renderAppointment}
                appointmentDataFields={option.appointmentDataFields}
                resources={option.resources}
                localization={option.localization}
                view = {'monthView'}
                views={
                    [
                        /*{ type: "dayView", workTime:{
                                fromDayOfWeek: 1,
                                fromHour: 9,
                                toDayOfWeek: 6,
                                toHour: 18,
                        }, showWeekends: false, timeRuler: { hidden: false } },
                        { type: "weekView",
                            workTime:{
                                fromDayOfWeek: 1,
                                fromHour: 9,
                                toDayOfWeek: 6,
                                toHour: 18,
                            },
                            showWeekends: false, timeRuler: { hidden: false } },*/
                        {
                            type: "monthView"
                        }
                    ]
                }
                onDateChange={(e)=> {
                    setFromDate(e.args.from.toDate())
                    setToDate(e.args.to.toDate())
                }}
                toolBarRangeFormat={option.toolBarRangeFormat}
                timeZone={`Korea Standard Time`}
                rendered={handleRendered}
                editDialogCreate={editDialogCreate} //Dialog Dom 생성
                editDialogOpen={editDialogOpen} //Dialog 오픈 시
                editDialogClose={editDialogClose} //Dialog 종료 시
                onAppointmentAdd={handleAppointmentSet}   //일정 등록 시
                onAppointmentChange={handleAppointmentSet}    //일정 수정 시
                onAppointmentClick={handleAppointmentClick} //일정 선택
                onAppointmentDelete={(e) => {   //일정 삭제 시
                    const args = e.args;
                    const {appointment = {}} = args;
                    const {originalData = {}} = appointment;
                    //return null;
                    onAction(appointment, e.type)
                }}
                onEditDialogOpen={(dialog) => setEditDialog(dialog)}
                onEditDialogClose={(dialog) => setEditDialog(null)}
                onAppointmentDoubleClick={(e) => {  //일정 더블클릭시
                    //비회원 팝업 제한
                    if (!user) {
                        scheduler.closeDialog()
                        swal({
                            title: `로그인한 사용자만 이용이 가능합니다.`, text: ' ',
                            icon: "warning", timer: 2000, buttons: false
                        });
                    }
                    else{
                        return
                    }
                }}
                onContextMenuOpen={(e) => { //오른쪽 마우스 클릭시
                    //console.log('오른쪽 마우스 클릭')
                    const args = e.args;
                    const {appointment = {}} = args;

                    //비회원 팝업 제한
                    if (!user) {
                        scheduler.closeMenu()
                        return swal({
                            title: `로그인한 사용자만 이용이 가능합니다.`, text: ' ',
                            icon: "warning", timer: 2000, buttons: false
                        });
                    }

                    //수정 시
                    if (appointment) {
                        const {originalData = {}} = appointment;
                        const {author: {id: author_id = null} = {}} = originalData;

                        if(moment(_.get(appointment,'from.toDate()')) < moment()) {
                            scheduler.closeMenu()
                            return swal({
                                title: `예약할 수 있는 시간이 지났습니다.`, text: ' ',
                                icon: "warning", timer: 2000, buttons: false
                            });
                        }

                        //자신의 일정만 수정 가능
                        if(!_.isEmpty(author_id)){
                            if (author_id !== user.id && user.role.name !== 'Admin' && user.role.name !== 'staff') {
                                scheduler.closeMenu()
                                return swal({
                                    title: `자신의 일정만 수정 가능합니다.`, text: ` `,
                                    icon: "warning", timer: 2000, buttons: false
                                });
                            } else {
                                if (user.role.name === 'staff') {
                                    if(_.get(appointment, 'space_resource.title') == '조선대학교'){
                                        if (_.get(appointment, 'space_resource.title') !== user.organization) {
                                            scheduler.closeMenu()
                                            return swal({
                                                title: `자신의 일정만 수정 가능합니다.`, text: ` `,
                                                icon: "warning", timer: 2000, buttons: false
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }}
                onViewChange={(e) => {
                    const args = e.args;
                    const {newViewType, oldViewType} = args;
                    setView(newViewType);
                }}
            />
        </>
    );
};

export default ScheduleCalendar;
