import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {apiUrl} from 'config';
import * as api from 'lib/content';

const EquipmentItem = ({items = []}) => {
    return (
        <div className="slidebox">
            {items.map(item => {
                const {
                    id = '', title = '', quantity = 0,
                    thumbnailimages: [thumbnail = {}] = [],
                    documentfiles = []
                } = item;
                const imageUrl = _.get(thumbnail, 'formats.small.url', null) &&
                    `${apiUrl}${_.get(thumbnail, 'formats.small.url', '')}` ||
                    '/assets/images/image-not-found.png';
                return (
                    <div className="item" key={id}>
                        {/*<Link to={`/resource/device/${id}`}>*/}
                        <span className={`status ${!item.status && 'disabled'}`}>대여가능</span>
                        <div className="thumb size-1-1">
                            <img src={imageUrl} alt={title}/>
                        </div>
                        <div className="info">
                            <h3>{title}</h3>
                            <p>보유 {quantity || 0}개</p>
                            {documentfiles.length && documentfiles.map((file, idx) => {
                                const {url = '', name = ''} = file
                                return (
                                    <button key={idx} onClick={(e) => api.download(`${apiUrl}${url}`, name)}
                                            className="download">서식 다운로드
                                    </button>
                                )
                            })}
                        </div>
                        {/*</Link>*/}
                    </div>
                );
            })}
        </div>
    );
};

export default EquipmentItem;
