import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowDropRightLine } from 'react-icons/all';

const MobileNavItem = ({ data }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="mobileNav__link-item">
            <button className='mobileNav__Button' onClick={() => setOpen(!open)}><span style={open === true ? { "color": "#00863C" } : { "color": "#333" }}>{data.title}</span><img src={open === true ? '/assets/images/arrowbottom.svg' : '/assets/images/arrowright.svg'} /></button>
            <div className={`depth ${open ? 'active' : ''}`}>
                {
                    data.list.map(link => {
                        if (_.isEmpty(link.type)) {
                            return (
                                <Link to={link.url} key={link.title}>
                                    {link.title}
                                    {/* <RiArrowDropRightLine /> */}
                                </Link>)
                        }
                        else {
                            return (
                                <a href={link.url} key={link.title} activeClassName="active">{link.title}</a>
                            )
                        }
                    })
                }
            </div>
        </div>
    );
};

export default MobileNavItem;
