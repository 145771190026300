export default {
    depth1: {
        title: '센터 소개',
        code: 'about',
        list: [
            {
                title: '인사말',
                // url: '#'
                url: '/about/introduction'
            }, {
                title: '조직도',
                url: '/about/organization'
                // url: '/about/membership'
            }, {
                title: '규정 및 지침',
                url: '/about/guideline'
                // url: '/about/membership'
            }, {
                title: '오시는 길',
                // url: '#'
                url: '/about/map'
            }, {
                title: '참여대학',
                // url: '#'
                url: '/about/college'
            },
        ]
    },
    depth2: {
        title: 'LMS∙인프라',
        code: 'lms',
        url: 'https://lms.gjudec.com/home/dashboard',
        type: 'a',
        list: [
            {
                title: '통합 LMS',
                // url: '#'
                //type:'a',
                //taget:'_blank',
                url: 'https://lms.gjudec.com/home/dashboard',
                type: 'a'
                //href: 'https://lms.gjudec.com/member/ext_login'
            },
            {
                title: '메타버스',
                // url: '/content/metaverse'
                url: '/university/metaverse'
            },
            {
                title: '스튜디오 예약',
                // url: '/resource/studio'
                url: '/university/studio'
                // url: '/help/remote'
            },
        ]
    },
    depth3: {
        title: '콘텐츠',
        code: 'content',
        list: [
            {
                title: '통합 콘텐츠',
                url: '/content/repository'
                // url: '/resource/space'
            },
            {
                title: '품질관리 시스템',
                url: '/content/auditcontent'
                // url: '/resource/device'
            },
        ]
    },
    depth4: {
        title: '원격수업 지원',
        code: 'resource',
        list: [
            {
                title: '역량강화 워크숍',
                url: '/resource/competencyworkshop'
                // url: '/help/qna'
            },
            {
                title: '원격수업 컨설팅',
                url: '/resource/consulting',
                //type:'a'
                // url: '/help/remote'
            },
            {
                title: '원격교육 혁신 브리프',
                url: '/resource/innovation_brief'
            },
            {
                title: '원격수업 우수사례',
                url: '/resource/youtube'
                // url: '/resource/device'
            },
            {
                title: '역량 진단평가',
                url: '/resource/evaluate'
            },
        ]
    },
    depth5: {
        title: '커뮤니티',
        code: 'community',
        list: [
            {
                title: '공지사항',
                // url: '#'
                url: '/community/notice'
            },
            {
                title: '센터 소식',
                url: '/community/news'
                // url: '/board/faq'
                //}
            },
            {
                title: '참여대학 소식',
                url: '/community/college_news'
                // url: '/board/faq'
                //}
            },
            {
                title: '뉴스레터 어디라도',
                url: '/community/letter_news'
                // url: '/board/faq'
                //}
            },
            {
                title: '자료실',
                url: '/community/reference',
                // url: '/board/faq'
                innerDepth: [
                    {
                        title: '포토 갤러리',
                        url: '/community/reference/photo'
                    },
                    {
                        title: '동영상',
                        url: '/community/reference/videos'
                    },
                    {
                        title: 'eBook',
                        url: '/community/reference/ebook'
                    }
                ]
            },
            {
                title: '설문조사',
                url: '/community/survey'
                // url: '/board/faq'
            },
        ]
    },
    depth6: {
        title: '마이페이지',
        code: 'member',
        list: [

            {
                title: '나의 강좌: 콘텐츠',
                admission: 'student',
                url: '/member/InstructorCourse'
                //url: '/member/mystudyprogram',
            },
            {
                title: '나의 강좌: 콘텐츠',
                admission: 'instructor',
                url: '/member/mycontent'
                //url: '/member/mystudyprogram'
            },

            {
                // title: window.location.href.indexOf('gjudec') > -1 ? '' : '나의 메타버스',
                title: '나의 메타버스',
                url: '/member/mymetaverse'
            },
            {
                title: '프로그램 신청 확인',
                // url: '#'
                url: '/member/mystudyprogram'
            },
            // {
            //     title: '컨설팅 신청 확인',
            //     url: '/member/myconsulting'
            //     // url: '/board/faq'
            // },
            {
                title: '공간·장비 예약 현황',
                url: '/member/myresource'
                // url: '/board/faq'
            },
            {
                title: '나의 역량진단평가',
                url: '/member/MyEvaluation'
                // url: '/board/faq'
            },
            {
                title: '뉴스레터 구독',
                url: '/member/SubscribeNewsLetter'
                // url: '/board/faq'
            },
            {
                title: '개인정보 수정',
                url: '/member/changeinfo'
                // url: '/board/faq'
            },
            {
                title: '회원탈퇴',
                url: '/member/withdrawal'
                // url: '/board/faq'
            }
        ]
    },
    depth7: {
        title: '커리큘럼',
        code: 'curriculum',
        list: [

            {
                title: '개요',
                url: '/digitalsprout/curriculum/summary'
            },
            {
                title: '온라인 과정 안내',
                url: '/digitalsprout/curriculum/digital-class'
            },
            {
                title: '오프라인 과정 안내',
                url: '/digitalsprout/curriculum/digital-offline-class'
            }
        ]
    },
    depth8: {
        title: '커뮤니티',
        code: 'community',
        list: [
            {
                title: '공지사항',
                url: '/digitalsprout/notice'
            },
            {
                title: 'FAQ',
                url: '/digitalsprout/faq'
            },
            {
                title: 'Q&A',
                url: '/digitalsprout/qna'
            },
            {
                title: '자유게시판',
                url: '/digitalsprout/freeboard'
            },
            {
                title: '포토갤러리',
                url: '/digitalsprout/photo'
            },
            {
                title: '참여후기',
                url: '/digitalsprout/review'
            },

        ]
    },
    depth9: {
        title: '민원창구',
        code: 'help',
        list: [
            {
                title: 'Q&A',
                url: '/help/qna'
                // url: '/board/notice'
            },
            {
                title: 'FAQ',
                // url: '#'
                url: '/help/faq'
            },
            {
                title: '장애접수',
                url: '/help/disabilityreception'
                // url: '/board/faq'
            },
            {
                title: '클린신고',
                url: '/help/clean'
                // url: '/board/faq'
            },

        ]
    },
};
