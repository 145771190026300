import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  lazy,
  Suspense,
  useMemo
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/actions/auth';
import LoadingBar from 'react-redux-loading-bar';
import { useCookies } from 'react-cookie';
import Cookie from "universal-cookie";

import GaiPopup from 'components/Gai/GaiPopup';

import 'styles/style.scss';

import IntroFooter from 'components/Intro/IntroFooter';

import { Header, Footer, MobileNav, MobileUserMenu } from 'components/Layout';
import Quick from 'components/Quick';

const Home = lazy(() => import('./Home'));
import Home2 from './Home2';
const About = lazy(() => import('./About'));
const Program = lazy(() => import('./Program'));
const Resource = lazy(() => import('./Resource'));
const Onair = lazy(() => import('./Onair'));
const Dataset = lazy(() => import('./Dataset'));
const Digitalsprout = lazy(() => import('./Digitalsprout'));
const Member = lazy(() => import('./Member'));
const Content = lazy(() => import('./Content'));
const Varsity = lazy(() => import('./Varsity'));
const Lms = lazy(() => import('./Lms'));
const Community = lazy(() => import('./Community'));
const Help = lazy(() => import('./Help'));
const Sublms = lazy(() => import('./Sublms'));
const Admin = lazy(() => import('./Admin'));
const Privacypolicy = lazy(() => import('./Home/Privacypolicy'));
const Copyright = lazy(() => import('./Home/Copyright'));
const Emailwarning = lazy(() => import('./Home/Emailwarning'));
const { NotFound, Forbidden } = lazy(() => import('components/Errors'));
import { Logging } from '../utils/logging'

//import { Logging } from '../utils/logging'

import ScrollToTop from 'components/ScrollToTop';
import useWindowPosition from 'utils/useWindowPosition';
import { BetaAnalyticsDataClient } from '@google-analytics/data';
// Head Metadata
import { Helmet } from 'react-helmet';
import config from 'config';
import { loginCookie } from '../utils/loginCookie.js'
import axios from 'axios'

const analyticsId = 'G-7TPV4S751V'
const RouterApp = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [pageClass, setPageClass] = useState(null);
  const [cookie, setCookie, removeCookie] = useCookies([]);

  const [pathName, setPathName] = useState('');
  const [prevPathName, setPrevPathName] = useState('');
  const [userInfo, setUserInfo] = useState({})
  const [popupOpen, setPopupOpen] = useState(false)
  const history = useHistory();

  const swalOption = {
    closeOnClickOutside: false,
    closeOnEsc: false
  }

  useEffect(() => {
    if (!_.isEmpty(_.get(cookie, 'jwt'))) {
      removeCookie('jwt', { path: "/", domain: "www.gjudec.com" })
      removeCookie('user', { path: "/", domain: "www.gjudec.com" })
      removeCookie('username', { path: "/", domain: "www.gjudec.com" })
    }
  }, [cookie]);


  const handleChangeOpenUserMenu = useCallback(
    (bool) => {
      setOpenUserMenu(bool);
    },
    [openUserMenu]
  );

  const handleChangeOpenMobileNav = useCallback(
    (bool) => {
      setOpenMobileNav(bool);
    },
    [openMobileNav]
  );

  useEffect(async () => {
    const cookies = new Cookie();
    // if(!_.isEmpty(localStorage.getItem('jwt'))){
    //   dispatch(authActions.authorization());
    // }
    if (!_.isEmpty(cookies.get('jwt'))) {
      dispatch(authActions.authorization());
    }
  }, [new Cookie().get('jwt')]);

  const {
    user,
    isLoggedIn,
    loading: userLoading,
  } = useSelector(({ auth }) => ({ ...auth }));



  useEffect(() => {
    setPrevPathName(pathName)
    setPathName(window.location.pathname)
  }, [window.location.pathname])

  useEffect(() => {
    setUserInfo(user)
    let contentUrlArr = ['notice/', 'promotion_video/', 'education-news/', 'newsletter/', 'column/', 'qna/']
    let contetntOX = 0
    contentUrlArr.map((item) => {
      if (pathName.includes(item)) contetntOX++
    });
    setTimeout(() => {
      if (pathName != '' && contetntOX == 0) {
        Logging('weblog', 'GJUDEC_PORTAL', user, 'menu_link', 'page', '', {}, pathName, prevPathName)
      }
    }, 500)
  }, [pathName, prevPathName, user]);

  useEffect(async () => {
    if (!_.isEmpty(user)) {
      const intervalId = setInterval(async () => {
        loginCookie({ intervalId, swalOption, dispatch })
      }, 5000)

      return () => clearInterval(intervalId);
    }
    else{
      setPopupOpen(false)
    }
  }, [user])

  useEffect(async () => {
    setPageClass(
      _.chain(location.pathname)
        .split('/')
        .filter()
        .map((name, idx, array) => {
          return idx > 0 ? `page-${array[idx - 1]}-${name}` : `page-${name}`;
        })
        .join(' ')
        .value()
    );
    const cookies = new Cookie();
    if (!_.isEmpty(cookies.get('jwt'))) {
      var now = new Date();
      var minutes = 30;
      now.setTime(now.getTime() + (minutes * 60 * 1000));
      let session_id = cookies.get('session_id');
      await axios.post('/custom-api/sso/update', { session_id })
      cookies.set('expiredTime', now, { expires: now, httpOnly: false })
    }
  }, [pathname]);

  //나의 유저 정보 Load
  // useEffect(() => {
  //   dispatch(authActions.authorization());
  // }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(localStorage.getItem('jwt'))) {
  //     dispatch(authActions.authorization());
  //   }
  // }, [localStorage.getItem('jwt')]);


  //221216 이전페이지 저장하기
  useEffect(() => {
    if (!_.isEmpty(pathname)) {
      if (pathname != "/member/login") {
        setTimeout(() => {
          localStorage.setItem("prevPage", pathname)
        }, 100)
      }
    }
  }, [pathname])

  const 임시_GAI_사용가능_아이디 = useMemo(()=>{

    return !_.isEmpty(user) && (
      _.get(user,'username') == 'student001' ||
      _.get(user,'username') == 'student002' ||
      _.get(user,'username') == 'student003' ||
      _.get(user,'username') == 'student004' ||
      _.get(user,'username') == 'student005' ||
      _.get(user,'username') == 'tjdals8019@jnu.ac.kr' || // gjudec 요청 아이디
      _.get(user,'username') == '217855' ||               // gjudec 요청 아이디
      _.get(user,'username') == 'operator@gj.udec.kr'     // gjudec 요청 아이디

    )
  
  },[user])

  return (
    <div
      className={`wrapper ${pageClass ? 'page ' + pageClass : ''} `}
    >

      {
        임시_GAI_사용가능_아이디 &&
        !popupOpen ? 
        <button className={'gai_icon'} onClick={()=>{setPopupOpen(true)}}>
            <img src={'/assets/images/GAI/GAI_Icon_2.svg'}/>
        </button>
        : <></>
      }
      {
        popupOpen && 
        <GaiPopup user={user} setPopupOpen={setPopupOpen} popupOpen={popupOpen}/>
      }

      <LoadingBar style={{ position: 'fixed', top: 0, zIndex: 999999 }} />
      <Helmet {...config.app.head} />
      <ScrollToTop />
      <Header
        onChangeOpenUserMenu={handleChangeOpenUserMenu}
        onChangeOpenMobileNav={handleChangeOpenMobileNav}
        pageClass={pageClass}
      />
      <MobileNav
        openMobileNav={openMobileNav}
        onChangeOpenMobileNav={handleChangeOpenMobileNav}
      />
      <MobileUserMenu
        openUserMenu={openUserMenu}
        onChangeOpenUserMenu={handleChangeOpenUserMenu}
      />
      <Quick />

      {/*<div className="content">*/}
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home2" component={Home2} />
          <Route exact path="/Privacypolicy" component={Privacypolicy} />
          <Route exact path="/Copyright" component={Copyright} />
          <Route exact path="/Emailwarning" component={Emailwarning} />
          <Route path="/digitalsprout" component={Digitalsprout} />
          <Route path="/about" component={About} />
          <Route path="/program" component={Program} />
          <Route path="/resource" component={Resource} />/
          <Route path="/board" component={Onair} />
          <Route path="/dataset" component={Dataset} />
          <Route path="/member" component={Member} />
          <Route path="/content" component={Content} />
          <Route path="/university" component={Varsity} />
          <Route path="/lms" component={Lms} />
          <Route path="/community" component={Community} />
          <Route path="/help" component={Help} />
          <Route path="/sublms" component={Sublms} />
          <Route path="/admin" component={Admin} />
          <Route path="/403" component={Forbidden} />
          <Route path="/*" component={NotFound} />
        </Switch>
      </Suspense>
      {/*</div>*/}
      <IntroFooter />
    </div>
  );
};

export default RouterApp;
