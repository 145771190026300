import {handleActions} from 'redux-actions';
import update from "react-addons-update";
import config from "config";

import {
    LOAD_USERS_REQUEST, LOAD_USERS_SUCCESS, LOAD_USERS_FAILURE, // User List
} from 'store/actions/users'

const initialState = {
    users: {
        list: [],
        totalCount: 0
    }, //사용자 리스트
    loading: false,
};

export default handleActions({

    /** 사용자 목록 불러오기 */
    [LOAD_USERS_REQUEST]: (state, action) => ({...state, loading: true}),
    [LOAD_USERS_SUCCESS]: (state, {payload}) => {
        return update(state, {
            users: {$set: {...payload}},
            loading: {$set: false}
        });
    },
    [LOAD_USERS_FAILURE]: (state, action) => {
        return update(state, {
            users: {$set: {...initialState.users}},
            loading: {$set: false}
        })
    },

}, initialState);
