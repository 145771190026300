import axios from 'axios';
import moment from 'moment/dist/moment';
import { logApiUrl } from 'config'

const Logging = async (class_, siteName, user, type, detailType, reason = '', data, pathName = window.location.pathname, prevPathName = '') => {
    if (_.isEmpty(user)) user = { username: '', name: '' };

    if (!_.isEmpty(user)) {
        let dataObj;
        if (class_ != 'admin') {
            dataObj = {
                site_name: siteName,
                page_name: window.document.title,
                current_uri: pathName,
                refer_uri: prevPathName,
                page_origin: window.location.origin,
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('HH:mm:ss'),
            }
        } else {
            dataObj = {
                site_name: siteName,
                admin_page_name: window.document.title,
                current_uri: pathName,
                refer_uri: prevPathName,
                page_origin: window.location.origin,
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('HH:mm:ss'),
            }
        }


        if (type == 'login') {
            _.set(dataObj, 'event_type', `${type}-${detailType}`);
            if (detailType == 'success') {
                _.set(dataObj, 'login_try_id', user.username);
            } else if (detailType == 'fail') {
                _.set(dataObj, 'login_try_id', data.identifier);
                _.set(dataObj, 'login_fail_reason', reason);
            }

        } else if (type == 'logout') {
            _.set(dataObj, 'event_type', type);
            _.set(dataObj, 'logout_id', user.username);

        } else if (type == 'menu_link') {
            _.set(dataObj, 'event_type', detailType);

        } else if (type == 'register') {
            _.set(dataObj, 'event_type', `${type}-${detailType}`);
            if (detailType == 'success') {
                _.set(dataObj, 'registered_data', data);
            } else if (detailType == 'fail') {
                _.set(dataObj, 'register_fail_reason', reason);
            }
        } else if (type == 'content') {
            _.set(dataObj, 'event_type', detailType);
            if (detailType == 'read') {
                _.set(dataObj, 'content_page_title', data.title);
            } else if (detailType == 'download') {
                _.set(dataObj, 'download_file_name', data.name);
            } else if (detailType == 'create') {
                _.set(dataObj, 'created_data', data.createData);
            } else if (detailType == 'update') {
                _.set(dataObj, 'updated_data', data.updateData);
            } else if (detailType == 'delete') {
                _.set(dataObj, 'deleted_data', data.deleteData);
            }
        } else if (type == 'user') {
            _.set(dataObj, 'event_type', detailType);
            if (detailType == 'update') {
                _.set(dataObj, 'updated_data', data.updateData);
            }
        }

        let fullParam = { data: dataObj, block_type: type };


        if (type == 'menu_link' || type == 'login' || type == 'logout' || type == 'register') {
            if (class_ == 'admin') {
                await axios.post(`${logApiUrl}/learning-record-api/${class_}/auth/${type}`, { params: { data: fullParam, user } })
            } else {
                await axios.post(`${logApiUrl}/learning-record-api/${class_}/${type}/portal-log`, { params: { data: fullParam, user } })
            }
        } else {
            await axios.post(`${logApiUrl}/learning-record-api/${class_}/${type}/portal-log/${detailType}`, { params: { data: fullParam, user } })
        }
    }
}

export { Logging };
