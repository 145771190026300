import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter, Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions/auth';
import { useTranslation } from 'react-i18next';
import { Motion, spring } from 'react-motion';
import { useCookies } from 'react-cookie';
import route from 'route';
import Cookie from "universal-cookie";

import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineUser } from 'react-icons/ai';
import useWindowPosition from 'utils/useWindowPosition';
import * as api from 'lib/content';

import HeaderMobileBottom from "./HeaderMobileBottom"
import HeaderMobileTop from "./HeaderMobileTop"

import { Logging } from '../../../utils/logging'
import axios from 'axios'

const GnbItem = ({ data, user, isLoggedIn }) => {
  const [customStyle, setCustomStyle] = useState('');
  const [loginCheck, setLoginCheck] = useState(false);
  const [loadings, setLoadings] = useState(false);




  useEffect(() => {
    if (!_.isEmpty(data)) {
      setLoadings(false)
      if (!_.isEmpty(_.get(data, "list"))) {
        let lists = _.get(data, "list");

        lists.map((items, idx) => {

          //외부 url일 경우
          if ((_.get(items, "title") == '통합 LMS')) {

            if (!_.isEmpty(user) && isLoggedIn) {
              _.set(data, "type", "a")
              _.set(data, "url", "https://lms.gjudec.com/home/dashboard")
              _.set(items, "type", "a")
              _.set(items, "url", "https://lms.gjudec.com/home/dashboard")

              setTimeout(() => { setLoadings(true) }, 1000)

            } else {
              _.set(data, "type", null)
              _.set(data, "url", "/member/login")
              _.set(items, "type", null)
              _.set(items, "url", "/member/login")

              setTimeout(() => { setLoadings(true) }, 1000)
            }
          } else {
            setTimeout(() => { setLoadings(true) }, 1000)
          }
        })
      }
    }
  }, [data, user, isLoggedIn])


  const path = location.pathname.split('/')[1];

  // const {
  //   user,
  //   isLoggedIn,
  //   loading: userLoading,
  // } = useSelector(({ auth }) => ({ ...auth }));



  return (
    <>
      {
        loadings &&
        <div className="_col">
          {data.type == 'a' ?
            (
              <a href={data.url}
                className={'link active2 ' +
                  customStyle +
                  ' ' +
                  (path == data.code && ' active1')
                }
                onMouseOver={() => {
                  setCustomStyle('active1');
                }}
                onMouseOut={() => {
                  setCustomStyle('');
                }}
              >
                {data.title}
              </a>
            ) : (
              <NavLink
                to={data.list[0]?.url}
                className={
                  'link active2 ' +
                  customStyle +
                  ' ' +
                  (path == data.code && ' active1')
                }
                onMouseOver={() => {
                  setCustomStyle('active1');
                }}
                onMouseOut={() => {
                  setCustomStyle('');
                }}
              >
                {data.title}
              </NavLink>
            )}

          <div className="depth">
            {data.list?.map((link) => {
              if (_.isEmpty(link.type)) {
                // if (link.title == '품질관리 시스템') {
                //   if (
                //     _.get(user, 'role._id') != '5fed5533258df16ef195ae04' &&
                //     _.get(user, 'role._id') != '602a265ab2b9050bdac24bcd'
                //   ) {
                //     return (
                //       <span
                //         className={'top_menu'}
                //         onClick={() => {
                //           swal({
                //             title: `권한이 없습니다.`,
                //             text: '해당 게시판을 이용할 수 있는 권한이 없습니다.',
                //             icon: 'warning',
                //             timer: 3000,
                //             buttons: false,
                //           });
                //         }}
                //       >
                //         {link.title}
                //       </span>
                //     );
                //   }
                // }
                return (
                  <NavLink
                    activeClassName={'bold'}
                    onMouseOver={() => {
                      setCustomStyle('active1');
                    }}
                    onMouseOut={() => {
                      setCustomStyle('');
                    }}
                    key={link.title}
                    to={link.url}
                  >
                    {link.title}
                  </NavLink>
                );
              } else {
                return (
                  <a
                    activeClassName={'bold'}
                    onMouseOver={() => {
                      setCustomStyle('active1');
                    }}
                    onMouseOut={() => {
                      setCustomStyle('');
                    }}
                    key={link.title}
                    href={link.url}
                  >
                    {link.title}
                  </a>
                );
              }
            })}
          </div>
        </div>
      }
    </>

  );
};

const Header = ({ onChangeOpenUserMenu, onChangeOpenMobileNav, pageClass }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [top_class, setTopClass] = useState('gnb_button_bar_top');
  const [bottom_class, setBottomClass] = useState('gnb_button_bar_bottom');
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies([]);

  // 상단 메세지 카운트
  const [courseMsgCnt, setCourseMsgCnt] = useState(null);
  const [sysMsgCnt, setSysMsgCnt] = useState(null);
  const [totalMsgCnt, setTotalMsgCnt] = useState(null);
  const [message_loading, setLoading] = useState(false);  //로딩중
  const [urls, setUrls] = useState(<img src={urls} alt="심볼로고" className="simbolLogos" />);

  //경험치가져오기
  const [xpPoint, setXpPoint] = useState(0);
  const [checkSubData, setCheckSubData] = useState({});


  const deleteSub = ({ user, checkSubData }) => {

    if (!_.isEmpty(checkSubData)) {
      swal({
        title: "구독을 취소하시겠습니까?", text: "진행을 누르면 뉴스레터를 더이상 받을 수 없습니다.", icon: "warning",
        buttons: { confirm: { text: "진행", value: true, className: "orange" }, cancle: { text: "취소", value: false, className: "nots" } }
      }).then(async (value) => {
        if (value) {
          try {
            const { data, status } = await axios.post(`/custom-api/users/deleteSubscribe`, { id: _.get(checkSubData, "_id") });

            if (status == 200) {
              swal({ title: "뉴스레터 구독이 취소되었습니다.", text: "뉴스레터 재신청은 언제든지 다시 가능합니다.", icon: "success", buttons: false });
              setTimeout(() => {
                window.location.reload();
              }, 2000)
            }

          } catch (e) {
            console.log(e)
          } finally {

          }
        }
      })
    }
  }


  const checkSubsribe = async ({ user }) => {
    try {

      let id = _.get(user, "_id");
      let email = _.get(user, "email");

      const { data } = await axios.get(`/custom-api/users/prevSubscribe`, { params: { id: id, email: email } });

      if (!_.isEmpty(data)) {

        setCheckSubData(data[0])
      } else {
        setCheckSubData({})
      }

    } catch (e) {
      console.log(e)
    } finally {

    }
  }




  const browserHeighe = window.scrollY || document.documentElement.scrollTop;
  const browserWidth = window.innerWidth

  const { user, isLoggedIn } = useSelector(
    ({ auth: { user, isLoggedIn } }) => ({
      user,
      isLoggedIn,
    })
  );

  const handleLogout = async () => {
    console.log('로그아웃')
    const cookie = new Cookie();

    removeCookie('tempass', { path: "/", domain: "www.gjudec.com" });
    removeCookie('jwt', { path: "/", domain: "www.gjudec.com" })
    removeCookie('user', { path: "/", domain: "www.gjudec.com" })
    removeCookie('username', { path: "/", domain: "www.gjudec.com" })
    await axios.post('/custom-api/sso/logout', { jwt: cookie.get('jwt'), message: 'logout_success' })
    //setTimeout(()=>{
    // document
    //   .getElementById('iframe-lms')
    //   .contentWindow.postMessage('log_out', 'https://lms.gjudec.com');
    dispatch(actions.logout({ user }));
    history.push('/');
    setTimeout(() => {
      window.location.href = `https://mv.gjudec.com/logout?from=${window.location.href}`
    }, 500);
    //},1000)

  };

  const Headerdom = document.getElementsByClassName('header');

  function Header_hover() {
    setMouseOver(true);
  }

  function Header_hover_leave() {
    setMouseOver(false);
  }

  // useEffect(() => {
  //   if (!_.isEmpty(document.getElementById('iframe-lms'))) {
  //     if (!_.isEmpty(user)) {
  //       document.getElementById('iframe-lms').addEventListener('load', () => {
  //         document
  //           .getElementById('iframe-lms')
  //           .contentWindow.postMessage(
  //             { jwt: _.get(localStorage, 'jwt') },
  //             'https://lms.gjudec.com'
  //           );
  //       });
  //       document
  //         .getElementById('iframe-lms')
  //         .contentWindow.postMessage(
  //           { jwt: _.get(localStorage, 'jwt') },
  //           'https://lms.gjudec.com'
  //         );
  //     }
  //   }
  // }, [document.getElementById('iframe-lms'), user]);


  let messageTo = _.get(user, 'email')




  const getMessageList = useCallback(async (messageTo) => {
    try {
      setLoading(true)
      //Get cw
      console.log(messageTo)


      if (_.get(user, 'role.type') == 'admin' || _.get(user, 'role.type') == 'operator') {
        const { data: count = [] } = await api.count({ type: 'lms-messages', "_where[_or][to_contains]": [_.get(user, 'name'), _.get(user, 'username'), _.get(user, 'email'), '@all', '@admin'], isread: false })
        setTotalMsgCnt(count)
      } else {
        const { data: count = [] } = await api.count({ type: 'lms-messages', "_where[_or][to_contains]": [_.get(user, 'name'), _.get(user, 'username'), _.get(user, 'email'), '@all'], isread: false })
        setTotalMsgCnt(count)
      }

      const { data: ssn = [] } = await api.count({ type: 'lms-messages', "_where[to_contains]": messageTo, "_where[isread_ne]": true })
      const { data: ssa = [] } = await api.count({ type: 'lms-messages', "_where[to_contains]": '@all', "_where[isread_ne]": true })
      // const {data: ssn = []} = await api.count({type:'lms-messages', "_where[to]":messageTo, "_where[isread_null]":true})
      // const {data: ssa = []} = await api.count({type:'lms-messages', "_where[to]":'@all', "_where[isread_null]":true})
      setLoading(false)

    } catch (err) {
      console.log(err, 'getMessageList Error')
      return null;
    } finally {
    }
  }, [user]);

  const [userLeaningSpaceList, setUserLearningSpaceList] = useState([]);



  //유저경험치정보가져오기
  const getXp = async ({ username }) => {
    try {
      console.log(username, "username")
      const { data } = await axios.get('/custom-api/users/getXp', { params: { username: username } });

      if (!_.isEmpty(data)) {
        let indata = data[0];
        setXpPoint(_.get(indata, "points"));
      } else {
        setXpPoint(0);
      }

    } catch (e) {
      console.log(e)
    } finally {

    }
  }

  const getLearningSpace = async ({ userLearningSpace }) => {
    try {

      if (!_.isEmpty(userLearningSpace)) {
        const { data } = await axios.get('/custom-api/users/getLearningSpace', { params: { userLearningSpace: userLearningSpace } });

        if (!_.isEmpty(data)) {
          setUserLearningSpaceList(data)
        } else {
          setUserLearningSpaceList([])
        }
      }

    } catch (e) {
      console.log(e)
    } finally {

    }
  }




  useEffect(() => {
    getMessageList(messageTo)
  }, [messageTo]);

  useEffect(() => {
  }, [totalMsgCnt]);


  useEffect(() => {
    if (browserHeighe < 1) {
      setUrls("/assets/images/logo_green.png")
    } else {
      setUrls("/assets/images/gj_simbol.svg")
    }
  }, [browserHeighe])


  useEffect(() => {
    if (!_.isEmpty(user)) {
      checkSubsribe({ user })
      getXp({ username: _.get(user, "username") })
      getLearningSpace({ userLearningSpace: _.get(user, "learning_space_id") })
    }
  }, [user])

  return (
    <header
      className="header"
      onMouseOver={() => Header_hover()}
      onMouseOut={() => Header_hover_leave()}
    >
      <div>
        {/* <iframe
          src="https://lms.gjudec.com/member/ext_login"
          id={'iframe-lms'}
          style={{ display: 'none' }}
        /> */}
      </div>
      <div className={browserHeighe < 10 && browserWidth > 1024 ? "header__top" : "header__top display_none_header_top"}>
        <div className="wrap">
          {(!user || !isLoggedIn) && (
            <>
              <Link to="/member/login">로그인</Link>
              <Link to="/member/register/information">회원가입</Link>
            </>
          )}
          {user && isLoggedIn && !message_loading && (
            <>

              {/* {!_.isEmpty(userLeaningSpaceList) ?
                <div className='space_wrapper'>
                  <img src='/assets/images/space_icon.svg' alt='' />
                  <select>
                    {!_.isEmpty(userLeaningSpaceList) && userLeaningSpaceList.map((item) => {
                      return <option>{_.get(item, "name")}</option>
                    })}
                  </select>
                  <button style={{ color: "#fff" }}>이동</button>
                </div>
                :
                null
              } */}
              <span className={`mr-3 text-white`}>
                {(user.name && `${user.name} 님`) || `${user.email} 님`}
              </span>
              <button onClick={() => handleLogout()}>로그아웃</button>

              {/* <div className="xpState">
                <span className="xps">{xpPoint}</span> xp
              </div> */}
              <a href={'https://lms.gjudec.com/home/message'}>메시지 수신함 <span className="header__msgcnt">{!message_loading && totalMsgCnt}</span></a>
              {_.get(user, 'role.type') == 'instructor' || _.get(user, 'role.type') == 'admin' ||
                _.get(user, 'role.type') == 'staff' ? (
                <Link to="/member/mycontent">마이페이지</Link>
                // <Link to="/member/InstructorCourse">마이페이지</Link>
              ) : (
                <Link to="/member/InstructorCourse">마이페이지</Link>
              )}

              {_.get(user, 'role.name') == 'Admin' && (
                <a
                  href={'https://lms.gjudec.com/backend/dashboard'}
                  target="_blank"
                >
                  관리자페이지
                </a>
              )}
              {_.get(user, 'role.name') == 'org_operator' && (
                <a
                  href={'https://lms.gjudec.com/backend/studio_reservation'}
                  target="_blank"
                >
                  관리자페이지
                </a>
              )}

            </>
          )}

          <Link to="/member/SubscribeNewsLetter" className="subsLink">
            {
              !_.isEmpty(checkSubData) ?
                <div className="subsIcon active">
                  <div className="left">
                    <img src="/assets/images/bell_green.svg" alt="신청완료" />
                  </div>
                  <div className="rights">
                    뉴스레터 구독중
                  </div>
                </div> :
                <div className="subsIcon">
                  <div className="left">
                    <img src="/assets/images/bell.svg" alt="신청완료" />
                  </div>
                  <div className="rights">
                    뉴스레터 구독신청
                  </div>
                </div>
            }
          </Link>

          {/* {
               !_.isEmpty(checkSubData) && 
               <>
                <div className="removesSide">
                  <div className="subsIcon removes" onClick={()=>{deleteSub({user,checkSubData})}}>
                    <div className="left">
                      <img src="/assets/images/bell_not.svg" alt="신청취소"/>
                    </div>
                    <div className="rights">
                      뉴스레터 구독취소
                    </div>
                  </div>
                </div>
               </>
               
            } */}
        </div>
      </div>
      <div
        className={`${useWindowPosition() > 10
          ? 'header__bottom border_g_bottom'
          : mouseOver
            ? 'header__bottom border_g_bottom'
            : _.isEmpty(pageClass)
              ? 'header__bottom border_g_bottom'
              : 'header__bottom border_g_bottom'
          }`}
      >
        <div className="wrap">
          <button
            className="mobile-button mobile-button--gnb"
            onClick={() => onChangeOpenMobileNav(true)}
          >
            <GiHamburgerMenu />
          </button>

          <Link to="/" className="header__logo">

            {/* <img src="/assets/images/gj_simbol.svg" alt="심볼로고" className="simbolLogos"/> */}
            <img src={urls} alt="심볼로고" className="simbolLogos" />
            <img src="/assets/images/logo_green.png"
              className="defaultLogos"
              // {`${
              //   useWindowPosition() > 10
              //     ? '/assets/images/logo_green.png'
              //     : mouseOver
              //     ? '/assets/images/logo_green.png'
              //     : _.isEmpty(pageClass)
              //     ? '/assets/images/logo_white.png'
              //     : '/assets/images/logo_green.png'
              // }`}
              alt="광주전남권역대학 원격교육지원센터"
            />
          </Link>
          <div className="header__gnb">
            <GnbItem data={route.depth1} />
            <GnbItem data={route.depth2} user={user} isLoggedIn={isLoggedIn} />
            <GnbItem data={route.depth3} />
            <GnbItem data={route.depth4} />
            <GnbItem data={route.depth5} />
            <GnbItem data={route.depth9} />
            <div className="gnb_button_new">
              <div
                className="gnb_button_bar_top top_color_ac"
              // {`${
              //   useWindowPosition() > 10
              //     ? 'gnb_button_bar_top top_color_ac'
              //     : mouseOver
              //     ? 'gnb_button_bar_top top_color_ac'
              //     : _.isEmpty(pageClass)
              //     ? 'gnb_button_bar_top'
              //     : 'gnb_button_bar_top top_color_ac'
              // }`}
              ></div>
              <div
                className="gnb_button_bar_bottom bottom_color_ac"
              // {`${
              //   useWindowPosition() > 10
              //     ? 'gnb_button_bar_bottom bottom_color_ac'
              //     : mouseOver
              //     ? 'gnb_button_bar_bottom bottom_color_ac'
              //     : _.isEmpty(pageClass)
              //     ? 'gnb_button_bar_bottom'
              //     : 'gnb_button_bar_bottom bottom_color_ac'
              // }`}
              ></div>
            </div>
          </div>

          <button
            className="mobile-button mobile-button--user"
            onClick={() => onChangeOpenUserMenu(true)}
          >
            <AiOutlineUser />
          </button>

          {/* <div className="mobile_nav_bottom">
            <div className='mobile_bottom_top' style={{height : 55}}>
              <div className='mobile_top_nav'>
                <HeaderMobileTop data={route.depth1} />
                <HeaderMobileTop data={route.depth2} />
                <HeaderMobileTop data={route.depth3} />
                <HeaderMobileTop data={route.depth4} />
                <HeaderMobileTop data={route.depth5} />
                <HeaderMobileTop data={route.depth9} />
              </div>
            </div>
            <div className='mobile_bottom_bottom'>
              <div className='mobile_top_nav' style={{ display: "flex", gap: "10px" }}>
                <HeaderMobileBottom data={route.depth1} />
                <HeaderMobileBottom data={route.depth2} />
                <HeaderMobileBottom data={route.depth3} />
                <HeaderMobileBottom data={route.depth4} />
                <HeaderMobileBottom data={route.depth5} />
                <HeaderMobileBottom data={route.depth9} />
              </div>
            </div>
          </div> */}
        </div>
        <div
          className="head_bar_border"
        // {`${
        //   useWindowPosition() > 10
        //     ? 'head_bar_border'
        //     : mouseOver
        //     ? 'head_bar_border'
        //     : _.isEmpty(pageClass)
        //     ? ''
        //     : 'head_bar_border'
        // }`}
        ></div>
      </div>
    </header>
  );
};

export default Header;
