/**
 * User 관련 API
 * code가 붙은 API url은 Drupal 을 바라봄.
 */
import axios from 'axios';
import qs from 'qs';


/******** 로그인 관련 *********/
//이메일 로그인
export const userLogin = (params) => axios.post(`/api/user/login`, qs.stringify(params))

//외부계정 로그인
export const externalLogin = (params) => axios.post(`/api/user/sns-login`, qs.stringify(params))

//로그아웃
export const logout = () => axios.post(`/api/user/logout`);


/******** 데이터 Load 관련 *********/
export const load = () => axios.get(`/api/user/load`)
export const getinfo = () => axios.get('/api/memberships/getinfo')
export const getSession = () => axios.get(`/api/memberships/session-id`);


/******** 회원가입 관련 *********/
//email로 아이디 찾기
export const getFindId = (email) => axios.post(`/api/user/find/id`, qs.stringify({email}))

//아이디로 임시 비밀번호 발급 받기(email)
export const getPasswordToEmail = (id) => axios.post(`/api/user/find/password`, qs.stringify({id}))

//이메일 Auth Code 요청
export const sendAuthEmail = (params) => axios.post(`/memberships/send-auth-email`, qs.stringify(params));

//이메일 Auth Code 요청
export const emailAuthCheck = (params) => axios.post(`/memberships/email-auth-check`, qs.stringify(params));

//이메일 인증코드 삭제
export const emailAuthDelete = (email) => axios.post(`/memberships/email-auth-delete`, qs.stringify({email}));

//User 생성
export const userCreate = (params) => axios.post(`/api/user/create`, qs.stringify(params));

//외부계정 User 생성
export const externalUserCreate = (params) => axios.post(`/api/user/sns-create`, qs.stringify(params));

//User 수정
export const userEdit = (params) => axios.post(`/api/user/edit`, qs.stringify(params));

/******** 회원 정보 관련 *********/
//비밀번호 변경
export const setPassword = ({currentPassword, password}) => axios.post(`/api/user/password/change`, qs.stringify({
    currentPassword,
    password
}));

//회원 탈퇴
export const deactivateUser = ({name, password}) => axios.post(`/api/user/deactivate`, qs.stringify({
    name,
    password
}))

//목록 검색
export const getUserData = ({userRole, pager, searchdata}) => axios.post(`/api/user-data/load`, qs.stringify({
    userRole,
    pager,
    searchdata
}))

/******** 검증 관련 *********/
//유저 이메일 중복 validate 체크
export const validEmailDupe = (email) => axios.post(`/memberships/email_duplicate`, qs.stringify({email}));

















