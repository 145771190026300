import React from 'react';
import Slider from 'react-slick';
import SlideItem from './SlideItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slides = [
    {
        id: '1',
        description: '디지털 전환 시대\n대학교육의 고품질 비대면 원격수업과 미래교육 혁신을 선도하는',
        title: '광주전남권역\n공동 활용 학습관리시스템',
        backgroundColor: 'linear-gradient(180deg,  #6eace5, #aedaf3)',
        image:'https://gj.udec.kr/assets/images/main_b_img1.png'
    },
    {
        id: '2',
        description: '디지털 전환 시대\n대학교육의 고품질 비대면 원격수업과 미래교육 혁신을 선도하는',
        title: '광주전남권역\n공동 활용 학습관리시스템',
        backgroundColor: 'linear-gradient(180deg,  #6eace5, #aedaf3)', //시작컬러 #6eace5
        image:'https://gj.udec.kr/assets/images//main_b_img2.png'
    }
];

const settings = {
    // slide: 'Slider',
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false
};

const MainSlider = ({bannerlist}) => {
    return (
        <div className="mainSlider">
            <Slider {...settings}>
                {bannerlist.map((slide,idx) => {
                    if(!_.get(slide,'always')){
                        if(moment().isBefore(moment(_.get(slide,'banner_start_date'))) || moment().isAfter(moment(_.get(slide,'banner_end_date')))){
                            return(null)
                        }
                        else{
                            return(
                                <div className={`mainSlider__item`}>
                                    {
                                        !_.isEmpty(_.get(slide,'banner_url')) &&
                                        <div>
                                            <a href={_.get(slide,'banner_url')}>
                                                <img src={`https://portal.gjudec.com/api${_.get(slide,'banner_image.url')}`} alt="광주전남권역 공동활용 학습관리 시스템"/>
                                            </a>
                                        </div>
                                        || 
                                        <div>
                                            <img src={`https://portal.gjudec.com/api${_.get(slide,'banner_image.url')}`} alt="광주전남권역 공동활용 학습관리 시스템"/>
                                        </div>
                                    }
                                </div>
                            )
                        }
                    }
                    else{
                        return(
                            <div className={`mainSlider__item`}>
                                {
                                    !_.isEmpty(_.get(slide,'banner_url')) &&
                                    <div>
                                        <a href={_.get(slide,'banner_url')}>
                                            <img src={`https://portal.gjudec.com/api${_.get(slide,'banner_image.url')}`} alt="광주전남권역 공동활용 학습관리 시스템"/>
                                        </a>
                                    </div>
                                    || 
                                    <div>
                                        <img src={`https://portal.gjudec.com/api${_.get(slide,'banner_image.url')}`} alt="광주전남권역 공동활용 학습관리 시스템"/>
                                    </div>
                                }
                            </div>
                        )
                    }
                    }
                )}
            </Slider>
        </div>
    );
};

export default MainSlider;
