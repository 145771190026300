import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form/dist/index.ie11";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import { useTranslation } from 'react-i18next'
import { apiUrl } from "config";
import * as api from 'lib/content';
import { useCookies } from 'react-cookie'

//graphql 관련
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import * as aQuery from "queries/content";

import ReactMarkdown from "react-markdown";

const CustomMainPopup = ({ data, num }) => {
    const [cookies, setCookie, removeCookie] = useCookies([`popup_${_.get(data, 'id')}`]);
    const [popupClose, setPopupClose] = useState(true);
    const { position_x, position_y, size_x, size_y } = data;

    // 다중 팝업 시 위치 변경
    let CustomStyle = {
        left: position_x + "px",
        top: position_y + "px",
        maxWidth: size_x + "px",
        maxHeight: size_y + "px",
    }


    // 오늘하루보지않기 체크 시 팝업 닫기+쿠키 저장
    const handleOnChange = (e) => {
        let now = new Date();
        let afterDay = new Date();

        if (e.target.checked) {
            afterDay.setDate(afterDay.getDate() + 1);
            afterDay.setHours(0);
            afterDay.setMinutes(0);
            afterDay.setSeconds(0);
            setCookie(`popup_${_.get(data, 'id')}`, afterDay, { path: '/', expires: afterDay });

        } else {
            removeCookie(`popup_${_.get(data, 'id')}`);
        }
    }

    if (_.isEmpty(_.get(cookies, `popup_${_.get(data, 'id')}`))) {
        return (
            <div className={popupClose ? 'main_popup_container' : 'main_popup_container close'} style={CustomStyle}>
                <div className={'main_popup_header'}>
                    <div className={'popup_title'}>{(_.get(data, 'title'))}</div>
                    <button onClick={() => setPopupClose(!popupClose)} type="button" className="overlay_close_x close">
                        <span className="element-invisible" aria-hidden="true"></span>
                    </button>

                </div>
                <div className={'main_popup_body'}>
                    <ReactMarkdown
                        transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                        escapeHtml={false}
                        source={_.get(data, 'body')} />
                </div>
                <div className={'main_popup_footer'}>
                    <img src='/assets/images/logo_green.png' alt='logo' />
                    <label className={'not_open_label'}>
                        <input type={'checkbox'} className={'day_not_open'} onClick={handleOnChange} />
                        오늘 하루 보지 않기
                    </label>
                </div>
            </div>
        )
    }
    else {
        return (<></>)
    }
}

export default CustomMainPopup;
