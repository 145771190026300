import React, { useState, useCallback, useEffect } from 'react';
import 'react-modal-video/scss/modal-video.scss';

import LeftBoard from './LeftBoard';
import RightBoard from './RightBoard';

const Index = ({ notices = {}, news = {}, contentsList = {}, isLoggedIn, user }) => {
    // console.log(contentsList, "==============contentsList")
    const [type, setType] = useState('notice');
    const [list, setList] = useState([]);

    const handleChangeType = useCallback((name) => {
        setType(name);
    }, [type]);

    useEffect(() => {
        if (type == 'notice') {
            setList(notices)
        }
        else if (type == 'news') {
            setList(news)
        }
        else if (type == 'reference') {
            setList(contentsList)
        }
    }, [type, notices])

    return (
        <div className="mainBoard">
            <div className="wrap">
                <LeftBoard type={type} onChangeType={handleChangeType} list={list} />
                <RightBoard user={user} isLoggedIn={isLoggedIn} />
            </div>
        </div>
    );
};

export default Index;
